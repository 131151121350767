import styled from 'styled-components';
import React from 'react';

export default function Loading({ size = 20 }) {
  return <LoadingContainer style={{ height: size, width: size }} />;
}

const LoadingContainer = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.1);

  border-radius: 50%;
  border-left-color: #fff;

  animation: spin 1s ease infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
