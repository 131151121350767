import React from 'react';
import { CommenterProfileImageContainer } from './styled-components';

const CommenterProfileImage = ({ src, alt }) => {
  return (
    <CommenterProfileImageContainer>
      <img src={src} alt={alt || 'Profile image'} />
    </CommenterProfileImageContainer>
  );
};

export default CommenterProfileImage;
