import React from 'react';
import { Ellipse } from '../../../assets/icons/svg-icons';
import FlexContainer from '../../UI/FlexContainer';
import Card from '../../UI/Card';
import Tag from '../../UI/Tag';
import { ShareArticleCardView, ShareArticleCardPreview } from './styled-components';
import ToolbarReactions from '../../UI/reaction-toolbar/ToolbarReactions';
import AuthorMeta from '../../UI/author-post/AuthorMeta';
import { getDateFormatted } from '../../../utils/utils';
import { useHistory } from 'react-router-dom';
import { FlexboxGrid } from 'rsuite';
import { screen } from '../../../utils/constants';
import styled from 'styled-components';
import useMobile from '../../../hooks/useMobile';
import Link from '../../UI/Link';

export default function ArticleEmbedView({
  _id,
  categoryId,
  contentTypeId,
  title,
  image,
  location,
  keywords,
  textContent,
  user,
  create_at,
  created_at,
  liked,
  likes,
  isPreview,
  className,
  slug,
  comments,
}) {
  const history = useHistory();
  const isMobile = useMobile();
  const [modalOpen, setModalOpen] = React.useState(false);

  const getKeywordsPreview = (keywords) => {
    if (keywords && keywords.length === 0) {
      return [];
    }

    if (window.innerWidth > 864) {
      return keywords?.slice(0, 10);
    } else {
      return keywords?.slice(0, 3);
    }
  };
  const keyWordsCutted = getKeywordsPreview(keywords);
  const cardContent = (
    <ArticleCard className={className} fullWidth>
      <FlexContainer column>
        <div style={{ width: '100%' }}>
          <ArticleMainContent>
            <ArticleContentContainer colspan={(!image && !image?.content?.featured_sm) || isMobile ? 24 : 16}>
              <FlexContainer justify="space-between" align="center">
                <div className="breadcrumb">
                  <Link className="breadcrumb" to={`/?category=${categoryId}&sort=created_at`}>
                    {categoryId}
                  </Link>
                  <Ellipse />
                  <Link className="breadcrumb" to={`/?search=${contentTypeId}&sort=created_at`}>
                    {contentTypeId}
                  </Link>
                  <Ellipse />
                  <Link
                    className="breadcrumb"
                    to={`/?locationID=${location?.place_id}&locationName=${location?.locality}&sort=created_at`}
                  >
                    {location?.locality || 'Worldwide'}
                  </Link>
                </div>
              </FlexContainer>
              <FlexContainer justify="space-between" align="stretch">
                <FlexContainer className="post-content" column>
                  <div style={{ flexGrow: 1 }}>
                    <h4>{title}</h4>
                    <p>{textContent}</p>
                    <div></div>
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <KeywordsContainer justify="flex-start" breakRow="wrap" inline className="keywords-container">
                      {keyWordsCutted && keyWordsCutted.length > 0
                        ? keyWordsCutted.map((k, index) => {
                            return (
                              <Link
                                style={{ margin: 5 }}
                                id="hidden_link"
                                to={isPreview ? '' : `/?keywords=${k}&sort=created_at`}
                              >
                                <Tag tagType="primary" sm key={index} className="tag-embed-post">
                                  {k}
                                </Tag>
                              </Link>
                            );
                          })
                        : null}
                    </KeywordsContainer>
                  </div>
                </FlexContainer>
              </FlexContainer>
            </ArticleContentContainer>
            {image && (image.content?.featured_sm || image.content?.medium) ? (
              <FlexboxGrid.Item colspan={isMobile ? 24 : 8}>
                <FlexContainer justify="flex-end" elmWidth="100%" align="center">
                  <FeaturedImageContainer>
                    <img src={image.content.featured_sm || image.content?.medium} alt={title} />
                  </FeaturedImageContainer>
                </FlexContainer>
              </FlexboxGrid.Item>
            ) : null}
          </ArticleMainContent>
        </div>
        <div style={{ width: '100%', marginTop: 10 }}>
          <FlexboxGrid justify="space-between">
            <FlexboxGrid.Item>
              {user ? (
                <AuthorMeta
                  userId={user?._id}
                  authorName={user?.name}
                  postDate={(created_at && getDateFormatted(created_at)) || (create_at && getDateFormatted(create_at))}
                  avatarUrl={user?.photo?.content?.small}
                />
              ) : (
                <AuthorMeta
                  authorName={'[DELETED]'}
                  postDate={(created_at && getDateFormatted(created_at)) || (create_at && getDateFormatted(create_at))}
                />
              )}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <FlexContainer justify="space-evenly" align="center" className="reactions-toolbar" elmWidth="100%">
                <ToolbarReactions
                  articleId={_id}
                  liked={liked}
                  likes={likes}
                  userId={user?._id}
                  comments={comments}
                  onComments={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (e.ctrlKey || e.metaKey) {
                      window.open(`/${categoryId.toLowerCase()}/${slug}?goComments=true`);
                    } else {
                      history.push(`/${categoryId.toLowerCase()}/${slug}?goComments=true`);
                    }
                  }}
                  modalOpen={setModalOpen}
                />
              </FlexContainer>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </div>
      </FlexContainer>
    </ArticleCard>
  );

  return (
    <div key={_id}>
      {!isPreview ? (
        <ShareArticleCardView>
          <Link
            id="hidden_link"
            to={`/${categoryId.toLowerCase()}/${slug}`}
            onClick={(e) => {
              if (modalOpen) {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          >
            {cardContent}
          </Link>
        </ShareArticleCardView>
      ) : (
        <ShareArticleCardPreview>{cardContent}</ShareArticleCardPreview>
      )}
    </div>
  );
}

const ArticleMainContent = styled(FlexboxGrid)`
  @media (max-width: ${screen.SM}) {
    flex-direction: column;
  }
`;

const ArticleContentContainer = styled(FlexboxGrid.Item)`
  @media (max-width: ${screen.SM}) {
    order: 2;
  }
`;

const ArticleCard = styled(Card)`
  -webkit-transform: translate3d(0, 0, 0);
  @media (max-width: ${screen.SM}) {
    box-shadow: none;
    border-bottom: 1px solid #f6f8ff;
    filter: none;
    border-radius: none;
    padding: 0;
    padding-bottom: 24px;
  }
`;

const FeaturedImageContainer = styled.figure`
  margin: 0;
  width: 169px;
  height: 138px;
  overflow: hidden;
  border-radius: 16px;
  margin: 0;
  @media (max-width: ${screen.SM}) {
    width: 100%;
    height: auto;
    margin-bottom: 8px;
  }
  & img {
    width: 100%;
    height: auto;
    border-radius: 16px;
    @media (max-width: ${screen.SM}) {
      width: 100%;
      height: 223px;
      object-fit: cover;
    }
  }
`;

const KeywordsContainer = styled(FlexContainer)`
  @media (max-width: ${screen.SM}) {
    margin-top: 16px;
    margin-bottom: 24px;
  }
`;
