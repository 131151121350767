import React from 'react';

import CommenterProfileImage from './CommenterProfileImage';
import { CommenterInfoContainer, CommenterName } from './styled-components';

const CommenterInfo = ({ profileImage, name, style }) => {
  return (
    <CommenterInfoContainer style={style}>
      <CommenterProfileImage src={profileImage} />
      {/*<img src="https://via.placeholder.com/24" alt="profile" />*/}
      <CommenterName>{name}</CommenterName>
    </CommenterInfoContainer>
  );
};

export default CommenterInfo;
