import React, { useEffect } from 'react';
import Caption from '../UI/Caption';
import styled from 'styled-components';
import ArticleTemplate from './ArticleTemplate';
import UploadInput from '../UI/forms/UploadInput';
import { useSelector, useDispatch } from 'react-redux';
import { makeFormDraft, updateValidationTemplate } from '../../redux/reducers/newArticleState';
import PlaceholderImg from '../../assets/images/Rectangle.png';
import { generalTemplate } from '../../utils/constants';
const StyledArticleImage = styled.div`
  height: 300px !important;
  max-height: 300px !important;
  min-heightL 300px !important;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

 
`;
const StyledArticleFields = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 10px;
`;

const StyledArticleData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding-top: 34px;
  /*border-top: 1px solid #151531;*/

  @media (max-width: 768px) {
    flex-direction: column;
    display: block;
  }

  ${Caption} {
    text-align: center;
  }

  ${StyledArticleFields} {
    flex-basis: 0;
    flex-grow: 7;
  }
  ${StyledArticleImage} {
    flex-basis: 0;
    flex-grow: 3;
  }
`;

const categoriesSelector = (state) => state.app.categories;
const getContentType = (categories, categoryId, contentTypeId) => {
  if (
    categoryId !== null &&
    categoryId !== '' &&
    categoryId !== undefined &&
    contentTypeId !== null &&
    contentTypeId !== '' &&
    contentTypeId !== undefined
  ) {
    const category = categories.find((category) => category.name === categoryId);
    const subCategory = category.contentTypes.find((contentType) => contentType.name === contentTypeId);
    if (subCategory) {
      return subCategory;
    } else {
      return generalTemplate;
    }
  } else {
    return generalTemplate;
  }
};

function getFeaturedImage(formData) {
  if (formData?.photo?.url?.medium) {
    return formData?.photo?.url?.medium;
  }

  return PlaceholderImg;
}

const ArticleData = ({ article, showImage, onChange, readOnly }) => {
  const categories = useSelector(categoriesSelector);
  const { draftForm, articleValidations } = useSelector((store) => store.newArticle);
  const dispatch = useDispatch();
  const content = getContentType(categories, article.categoryId, article.contentTypeId);
  const contentType = content ? content : draftForm[0];
  const formData = article ? article : draftForm[0].content;
  const changeWithDraft = (articleContent) => {
    const draftContent = { ...contentType, content: articleContent };
    dispatch(makeFormDraft(draftContent));
    onChange(articleContent);
  };

  useEffect(() => {
    const validationTemplate = {};
    for (const key in contentType) {
      if (key !== 'name' && key !== 'template') {
        if (contentType[key].required) {
          if (key === 'date') {
            validationTemplate[key] = { valid: true, errorType: '' };
          } else {
            validationTemplate[key] = { valid: false, errorType: '' };
          }
        }
      }
    }
    const fieldsAlreadyValidated = {};
    for (const key in articleValidations) {
      if (articleValidations[key].valid) {
        fieldsAlreadyValidated[key] = articleValidations[key];
      }
    }
    dispatch(updateValidationTemplate({ ...validationTemplate, ...fieldsAlreadyValidated }));
  }, [article.contentTypeId, contentType, dispatch]);

  return (
    <StyledArticleData>
      <StyledArticleFields>
        <Caption bold>KEY INFO</Caption>
        <ArticleTemplate contentType={contentType} article={formData} onChange={changeWithDraft} readOnly={readOnly} />
      </StyledArticleFields>
      <StyledArticleImage>
        {contentType?.image && (
          <>
            <Caption bold className="no-margin">
              FEATURE PHOTO
            </Caption>
            <UploadInput
              contentType={contentType}
              onChange={changeWithDraft}
              srcImg={getFeaturedImage(article)}
              readOnly={readOnly}
            />
          </>
        )}
      </StyledArticleImage>
    </StyledArticleData>
  );
};

export default ArticleData;
