import React, { useState, useEffect } from 'react';
import {
  PunchIcon,
  FlagIcon,
  PunchIconFilled,
  CommentsIconUnfilled,
  ReplyIcon,
  UpArrow,
  CloseArrow,
  TrashIcon,
} from '../../../assets/icons/svg-icons';
import { ThemeProvider } from 'styled-components';

import FlexContainer from '../FlexContainer';
import Modal from '../modal/Modal';
import { useSelector, useDispatch } from 'react-redux';

import Report from '../../ArticleDetails/Report/Report';
import { ReactionLayout } from './styled-components';
import { setLikeService, unsetLikeService } from '../../../http/likeService';
import { deleteComment } from '../../../http/articleService';

import Config from '../../../lib/Config';
import styled from 'styled-components';
import Notice from '../../UI/notice/Notice';
import { setVisibleLogin } from '../../../redux/reducers/appState';
import { useParams } from 'react-router-dom';
import { getArticleDetailBySlug } from '../../../redux/reducers/articleDetail';

export default function CommentToolbar({
  height,
  commentId,
  likes,
  liked,
  setOpen,
  open,
  setReply,
  children,
  isChildren,
  userId,
  reply,
  onUpdate,
}) {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { user, authorization } = useSelector((state) => state.auth);
  const [reactions, setReactions] = useState({ liked: liked || false, likes: likes || 0 });
  const [showReportModal, setShowReportModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setReactions({ ...reactions, liked: liked || false, likes: likes || 0 });
  }, [liked, likes]);

  const setLike = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (authorization) {
      try {
        const response = await setLikeService({ commentId });
        setReactions({ ...reactions, liked: true, likes: response.data.likes });
      } catch (e) {}
    } else {
      dispatch(setVisibleLogin(true));
    }
  };

  const unsetLike = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (authorization) {
      try {
        const response = await unsetLikeService({ commentId });
        setReactions({ ...reactions, liked: false, likes: response.data.likes });
      } catch (e) {}
    } else {
      dispatch(setVisibleLogin(true));
    }
  };

  const deleteCommentRequest = async (e) => {
    try {
      setShowDelete(false);
      await deleteComment(commentId);
      dispatch(getArticleDetailBySlug(slug, true));
      if (onUpdate) {
        onUpdate();
      }
    } catch (e) {}
  };

  return (
    <CommentToolbarLayout>
      {successMessage && (
        <Notice
          duration={2000}
          type="success"
          text={successMessage}
          callBack={() => {
            setSuccessMessage(null);
          }}
        />
      )}
      {errorMessage && (
        <Notice
          duration={2000}
          type="error"
          text={errorMessage}
          callBack={() => {
            setErrorMessage(null);
          }}
        />
      )}
      <Report
        commentId={commentId}
        setShowReportModal={setShowReportModal}
        showReportModal={showReportModal}
        onSuccess={() => {
          setSuccessMessage('Your report has been submitted successfully');
        }}
        onError={(message) => {
          setErrorMessage(message);
        }}
      />

      <ThemeProvider theme={{ isDark: true }}>
        <Modal
          title="DELETE CONFIRMATION"
          setVisibility={() => {
            setShowDelete(!showDelete);
          }}
          visible={showDelete}
          elmHeight="auto"
          elmWidth="496px"
          textOk="Delete"
          okClick={deleteCommentRequest}
          header={undefined}
        >
          <p style={{ textAlign: 'center', marginTop: '16px' }}>
            {`Are you sure you want to delete this ${isChildren ? 'reply' : 'comment'}?`}
            <br />
            This action cannot be undone
          </p>
        </Modal>
      </ThemeProvider>
      <ReactionLayout>
        {!Config.HIDE_WHILE_LAUNCH ? (
          <FlexContainer justify="flex-end" align="center" className="reactions-toolbar">
            {height > 110 && (
              <FlexContainer>
                <FlexContainer align="center" onClick={setOpen}>
                  {open ? <CloseArrow /> : <UpArrow />}
                  <div style={{ color: '#BDBFDF', cursor: 'pointer', width: 65 }}>
                    {!open ? 'See Full' : 'Collapse'}
                  </div>
                </FlexContainer>
              </FlexContainer>
            )}
            <FlexContainer>
              <FlexContainer justify="flex-end" align="center">
                {reactions?.liked ? <PunchIconFilled onClick={unsetLike} /> : <PunchIcon onClick={setLike} />}
                <span style={{ marginRight: 5 }}>{reactions?.likes || 0}</span>
              </FlexContainer>
            </FlexContainer>
            {!isChildren && (
              <FlexContainer>
                <FlexContainer align="center">
                  <CommentsIconUnfilled className="comment-icon-static" />
                  <span style={{ marginRight: 5 }}>{children?.length || 0}</span>
                </FlexContainer>
              </FlexContainer>
            )}
            {userId === user?._id && (
              <FlexContainer>
                <FlexContainer
                  className="delete-btn"
                  align="center"
                  onClick={() => {
                    setShowDelete(true);
                  }}
                >
                  <TrashIcon width={12} height={12} />
                </FlexContainer>
              </FlexContainer>
            )}
            {!isChildren && (
              <FlexContainer>
                <FlexContainer className="share-btn" align="center" onClick={setReply}>
                  {reply ? <ReplyIcon color="#6670F0" fillColor="#6670F0" /> : <ReplyIcon />}
                </FlexContainer>
              </FlexContainer>
            )}
            {userId !== user?._id && (
              <FlexContainer>
                <FlexContainer
                  className="report-btn"
                  align="center"
                  onClick={() => {
                    setShowReportModal(true);
                  }}
                >
                  <FlagIcon fill={showReportModal} />
                </FlexContainer>
              </FlexContainer>
            )}
          </FlexContainer>
        ) : null}
      </ReactionLayout>
    </CommentToolbarLayout>
  );
}

const CommentToolbarLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;
