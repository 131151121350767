import React from 'react';
import { LoadingContext } from '../context/LoadingContext';

const useLoading = () => {
  const context = React.useContext(LoadingContext);

  if (!context) {
    throw new Error('UseLoading must be used within the LoadingProvider');
  }

  return context;
};

export default useLoading;
