import React, { useState } from 'react';
import { CloseIcon } from '../../../assets/icons/svg-icons';
import FlexContainer from '../../UI/FlexContainer';
import Button from '../../UI/Button';
import RadioButton from '../../UI/RadioButton';
import Select from 'react-select';
import { screen } from '../../../utils/constants';

import Modal from '../../UI/modal/Modal';
import { setReportService } from '../../../http/reportService';

import styled from 'styled-components';
import { customStyle } from './styled-components.jsx';

export default function Report({ articleId, commentId, showReportModal, setShowReportModal, onSuccess, onError }) {
  const [radio, setRadio] = useState(null);
  const [categoryValue, setCategoryValue] = useState(null);

  const harmfulList = [
    { value: 'NUDITY', label: 'Nudity' },
    { value: 'VIOLENCE', label: 'Violence' },
    { value: 'HATE_SPEECH', label: 'Hate Speech' },
    { value: 'TROLLING', label: 'Trolling' },
    { value: 'SUICIDE', label: 'Suicide' },
    { value: 'SELF_INJURY', label: 'Self Injury' },
    { value: 'MISLEADING', label: 'Misleading' },
  ];

  const setReport = async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    try {
      let data = {};
      if (articleId) {
        data = { articleId };
      }
      if (commentId) {
        data = { commentId };
      }
      await setReportService({ ...data, flag: radio === 'harmful' ? categoryValue : radio });
      if (onSuccess) {
        onSuccess();
      }
      setShowReportModal(false);
    } catch (e) {
      if (onError) {
        onError(e?.response?.data?.error?.message || 'An error ocurred sending the report');
      }
    }
  };

  const onChangeRadio = (value) => {
    setCategoryValue(null);
    setRadio(value);
  };

  return (
    <Modal
      setVisibility={() => setShowReportModal(showReportModal)}
      visible={showReportModal}
      elmHeight="auto"
      elmWidth="496px"
      footer={null}
    >
      <ReportModal>
        <FlexContainer
          style={{ padding: 16 }}
          justify="space-between"
          align="center"
          className="reactions-toolbar"
          elmWidth="100%"
        >
          <ReportModalTitle>REPORT THIS CONTENT</ReportModalTitle>
          <CloseIcon
            width="10"
            height="10"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowReportModal(false);
            }}
          />
        </FlexContainer>
        <div
          style={{
            width: '100%',
            height: 1,
            backgroundColor: '#151531',
          }}
        />

        <FlexContainer
          style={{ padding: 16 }}
          justify="flex-start"
          align="center"
          className="reactions-toolbar"
          elmWidth="100%"
        >
          <RadioButton checked={radio === 'NSFW'} value="NSFW" onChange={onChangeRadio} />
          <ReportModalOption>Sensitive content</ReportModalOption>
        </FlexContainer>
        <div
          style={{
            width: '95%',
            margin: 'auto',
            height: 1,
            backgroundColor: '#151531',
          }}
        />
        <FlexContainer
          style={{ padding: '8px 16px' }}
          justify="space-between"
          align="center"
          className="reactions-toolbar"
          elmWidth="100%"
        >
          <FlexContainer>
            <RadioButton checked={radio === 'harmful'} value="harmful" onChange={onChangeRadio} />
            <ReportModalOption>Not nice or harmful</ReportModalOption>
          </FlexContainer>

          <Select
            isDisabled={radio !== 'harmful'}
            options={[
              { label: harmfulList && harmfulList.length ? 'Select a reason' : 'Loading...', value: '' },
              ...harmfulList,
            ]}
            styles={customStyle}
            placeholder="Select a reason"
            className="select-category"
            classNamePrefix="category"
            isSearchable={false}
            onChange={(val) => {
              setCategoryValue(val.value);
            }}
            value={harmfulList.filter((c) => c.value === categoryValue)}
          />
        </FlexContainer>
        <div
          style={{
            width: '95%',
            margin: 'auto',
            height: 1,
            backgroundColor: '#151531',
          }}
        />
        <FlexContainer
          style={{ padding: 16 }}
          justify="flex-start"
          align="center"
          className="reactions-toolbar"
          elmWidth="100%"
        >
          <RadioButton checked={radio === 'SPAM'} value="SPAM" onChange={onChangeRadio} />
          <ReportModalOption>Spam</ReportModalOption>
        </FlexContainer>
        <div
          style={{
            width: '95%',
            margin: 'auto',
            height: 1,
            backgroundColor: '#151531',
          }}
        />
        <FlexContainer
          style={{ padding: 16 }}
          justify="flex-start"
          align="center"
          className="reactions-toolbar"
          elmWidth="100%"
        >
          <RadioButton checked={radio === 'OTHER'} value="OTHER" onChange={onChangeRadio} />
          <ReportModalOption>Other</ReportModalOption>
        </FlexContainer>
        <div
          style={{
            width: '95%',
            margin: 'auto',
            height: 1,
            backgroundColor: '#151531',
          }}
        />

        <FlexContainer
          style={{ padding: 20 }}
          justify="center"
          align="center"
          className="reactions-toolbar"
          elmWidth="100%"
        >
          <Button
            modalSecondary
            rounded
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowReportModal(false);
            }}
          >
            CANCEL
          </Button>
          <Button
            disabled={!radio || (radio === 'harmful' && !categoryValue)}
            primary
            rounded
            style={{ marginLeft: 10 }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setReport();
            }}
          >
            REPORT
          </Button>
        </FlexContainer>
      </ReportModal>
    </Modal>
  );
}

const ReportModal = styled.div`
  background: #1f1f3d;
  border-radius: 16px;
  & .select-category.css-2b097c-container {
    @media (max-width: ${screen.SM}) {
      width: 99%;
      margin: 0;
    }
  }

  & .category__control.css-14xdnlq-Control,
  .category__control--is-focused {
    @media (max-width: ${screen.SM}) {
      width: 100%;
      margin: 0;
    }
  }

  & .category__option {
    background: #151531;
    &:hover {
      background: #1f1f3d;
      color: #6670f0;
    }
  }
  & .category__option--is-selected {
    color: #6670f0;
  }
  & .category__single-value {
    color: #bdbfdf;
  }
  & .css-tlfecz-indicatorContainer svg path,
  .css-tlfecz-indicatorContainer svg rect {
    fill: ${(props) => (props.theme.isDark ? props.theme.baseColors.middleLight : props.theme.baseColors.middle)};
    &:focus {
      fill: white;
    }
    &:hover {
      fill: white;
    }
  }

  & .category__control--is-focused .category__dropdown-indicator svg path {
    fill: ${(props) => (props.theme.isDark ? props.theme.baseColors.middleLight : props.theme.baseColors.middle)};
  }

  & .category__control {
    @media (max-width: ${screen.SM}) {
      border-radius: 0 0 8px 8px !important;
      width: 100%;
    }
  }
`;

const ReportModalTitle = styled.div`
  /* Caption - Lato 12 Bold */

  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  /* mid gray */

  color: #bdbfdf;
`;

const ReportModalOption = styled.div`
  /* Body - OpenSans 14 reg */

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: center;

  /* clear BG */

  color: #f6f8ff;
`;
