import React, { useState, useEffect } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { suggestionsFilter } from '../../../utils/utils';
import SuggestionOptions from './SuggestionsOptions';
import { AutocompleteLayout, PlacesAutocompleteContainerLayout } from './styled-components';
import { IoIosClose } from 'react-icons/io';
import { BlueLocationIcon } from '../../../assets/icons/svg-icons';

export default function LocationAutocomplete(props) {
  const { value, addressValue, placeholder, onChangeData, inputValue, onChangeInput } = props;

  const [input, setInput] = useState(inputValue);
  const [address, setAddress] = useState(addressValue);
  const [placeId, setPlaceId] = useState(value);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  useEffect(() => {
    if (inputValue === null) {
      clearValue();
    }
  }, [inputValue]);

  useEffect(() => {
    setAddress(addressValue);
  }, [addressValue]);

  const handleChange = async (address) => {
    setAddress(address);
    setPlaceId(null);
    onChangeInput(address);
  };

  const clearValue = () => {
    setAddress('');
    setPlaceId(null);
    setInput('');
  };

  const handleChangeValidations = async (address, placeId) => {
    setAddress(address);
    setPlaceId(placeId);
    if (onChangeData) {
      return onChangeData(placeId);
    }
  };
  return (
    <PlacesAutocompleteContainerLayout>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleChangeValidations}
        debounce={500}
        shouldFetchSuggestions={!!(address && address.length > 1)}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          const filteredSuggestions = suggestionsFilter(suggestions);
          return (
            <AutocompleteLayout>
              <div className="input-autocomplete-container">
                <BlueLocationIcon className="location-icon" />
                <input
                  {...getInputProps({
                    placeholder: placeholder,
                    className: 'location-search-input',
                  })}
                  autocomplete="nope"
                  onFocus={() => setTooltipVisible(true)}
                  onBlur={() => setTooltipVisible(false)}
                />
                {address ? <IoIosClose onClick={clearValue} className="clear-element" size={30} /> : null}
              </div>
              {tooltipVisible ? (
                <div className="autocomplete-dropdown-container">
                  {filteredSuggestions.map((suggestion, index) => (
                    <SuggestionOptions
                      key={index}
                      suggestion={suggestion}
                      getSuggestionItemProps={getSuggestionItemProps}
                    />
                  ))}
                  {placeId === null ? (
                    <SuggestionOptions
                      getSuggestionItemProps={getSuggestionItemProps}
                      suggestion={{ active: false, description: 'Worldwide', placeId: '' }}
                    />
                  ) : null}
                </div>
              ) : null}
            </AutocompleteLayout>
          );
        }}
      </PlacesAutocomplete>
    </PlacesAutocompleteContainerLayout>
  );
}
