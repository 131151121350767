import React from 'react';
import styled from 'styled-components';
import { Popover, Whisper } from 'rsuite';
import Option from './dropdown/Option';

export const StyledMenu = styled(Popover)`
  padding: 0px;
  border: 1px solid ${(props) => (props.theme.isDark ? 'transparent' : props.theme.baseColors.light)};
  border-radius: 0px 0px 8px 8px;
  min-width: 200px;
  background: ${(props) => (props.theme.isDark ? props.theme.baseColors.darker : 'white')};
  color: ${(props) => (props.theme.isDark ? props.theme.baseColors.middleLight : props.theme.baseColors.middle)};

  .rs-popover-arrow:after {
    border-bottom-color: ${(props) => (props.theme.isDark ? props.theme.baseColors.darker : 'white')} !important;
  }
`;

export const CustomMenu = StyledMenu;

const Menu = ({ options, onSelect, icon, ...props }) => {
  const triggerRef = React.useRef();

  const speaker = (
    <StyledMenu>
      {options
        ? options.map((option, key) => (
            <Option
              option={option}
              key={key}
              onOptionSelect={() => {
                triggerRef.current.close();
                onSelect(option);
              }}
            />
          ))
        : null}
    </StyledMenu>
  );

  return (
    <div height={300}>
      <Whisper trigger="click" speaker={speaker} placement="bottom" ref={triggerRef}>
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {icon}
        </div>
      </Whisper>
    </div>
  );
};

export default styled(Menu)``;
