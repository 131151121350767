import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import Loader from '../Loader';

import { ReactComponent as CameraWhite } from '../../../assets/icons/camera-white.svg';

const Avatar = ({ size, src, editable, loading, onChange, props }) => {
  const fileInputRef = useRef(null);

  const handleUploadImage = async (e) => {
    e.preventDefault();
    const dataSrc = e.target.files[0];
    if (dataSrc) {
      onChange(dataSrc);
    } else {
      return;
    }
  };

  return (
    <Container>
      <input
        onChange={(e) => handleUploadImage(e)}
        className="d-none input-media-file"
        type="file"
        accept="image/*"
        ref={fileInputRef}
      />
      <ImageContainer style={{ height: size, width: size }} {...props}>
        {src && <img src={src} alt="profile" style={{ objectFit: 'cover' }} />}

        {editable && (
          <ActionContainer
            onClick={(e) => {
              e.preventDefault();
              fileInputRef.current.click();
            }}
          >
            {loading ? (
              <div style={{ height: '60%', width: '80%' }}>
                <Loader />
              </div>
            ) : (
              <>
                <CameraWhite style={{ marginBottom: 5 }} />
                {src ? 'EDIT' : 'ADD'}
              </>
            )}
          </ActionContainer>
        )}
      </ImageContainer>
    </Container>
  );
};

const Container = styled.div`
  border: 1px solid white;
  padding: 2px;
  border-radius: 50%;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  position: relative;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionContainer = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  /* Caption - Lato 12 Reg */

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
`;

Avatar.defaultProps = {
  size: 96,
  editable: false,
};

Avatar.propTypes = {
  src: PropTypes.string,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.number,
  loading: PropTypes.bool,
};

export default Avatar;
