import React, { useEffect, useState, useCallback } from 'react';
import { Whisper } from 'rsuite';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Loader from '../UI/Loader';
import FlexContainer from '../UI/FlexContainer';
import Button from '../UI/Button';
import Activity from '../../views/Profile/Activity';
import Alert from '../../views/Profile/Alert';
import { screen } from '../../utils/constants';

import { BellNotification, CloseIcon } from '../../assets/icons/svg-icons';
import { NotificationsButton } from './styled-components';
import { getActivities, getAlertResults } from '../../http/profileService';

const NotificationsMenu = () => {
  const history = useHistory();

  const [activeTab, setActiveTab] = useState('Activity');
  const [activities, setActivities] = useState(null);
  const [alerts, setAlerts] = useState(null);

  const { isMobile } = useSelector((store) => store.app);
  const [loading, setLoading] = useState(false);

  const loadActivities = useCallback(async () => {
    setLoading(true);
    const response = await getActivities(3, null);
    if (response.data) {
      setActivities(response.data);
    }
    setLoading(false);
  }, []);

  const loadAlerts = useCallback(async () => {
    setLoading(true);
    const response = await getAlertResults();
    if (response.data) {
      const flat = response?.data
        ?.reduce((previousValue, currentValue) => {
          return previousValue.concat(currentValue.results);
        }, [])
        .slice(0, 3);
      setAlerts(flat);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (activeTab === 'Activity') {
      loadActivities();
    } else if (activeTab === 'Alerts') {
      loadAlerts();
    }
  }, [loadActivities, loadAlerts, activeTab]);

  useEffect(() => {
    loadActivities();
  }, [loadActivities]);

  const Overlay = React.forwardRef(({ style, onClose, ...rest }, ref) => {
    const styles = {
      ...style,
      top: isMobile ? 0 : 90,
      width: isMobile ? '100%' : 600,
      height: isMobile ? '100%' : 620,
      padding: 10,
      background: '#151531',
      color: '#f6f8ff',
      zIndex: 99,
      position: 'fixed',
      borderRadius: '4px 4px 4px 4px',
      boxShadow: '0px 0px 7px #151531',
    };
    return (
      <ThemeProvider theme={{ isDark: true }}>
        <div {...rest} style={styles} ref={ref}>
          <button
            onClick={onClose}
            style={{ backgroundColor: 'transparent', position: 'absolute', top: 10, right: 10 }}
          >
            <CloseIcon height={10} width={10} />
          </button>
          <Tabs>
            <Tab activeTab={activeTab} onClick={() => setActiveTab('Activity')}>
              Activity
            </Tab>
            <Tab activeTab={activeTab} onClick={() => setActiveTab('Alerts')}>
              Alerts
            </Tab>
          </Tabs>
          <FlexContainer
            id="scrollableDiv"
            style={{ height: '100%', overflow: 'scroll', width: '100%', display: 'flex', flexDirection: 'column' }}
          >
            {loading && (
              <FlexContainer style={{ width: '100%', marginTop: 40 }} justify="center" align="center">
                <Loader color="#fff" />
              </FlexContainer>
            )}
            {activeTab === 'Activity' && (
              <ActivityPanel>
                {activities?.map((activity) => (
                  <Activity
                    type={activity?.type || ''}
                    count={activity?.count || 0}
                    article={activity?.article}
                    created_at={activity?.created_at}
                    onClick={onClose}
                  />
                ))}
              </ActivityPanel>
            )}
            {activeTab === 'Alerts' && (
              <AlertsPanel>
                {alerts?.map((alert) => (
                  <Alert key={alert._id} {...alert} onClick={onClose} />
                ))}
              </AlertsPanel>
            )}
            <div style={{ position: 'absolute', bottom: 20, width: '100%' }}>
              <Button
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
                primary
                onClick={() => {
                  if (activeTab === 'Alerts') {
                    history.push('/profile#alerts');
                  } else if (activeTab === 'Activity') {
                    history.push('/profile#activity');
                  }
                  if (onClose) {
                    onClose();
                  }
                }}
                disabled={loading}
              >
                SEE MORE
              </Button>
            </div>
          </FlexContainer>
        </div>
      </ThemeProvider>
    );
  });

  return (
    <Whisper
      trigger="click"
      placement="left"
      speaker={(props, ref) => {
        const { className, left, top, onClose } = props;
        if (left === 0) {
          loadActivities();
        }
        return (
          <Overlay style={{ left: left + 130, top: top + 80 }} onClose={onClose} className={className} ref={ref} />
        );
      }}
    >
      <NotificationsButton transparent secondary icon>
        <BellNotification />
      </NotificationsButton>
    </Whisper>
  );
};

const Tabs = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  aling-items: center;
  padding: 2px 0px;
  border-bottom: 1px solid #fff;
`;

const Tab = styled.div`
  -webkit-user-select: none;
  cursor: pointer;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  aling-items: center;
  font-weight: bold;
  &:hover {
    background: #1f1f3d;
    color: #6670f0;
  }
  color: ${(props) => (props.children === props.activeTab ? '#6670f0' : '#fff')};
  border-bottom: ${(props) => (props.children === props.activeTab ? '2px solid #6670f0' : 'none')};
`;

const ActivityPanel = styled.div`
  width: 100%;
  padding: 20px 24px;
`;

const AlertsPanel = styled.div`
  padding: 0px 24px;
  width: 100%;
  & .select-category.css-2b097c-container {
    @media (max-width: ${screen.SM}) {
      width: 99%;
      margin: 0;
    }
  }

  & .category__control.css-14xdnlq-Control,
  .category__control--is-focused {
    @media (max-width: ${screen.SM}) {
      width: 100%;
      margin: 0;
    }
  }

  & .category__option {
    background: #151531;
    &:hover {
      background: #1f1f3d;
      color: #6670f0;
    }
  }
  & .category__option--is-selected {
    color: #6670f0;
  }
  & .category__single-value {
    color: #bdbfdf;
  }
  & .css-tlfecz-indicatorContainer svg path,
  .css-tlfecz-indicatorContainer svg rect {
    fill: ${(props) => (props.theme.isDark ? props.theme.baseColors.middleLight : props.theme.baseColors.middle)};
    &:focus {
      fill: white;
    }
    &:hover {
      fill: white;
    }
  }

  & .category__control--is-focused .category__dropdown-indicator svg path {
    fill: ${(props) => (props.theme.isDark ? props.theme.baseColors.middleLight : props.theme.baseColors.middle)};
  }

  & .category__control {
    @media (max-width: ${screen.SM}) {
      border-radius: 0 0 8px 8px !important;
      width: 100%;
    }
  }
`;

export default NotificationsMenu;
