import React from 'react';
import styled from 'styled-components';
import 'rsuite/dist/styles/rsuite-default.css';
import { Drawer } from 'rsuite';
import { Link } from 'react-router-dom';
import { screen } from '../../utils/constants';
import { setAuthorization } from '../../redux/reducers/authState';
import { setVisibleSettings } from '../../redux/reducers/appState';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const SidebarLayout = styled(Drawer)`
  @media (max-width: ${screen.SM}) {
    width: 300px !important;
  }

  & .rs-drawer-content {
    color: #bdbfdf;
    background-color: #151531;

    & .menu-list-item {
      list-style: none;
      color: #bdbfdf;
      & li {
        padding: 7px;
        border-bottom: 1px solid #1f1f3d;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          color: #6670f0;
          background-color: #1f1f3d;
        }

        & a {
          color: inherit;
          text-decoration: none;
          outline: 0;
        }
      }
    }
  }

  & .rs-drawer-header-close {
    color: #bdbfdf;
    font-size: 17px;
    &:hover {
      color: white;
    }
  }
`;

export default function Sidebar({ shown, isShown }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { authorization, user } = useSelector((store) => store.auth);

  return (
    <SidebarLayout show={shown} onHide={() => isShown(false)} backdropClassName="buskers">
      {/* <Drawer.Header><Drawer.Title></Drawer.Title></Drawer.Header> */}
      <Drawer.Body>
        <ul className="menu-list-item">
          {authorization && (
            <li>
              <Link to="/profile" onClick={() => isShown(false)}>
                Profile
              </Link>
            </li>
          )}
          <li>
            <Link to="/general/buskers-guide-etiquette-content-policy" onClick={() => isShown(false)}>
              Site rules
            </Link>
          </li>
          {authorization && (
            <li>
              <Link
                to="/profile"
                onClick={() => {
                  dispatch(setVisibleSettings(true));
                  isShown(false);
                }}
              >
                Settings
              </Link>
            </li>
          )}
          <li>
            <Link to="/general/why-did-we-create-the-busker-s-guide-to-the-galaxy" onClick={() => isShown(false)}>
              About the busking project
            </Link>
          </li>
          <li>
            <Link to="/general/user-agreement" onClick={() => isShown(false)}>
              Terms and conditions
            </Link>
          </li>
          <li>
            <Link to="/general/faqs" onClick={() => isShown(false)}>
              FAQ's
            </Link>
          </li>
          {authorization && (
            <li>
              <Link to="/create" onClick={() => isShown(false)}>
                Create Article
              </Link>
            </li>
          )}
          {authorization && (
            <li>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (window.location.pathname === '/profile') {
                    history.push(`/profile/${user._id}`);
                  }
                  dispatch(setAuthorization(false));
                  isShown(false);
                }}
              >
                Log out
              </div>
            </li>
          )}
        </ul>
      </Drawer.Body>
      <Drawer.Footer>footer</Drawer.Footer>
    </SidebarLayout>
  );
}
