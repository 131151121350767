import React, { useEffect, useRef, useState } from 'react';
import FlexContainer from '../UI/FlexContainer';
import Tag from '../UI/Tag';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getKeywordsSuggested, setSelectedKeyword, removeKeyword, setSort } from '../../redux/reducers/topbarSearch';
import _ from 'lodash';
import { IoIosClose } from 'react-icons/io';
import { LeftArrowIcon, RightArrowIcon, SortIcon, PlusIcon, DropdownIcon } from '../../assets/icons/svg-icons';
import { setVisibleSearch } from '../../redux/reducers/appState';
import Menu from '../UI/Menu';
import { ThemeProvider } from 'styled-components';

const sortOptions = [
  { description: 'Sort by Popular', value: 'popularity' },
  { description: 'Sort by Recent', value: 'created_at' },
  { description: 'Sort by Best', value: 'best' },
];

export default function KeywordsSection({ isMobile }) {
  const { loading, keywordSuggestions, keywordsSelected, categoryValue, locationValue, sortValue } = useSelector(
    (store) => store.topbarSearch
  );

  const { showSearch } = useSelector((store) => store.app);
  const dispatch = useDispatch();

  const keywordContainer = useRef(null);
  const addTag = (tag) => {
    dispatch(setSelectedKeyword(tag));
  };
  const [visibleRight, setVisibleRight] = useState(true);
  const [visibleLeft, setVisibleLeft] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(0);
  const buildKeywordsArr = (available, selected) => {
    const availableFiltered = _.difference(available, selected);
    return availableFiltered;
  };

  const handleShowLeftArrow = () => {
    if (keywordContainer && keywordContainer.current) {
      const currentScrollPosition = keywordContainer.current.scrollLeft;
      if (currentScrollPosition !== 0) {
        setVisibleLeft(true);
      } else {
        setVisibleLeft(false);
      }
    }
  };
  const handleShowRightArrow = () => {
    if (keywordContainer && keywordContainer.current) {
      const scrollableWidth = keywordContainer.current.scrollWidth;
      const noScrollableWidth = Math.ceil(keywordContainer.current.offsetWidth);
      const currentScrollPosition = keywordContainer.current.scrollLeft;
      const topScroll = Math.ceil(scrollableWidth - currentScrollPosition);
      if (topScroll === noScrollableWidth) {
        setVisibleRight(false);
      } else {
        setVisibleRight(true);
      }
    }
  };

  const handleRightScroll = () => {
    if (keywordContainer && keywordContainer.current) {
      setCurrentPosition((keywordContainer.current.scrollLeft += 50));
    }
  };
  const handleLeftScroll = () => {
    if (keywordContainer && keywordContainer.current) {
      setCurrentPosition((keywordContainer.current.scrollLeft -= 50));
    }
  };
  const keywordsFiltered = buildKeywordsArr(keywordSuggestions, keywordsSelected);
  useEffect(() => {
    dispatch(getKeywordsSuggested(categoryValue, locationValue, keywordsSelected));
    handleShowLeftArrow();
    handleShowRightArrow();
    let keywordContainerRef;
    if (keywordContainer && keywordContainer.current) {
      keywordContainer.current.addEventListener('scroll', () => {
        handleShowLeftArrow();
        handleShowRightArrow();
      });
      keywordContainerRef = keywordContainer.current;
    }

    return () => {
      if (keywordContainerRef) {
        keywordContainerRef.removeEventListener('scroll', () => {
          handleShowLeftArrow();
          handleShowRightArrow();
          keywordContainerRef.scrollLeft = 0;
        });
      }
    };
  }, [
    categoryValue,
    locationValue,
    visibleRight,
    visibleLeft,
    currentPosition,
    keywordSuggestions.length,
    keywordsSelected,
    showSearch,
    isMobile,
    dispatch,
  ]);

  const onSortOptionSelect = (option) => {
    dispatch(setSort(option.value));
    dispatch(setVisibleSearch(false));
    //dispatch(getArticlesFiltered(searchParam, locationValue, categoryValue, sortValue, keywordsSelectedValue));
  };
  return (
    <KeywordsSectionLayout className="keywords-container" align="center">
      <SorterContainer justify="flex-start">
        <DropdownLayout>
          <ThemeProvider theme={{ isDark: true }}>
            <Menu
              onSelect={onSortOptionSelect}
              options={sortOptions.filter((item) => item.value !== sortValue)}
              value={sortValue}
              icon={
                <div className="input-autocomplete-container">
                  <SortIcon className="location-icon" />
                  <input
                    className="location-search-input"
                    value={sortOptions.find((item) => item.value === sortValue)?.description}
                    readOnly={true}
                  />
                  <DropdownIcon />
                </div>
              }
            />
          </ThemeProvider>
        </DropdownLayout>
        {!showSearch ? (
          <button onClick={() => dispatch(setVisibleSearch(true))}>
            <PlusIcon /> Show search
          </button>
        ) : (
          <button onClick={() => dispatch(setVisibleSearch(false))}>
            <IoIosClose size={24} /> Hide search
          </button>
        )}
      </SorterContainer>
      {(isMobile && showSearch) || !isMobile ? (
        <KeywordsContainer className="keywords-hidden">
          {visibleLeft && keywordsFiltered?.length > 0 && (
            <>
              <div className="left-arrow">
                <button onClick={handleLeftScroll}>
                  <LeftArrowIcon />
                </button>
              </div>
              <div className="left-arrow-blurred"></div>
            </>
          )}

          <div ref={keywordContainer} className="keywords">
            {loading && (
              <div style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 1 }}>
                <FlexContainer justify="center">{/* <Loader color="#6670F0" /> */}</FlexContainer>
              </div>
            )}
            {keywordsSelected?.length > 0 &&
              keywordsSelected.map((tag, index) => (
                <Tag className="selected-keywords" key={index} mt tagType="primary">
                  {tag}
                  <IoIosClose
                    className="closable"
                    size={24}
                    onClick={() => {
                      if (!loading) {
                        dispatch(removeKeyword(tag));
                      }
                    }}
                  />
                </Tag>
              ))}
            {keywordsFiltered?.length > 0 &&
              keywordsFiltered.map((tag, index) => (
                <Tag
                  key={index}
                  mt
                  tagType="secondary"
                  onClick={() => {
                    if (!loading) {
                      addTag(tag);
                    }
                  }}
                >
                  {tag}
                </Tag>
              ))}
          </div>
          {visibleRight && keywordsFiltered?.length > 0 && (
            <>
              <div className="right-arrow">
                <button onClick={handleRightScroll}>
                  <RightArrowIcon />
                </button>
              </div>
              <div className="right-arrow-blurred"></div>
            </>
          )}
        </KeywordsContainer>
      ) : null}
    </KeywordsSectionLayout>
  );
}

const SorterContainer = styled(FlexContainer)`
  width: 10%;
  @media (max-width: 864px) {
    width: 100%;
    order: 2;
    background: #151531;
    justify-content: space-between;
  }

  & button {
    display: none;
    @media (max-width: 864px) {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: 0;
      background: none;
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      color: #9695b7;
      height: 40px;

      & svg path,
      svg rect,
      svg circle {
        fill: #9695b7;
        stroke: #9695b7;
      }
    }
  }
`;

const KeywordsContainer = styled(FlexContainer)`
  width: 970px;
  overflow: hidden;
  overflow-x: scroll;
  align-items: center;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 600px) {
    width: 100vw;
  }
`;

const KeywordsSectionLayout = styled(FlexContainer)`
  position: relative;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  & > div:first-child {
    padding-right: 15px;
    margin-right: 15px;
    border-right: solid 1px #151531;
    width: 200px;
    @media (max-width: 600px) {
      width: 100vw;
      padding-right: 0px;
      margin-right: 0px;
    }
  }
  & .keywords {
    width: auto;
    white-space: nowrap;
    overflow-y: hidden;
    position: relative;
    /* box-shadow: inset 0 20px 20px rgba(21, 21, 49, 0.7);*/
    & > span {
      cursor: pointer;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const DropdownLayout = styled.div`
  position: relative;
  margin-left: 2px;
  margin-right: 2px;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  z-index: 10;
  background: ${(props) => (props.focused ? '#151531' : '#1F1F3D')};
  & .input-autocomplete-container {
    position: relative;
  }
  & input {
    all: unset;
    font-family: 'Open Sans';
    font-size: 14px;
    line-height: 22px;
    width: 100px;
    padding: 9px 15px 9px 9px;
    background: ${(props) => (props.focused ? '#151531' : '#1F1F3D')};
    border: none;
    color: ${(props) => (props.focused ? '#f6f8ff' : '#BDBFDF')};
    cursor: pointer;
    &:focus {
      border: none;
      color: #f6f8ff;
    }
    &::placeholder {
      color: ${(props) => (props.theme.isDark ? props.theme.baseColors.middleLight : props.theme.baseColors.middle)};
    }
  }

  & .clear-element {
    position: absolute;
    top: 15%;
    left: 90%;
    font-size: 19px;
    cursor: pointer;
    opacity: 0.7;
    color: ${(props) => props.theme.baseColors.middleLight};
    &:hover {
      color: white;
      opacity: 1;
    }
  }

  & .location-icon {
    position: relative;
    width: 16px;
    height: 16px;
    left: 5px;
    & path,
    rect {
      fill: rgb(102, 112, 240);
    }
  }

  & .autocomplete-dropdown-container {
    border-radius: 0 0 8px 8px;
    background-color: #151531;
    color: #bdbfdf;
    position: absolute;
    z-index: 99;
    width: 100%;
    top: 100%;
    box-shadow: 0px 1px 7px 0px #151531;
  }

  & .action-button {
    position: absolute;
    top: 20%;
    left: 100%;
  }
`;
