import React from 'react';

import CommentView from '../CommentView/CommentView';

export default function Comment({
  articleId,
  _id,
  comment,
  created_at,
  likes,
  user,
  liked,
  children,
  userId,
  onDeleteComment,
  onUpVote,
}) {
  return (
    <CommentView
      articleId={articleId}
      _id={_id}
      comment={comment}
      created_at={created_at}
      likes={likes}
      user={user}
      liked={liked}
      children={children}
      userId={userId}
      onDeleteComment={onDeleteComment}
      onUpVote={onUpVote}
    />
  );
}
