import axios from 'axios';
import Config from './Config';
import { store } from '../redux/store';
import { setAuthorization } from '../redux/reducers/authState';
import { setVisibleLogin } from '../redux/reducers/appState';

const API = axios.create({
  baseURL: Config.API_URL,
});

API.getCategories = () => {
  return API.get('/categories');
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if ([401, 403].includes(error?.response?.status)) {
      const { dispatch } = store;
      dispatch(setAuthorization(false));
      dispatch(setVisibleLogin(true));
    }
    return Promise.reject(error);
  }
);

export default API;
