import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import styled from 'styled-components';
import { Prompt } from 'react-router-dom';
import CommentToolbar from '../../UI/reaction-toolbar/CommentToolbar';
import CommenterInfo from '../Comments/CommenterInfo';
import { CommentBoxHeader } from '../Comments/styled-components';
import CommentBox from '../../../components/ArticleDetails/Comments/CommentBox';
import { onPostComment, onGetComments } from '../../../redux/actions/commentActions';
import FlexContainer from '../../../components/UI/FlexContainer';
import { UpArrow, CloseArrow } from '../../../assets/icons/svg-icons';
import { useHistory } from 'react-router-dom';

import Collapse from '@kunukn/react-collapse';
import moment from 'moment';

import Modal from '../../../components/UI/modal/Modal';
import { useModal } from '../../../components/UI/modal/useModal';
import { useParams } from 'react-router-dom';
import { getArticleDetailBySlug } from '../../../redux/reducers/articleDetail';

export default function CommentView({
  articleId,
  _id,
  userId,
  comment,
  created_at,
  likes,
  user,
  liked,
  children,
  isChildren = false,
}) {
  const { slug } = useParams();
  const modal = useModal();
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);

  const history = useHistory();

  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [reply, setReply] = useState(false);
  const [openReplies, setOpenReplies] = useState(true);
  const [canLeave, setCanLeave] = useState(true);
  const [showPrompt, setShowPrompt] = useState(false);
  const [height, setHeight] = useState(0);
  const [firstTime, setFirstTime] = useState(true);

  const [childHeight, setChildHeight] = useState(0);

  const commentContainerRef = useRef(null);
  const lastChildComment = useRef(null);
  const refCommentBox = useRef(null);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  const alertUser = useCallback(
    (e) => {
      if (!canLeave) {
        e.preventDefault();
        setShowPrompt(true);

        e.returnValue = true;

        return '';
      }
    },
    [canLeave]
  );

  useEffect(() => {
    setShowPrompt(!canLeave);
    window.addEventListener('beforeunload', alertUser);
    //window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', alertUser);

    return () => {
      window.removeEventListener('beforeunload', alertUser);
      window.removeEventListener('popstate', alertUser);
    };
  }, [canLeave, alertUser]);

  useEffect(() => {
    setHeight(commentContainerRef.current.clientHeight);
  }, [commentContainerRef]);

  useEffect(() => {
    setOpen(false);
  }, [height]);

  useEffect(() => {
    if (childHeight !== 0) {
      setOpenReplies(false);
    }
  }, [childHeight]);

  useEffect(() => {
    if (openReplies && reply && refCommentBox?.current) {
      setTimeout(() => {
        refCommentBox.current.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        });
      }, 301);
    }
  }, [reply, openReplies]);

  useEffect(() => {
    if (firstTime && childHeight === 0 && children?.length > 0) {
      if (children?.length === 1) {
        setChildHeight(270);
      } else if (children?.length === 2) {
        setChildHeight(500);
      } else if (children?.length >= 3) {
        setChildHeight(700);
      } else {
        setChildHeight(0);
      }
    }
  }, [children, firstTime, childHeight]);

  const handlePostComment = async (comment) => {
    await dispatch(
      onPostComment({
        articleId: articleId,
        parentCommentId: _id,
        comment: comment.comment,
        recaptchaToken: comment.recaptchaToken,
      })
    );
    dispatch(getArticleDetailBySlug(slug, true));
    setReply(false);
    setCanLeave(true);
    if (lastChildComment?.current) {
      const y = lastChildComment?.current.getBoundingClientRect().top + window.pageYOffset + -200;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const onExitClick = () => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && showPrompt) {
      setLastLocation(nextLocation);
      modal.handleClick();
      return false;
    }

    return true;
  };

  return (
    <CommentContainer style={reply ? { backgroundColor: '#F6F8FF' } : null}>
      <Prompt when={showPrompt} message={handleBlockedNavigation} />
      <ThemeProvider theme={{ isDark: true }}>
        <Modal
          title="Exit Page"
          setVisibility={modal.handleClick}
          visible={modal.visible}
          elmHeight="auto"
          elmWidth="496px"
          textOk="Leave"
          okClick={onExitClick}
          header={undefined}
        >
          <p style={{ textAlign: 'center', marginTop: '16px' }}>
            You have unfinished changes,
            <br /> If you leave now you may lose them.
          </p>
        </Modal>
      </ThemeProvider>
      {isChildren && (
        <div
          style={{
            position: 'absolute',
            width: 2,
            height: 'calc(100% - 30px)',
            marginLeft: -10,
            backgroundColor: '#F6F8FF',
          }}
        />
      )}
      <CommentBoxHeader style={{ alignItems: 'center' }}>
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (user?._id && user?.name !== '[DELETED]') {
              if (e.ctrlKey || e.metaKey) {
                window.open(`/profile/${user?._id}`);
              } else {
                history.push(`/profile/${user?._id}`);
              }
            }
          }}
        >
          <CommenterInfo
            profileImage={user?.photo?.content?.small ? user?.photo?.content?.small : 'https://via.placeholder.com/24'}
            name={user?.name || ''}
            style={{ flexGrow: 0, cursor: 'pointer' }}
          />
          <DateText>{created_at ? moment(created_at).format('MMMM DD, YYYY') : ''}</DateText>
        </div>
      </CommentBoxHeader>
      <CommentBody>
        <Collapse
          elementType="article"
          isOpen={open}
          transition="height 300ms cubic-bezier(0.4, 0, 0.2, 1)"
          collapseHeight={height < 110 ? `${height + 20}px` : '110px'}
        >
          <div ref={commentContainerRef}>
            {comment.split(' ').map((comm, index) => {
              if (
                // eslint-disable-next-line
                comm.match(/(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/g)
              ) {
                return (
                  <a href={comm} target="_blank" rel="noopener noreferrer">
                    {comm}{' '}
                  </a>
                );
              }
              if (comm.includes('\n')) {
                return comm.split('\n').map((i, key) => {
                  if (comm.split('\n').length === key + 1) {
                    return i + ' ';
                  } else {
                    return (
                      <>
                        {i}
                        <br />
                      </>
                    );
                  }
                });
              } else {
                return comm + ' ';
              }
            })}
          </div>
        </Collapse>
      </CommentBody>
      <CommentToolbar
        height={height}
        commentId={_id}
        userId={userId}
        user={user}
        children={children}
        likes={likes}
        liked={liked}
        setOpen={() => setOpen(!open)}
        open={open}
        setReply={() => {
          setReply(!reply);
          setOpenReplies(true);
          setFirstTime(false);
        }}
        reply={reply}
        isChildren={isChildren}
        onUpdate={() => {
          dispatch(onGetComments(articleId));
        }}
      />
      {children?.length > 0 && (
        <>
          <RepliesContainer
            onClick={() => {
              setOpenReplies(!openReplies);
              setReply(false);
              setChildHeight(0);
              setFirstTime(false);
              setFirstTime(false);
            }}
          >
            <RepliesText>{`${children?.length} Replies`}</RepliesText>
            <FlexContainer align="center">
              {openReplies ? <CloseArrow /> : <UpArrow />}
              <ActionText>{openReplies ? 'Collapse' : 'See All'}</ActionText>
            </FlexContainer>
          </RepliesContainer>
          <ChildrenView>
            <Collapse
              elementType="article"
              isOpen={openReplies || firstTime}
              transition="height 300ms cubic-bezier(0.4, 0, 0.2, 1)"
              collapseHeight={0}
            >
              {children.map((comment, index) => {
                return (
                  <div ref={index === children.length - 1 ? lastChildComment : null}>
                    {(index <= 2 || !firstTime) && (
                      <CommentView
                        key={comment?._id}
                        articleId={articleId}
                        {...comment}
                        user={comment?.user}
                        isChildren={true}
                      />
                    )}
                  </div>
                );
              })}
            </Collapse>
          </ChildrenView>
        </>
      )}
      <div ref={refCommentBox}>
        {reply && (
          <ChildrenView>
            <CommentBox onPostComment={handlePostComment} setCanLeave={setCanLeave} isChildren />
          </ChildrenView>
        )}
      </div>
    </CommentContainer>
  );
}

const RepliesText = styled.div`
  /* Caption - Lato 12 Bold */

  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  /* dark */

  color: #1f1f3d;
`;

const ActionText = styled.div`
  /* Caption - Lato 12 Reg */

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  margin-left: 10px;

  /* dark gray */

  color: #9695b7;
`;

const RepliesContainer = styled.div`
  cursor: pointer;
  width: 100%;
  background: #f6f8ff;
  height: 32px;
  margin: 15px 0;
  padding: 0px 10px 0px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CommentContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 14px;
`;

const CommentBody = styled.div`
  width: 100%;
  text-align: justify;
  margin-top: 16px;
  font-size: 14px;
  font-family: 'Open Sans';
`;

const DateText = styled.span`
  font-size: 12px;
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  color: #9695b7;
  margin-left: 5px;
`;

const ChildrenView = styled.div`
  width: 100%;
  padding-left: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
`;
