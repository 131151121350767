import React from 'react';

const LoadingContext = React.createContext();

function LoadingProvider(props) {
  const [loading, setLoading] = React.useState(false);
  const value = [loading, setLoading];
  return <LoadingContext.Provider value={value} {...props} />;
}

export { LoadingContext, LoadingProvider };
