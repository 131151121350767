import React from 'react';
import { OptionsLayout } from './styled-components';

export default function Option({ option, onOptionSelect }) {
  return (
    <OptionsLayout
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onOptionSelect(option);
      }}
    >
      <span>{option.description}</span>
    </OptionsLayout>
  );
}
