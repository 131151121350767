import { getComments, postComment } from '../../http/articleService';

export const POST_COMMENT = 'POST_COMMENT';
export const GET_COMMENTS = 'GET_COMMENTS';
export const COMMENTS_LOADING = 'COMMENTS_LOADING';
export const COMMENT_LOADING = 'COMMENT_LOADING';

export const onPostComment = (data) => async (dispatch) => {
  dispatch({ type: COMMENT_LOADING, payload: true });
  try {
    await postComment(data);
    await dispatch(onGetComments(data.articleId));
    dispatch({ type: COMMENT_LOADING, payload: false });
  } catch {
    dispatch({ type: COMMENT_LOADING, payload: false });
  }
};

export const onGetComments = (articleId) => async (dispatch) => {
  dispatch({ type: COMMENTS_LOADING, payload: true });

  try {
    const { data } = await getComments(articleId);
    dispatch({ type: GET_COMMENTS, payload: data.reverse() });
    dispatch({ type: COMMENTS_LOADING, payload: false });
  } catch {
    dispatch({ type: COMMENTS_LOADING, payload: false });
  }
};
