import React, { useState } from 'react';
import { ImagesContainer, ImageWrapper, ImageItem } from './styled-components';
import { Portal } from 'rsuite';
import styled from 'styled-components';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';

const ImageEditorComponent = (props) => {
  const [currentPhoto, setCurrentPhoto] = useState({ show: false, currentIndex: 0 });
  const { show, currentIndex } = currentPhoto;
  const [proportionTo, setProportionTo] = useState('width');
  const { images } = props;

  React.useLayoutEffect(() => {
    if (images && images[currentIndex]) {
      const img = new Image();
      img.src = images[currentIndex];
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      const proportion = width / height;
      if (proportion < 0.99) {
        setProportionTo('width');
      } else {
        setProportionTo('height');
      }
    }
  }, [show, currentIndex, images]);
  return (
    <ImagesContainer aria-label="Photos" role="group">
      {props.images.map((item, imgIndex) => (
        <GalleryImage
          setShow={setCurrentPhoto}
          length={props.images.length}
          item={item}
          imgIndex={imgIndex}
          key={item.imageId}
        />
      ))}
      <ImageModal
        proportionTo={proportionTo}
        setShow={setCurrentPhoto}
        images={images}
        currentIndex={currentIndex}
        show={show}
      />
    </ImagesContainer>
  );
};

ImageEditorComponent.defaultProps = {
  images: [],
};

function GalleryImage({ item, imgIndex, length, setShow }) {
  const img = item.url.medium || item.url;
  const imgRef = React.useRef(null);

  return (
    <>
      <ImageWrapper position={imgIndex} length={length}>
        <ImageItem ref={imgRef} onClick={() => setShow({ show: true, currentIndex: imgIndex })} src={img} />
      </ImageWrapper>
    </>
  );
}

const ImageModal = ({ images, currentIndex, show, setShow, proportionTo }) => {
  const img = images[currentIndex].url.medium || images[currentIndex].url;
  return show ? (
    <Portal>
      <FullImage
        proportion={proportionTo}
        onClick={(e) => {
          setShow({ show: false, currentIndex: currentIndex });
        }}
      >
        <span className="left">
          {currentIndex > 0 && (
            <FaChevronLeft
              onClick={(e) => {
                e.stopPropagation();
                setShow({ show: true, currentIndex: currentIndex - 1 });
              }}
              size={40}
              color="white"
            />
          )}
        </span>

        <div>
          <img onClick={() => setShow({ show: false, currentIndex: currentIndex })} src={img} alt="" />
        </div>

        <span className="right">
          {currentIndex < images.length - 1 && (
            <FaChevronRight
              onClick={(e) => {
                e.stopPropagation();
                setShow({ show: true, currentIndex: currentIndex + 1 });
              }}
              size={40}
              color="white"
            />
          )}
        </span>
      </FullImage>
    </Portal>
  ) : null;
};

const FullImage = styled.figure`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 30;
  top: 0;
  margin: 0;
  & div {
    width: 70vw;
    height: 70vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      object-fit: scale-down;
      /* width: ${(props) => (props.proportion === 'width' ? '40vw' : 'auto')};
      height: ${(props) => (props.proportion === 'height' ? '70vh' : 'auto')}; */
      width: 100%;
      height: 100%;
    }
  }
  & span {
    z-index: 50;
    width: 40px;
    height: 40px;
    padding: 20px;
    opacity: 0.6;
    transition: 0.2s;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
`;

export default ImageEditorComponent;
