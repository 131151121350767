import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Ellipse } from '../../assets/icons/svg-icons';
import {
  ContributionDate,
  ContributionArticleTitle,
  ContributionText,
  ContributionContainer,
} from './styled-components';
import FlexContainer from '../../components/UI/FlexContainer';
import ToolbarReactions from '../../components/UI/reaction-toolbar/ToolbarReactions';

const Contribution = ({
  type,
  created_at,
  article: { title, textContent, categoryId, contentTypeId, location, slug, comments, liked, likes, _id },
  comment,
}) => {
  const history = useHistory();
  created_at = moment(created_at);
  return (
    <ContributionContainer
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.ctrlKey || e.metaKey) {
          window.open(`/${categoryId.toLowerCase()}/${slug}`);
        } else {
          history.push(`/${categoryId.toLowerCase()}/${slug}`);
        }
      }}
    >
      {created_at.isValid() ? <ContributionDate>{created_at.format('MMM DD, YYYY')}</ContributionDate> : null}
      {type === 'CREATE_ARTICLE' ? (
        <>
          <div className="breadcrumb">
            <div
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (e.ctrlKey || e.metaKey) {
                  window.open(`/?category=${categoryId}&sort=created_at`);
                } else {
                  history.push(`/?category=${categoryId}&sort=created_at`);
                }
              }}
            >
              {categoryId}
            </div>
            <Ellipse />
            <div
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (e.ctrlKey || e.metaKey) {
                  window.open(`/?search=${contentTypeId}&sort=created_at`);
                } else {
                  history.push(`/?search=${contentTypeId}&sort=created_at`);
                }
              }}
            >
              {contentTypeId}
            </div>
            <Ellipse />
            <div
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (!location?.place_id) {
                  return;
                }
                if (e.ctrlKey || e.metaKey) {
                  window.open(`/?locationID=${location?.place_id}&locationName=${location?.locality}&sort=created_at`);
                } else {
                  history.push(`/?locationID=${location?.place_id}&locationName=${location?.locality}&sort=created_at`);
                }
              }}
            >
              {location?.locality || 'Worldwide'}
            </div>
          </div>
          <ContributionArticleTitle>{title}</ContributionArticleTitle>
          <ContributionText>{textContent}</ContributionText>
          <FlexContainer align="center" justify="flex-end" className="reactions-toolbar" elmWidth="100%">
            <ToolbarReactions
              articleId={_id}
              liked={liked}
              likes={likes}
              comments={comments}
              hideEllipses
              onComments={(e) => {
                e.stopPropagation();
                if (e.ctrlKey || e.metaKey) {
                  window.open(`/${categoryId.toLowerCase()}/${slug}?goComments=true`);
                } else {
                  history.push(`/${categoryId.toLowerCase()}/${slug}?goComments=true`);
                }
              }}
            />
          </FlexContainer>
        </>
      ) : null}
      {type === 'COMMENT_ARTICLE' ? (
        <>
          <ContributionText>
            Commented on <Link to={`/${categoryId.toLowerCase()}/${slug}`}>{title}</Link>
          </ContributionText>
          <ContributionText>{comment?.comment}</ContributionText>
        </>
      ) : null}
    </ContributionContainer>
  );
};

export default Contribution;
