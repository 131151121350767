import React, { useState, useRef, useEffect } from 'react';
import { EditorLayout, TextToolbarFixed, MediaToolbarFixed } from './styled-components';
import { Editor, EditorState, Modifier, RichUtils, SelectionState } from 'draft-js';
import TextFormat from './style-toolbar/TextFormat';
import 'draft-js/dist/Draft.css';
import InsertLink from './widgets/link/InsertLink';
import MediaToolbar from './media-toolbar/MediaToolbar';
import ArticleEmbed from '../ShareArticle/ArticleEmbed';
import EmbedPreview from '../ShareEmbed/EmbedPreview';
import ImageEditorComponent from '../ImageEditor/ImageEditorComponent';
import { uploadImage } from '../../../http/createArticleService';
import FlexContainer from '../../UI/FlexContainer';
import Popover from 'react-text-selection-popover';
import { useDispatch, useSelector } from 'react-redux';
import { onChangeArticleContent } from '../../../redux/reducers/newArticleState';
import { useCallback } from 'react';
import { getEntityFromCursor, getEntityKeyFromCursor, getURLFromCursor } from './util';
import { startsWith } from 'lodash';
import Loader from '../../UI/Loader';
import useLoading from '../../../hooks/useLoading';

const EDITOR_VISIBLE_DISTANCE = 153;
const styles = {
  root: {
    fontFamily: "'Georgia', serif",
    padding: 20,
    width: 600,
  },
  buttons: {
    marginBottom: 10,
  },
  urlInputContainer: {
    marginBottom: 10,
  },
  urlInput: {
    fontFamily: "'Georgia', serif",
    marginRight: 10,
    padding: 3,
  },
  editor: {
    border: '1px solid #ccc',
    cursor: 'text',
    minHeight: 80,
    padding: 10,
  },
  button: {
    marginTop: 10,
    textAlign: 'center',
  },
  link: {
    color: '#3b5998',
    textDecoration: 'underline',
  },
};

const MAX_FILES = 4;

export function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
}

function ContentEditor() {
  const dispatch = useDispatch();
  const { newArticle, step } = useSelector((store) => store.newArticle);
  const { isMobile } = useSelector((store) => store.app);
  const editorState = newArticle.content;
  const setEditorState = useCallback((editorData) => dispatch(onChangeArticleContent(editorData)), [dispatch]);
  const [isFocusEditor, setFocusEditor] = useState(false);
  const [styledToolbarOut, setStyledToolbarOut] = useState(false);
  const [mediaToolbarOut, setMediaToolbarOut] = useState(false);
  const [editorOut, setEditorOut] = useState(false);
  const [loading, setLoading] = useState(false);
  const [linkPopupOpened, setLinkPopupOpened] = useState(false);
  const [linkButtonState, setLinkButtonState] = useState('disabled');
  const [newLinkEntityKey, setLinkEntityKey] = useState(null);
  const imageInputRef = useRef(null);
  const editorContainer = useRef(null);
  const [currentBlockKey, setBlockKey] = useState(null);
  const [imagesGallery, setImagesGallery] = useState([]);
  const [urlValue, setUrlValue] = useState('');
  //const [selectionState, setSelectionState] = useState(null);
  const editorRef = useRef(null);
  const editorDraftRef = useRef(null);
  const urlInputRef = useRef(null);
  const makeFocus = () => editorDraftRef.current.focus();
  const urlMakeFocus = () => (urlInputRef.current ? urlInputRef.current.focus() : null);
  const styledToolbarRef = useRef(null);
  const mediaToolbarRef = useRef(null);
  const [topDistance, setTopDistance] = useState(0);
  const [embedActive, setEmbedActivation] = useState(false);
  const editorStateBackupRef = useRef(null);
  const [, setLoadingAddimg] = useLoading();

  const setEditorStateBackup = (state) => (editorStateBackupRef.current = state);
  const restoreEditorStateBackup = () => {
    if (editorStateBackupRef.current) {
      setEditorState(editorStateBackupRef.current);
    }
  };

  const mediaBlockRenderer = (block) => {
    if (block.getType() === 'atomic') {
      return {
        component: Media,
        editable: false,
        props: {
          setEditorState: setEditorState,
          imageInputRef: imageInputRef,
          setBlockKey: setBlockKey,
          setImagesGallery: setImagesGallery,
          editorState: editorState,
        },
      };
    }

    return null;
  };

  /*
  const _promptForLink = () => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      linkPopupOpened(false);
    }
  };
  */

  const fixUrl = (url) => {
    if (!startsWith(url, 'https://') && !startsWith(url, 'http://')) {
      return 'http://' + url;
    }
    return url;
  };
  const _confirmLink = useCallback(
    (urlValue) => {
      let editorState = editorStateBackupRef.current || null;
      if (editorState) {
        if (urlValue === '') {
          const selection = editorState.getSelection();
          const content = editorState.getCurrentContent();
          const startKey = selection.getStartKey();
          const startOffset = selection.getStartOffset();
          const block = content.getBlockForKey(startKey);
          const linkKey = block.getEntityAt(startOffset);

          let contentWithRemovedLink = content;
          block.findEntityRanges(
            (charData) => {
              //You need to use block.findEntityRanges() API to get the whole range of link

              const entityKey = charData.getEntity();
              if (!entityKey) return false;
              return entityKey === linkKey; //Need to return TRUE only for your specific link.
            },
            (start, end) => {
              const entitySelection = new SelectionState({
                anchorKey: block.getKey(), //You already have the block key
                focusKey: block.getKey(),
                anchorOffset: start, //Just use the start/end provided by the API
                focusOffset: end,
              });
              contentWithRemovedLink = Modifier.applyEntity(content, entitySelection, null);

              return;
            }
          );

          //Remove link from entity
          /*
      const contentState = editorState.getCurrentContent();
      const contentWithoutEntities = Modifier.applyEntity(contentState, editorState.getSelection(), null);
      
      */

          const newEditorState = EditorState.set(editorState, { currentContent: contentWithRemovedLink });
          setEditorState(newEditorState);
        } else {
          const currentSelection = editorState.getSelection(); //selectionState;
          const contentState = editorState.getCurrentContent();
          const currentUrlValue = fixUrl(urlValue);
          if (currentSelection.isCollapsed()) {
            const entityKey = getEntityKeyFromCursor(editorState, 'LINK');
            if (entityKey) {
              const newContentState = contentState.replaceEntityData(entityKey, { url: currentUrlValue });
              const newEditorState = EditorState.set(editorState, { currentContent: newContentState });
              setEditorState(newEditorState);
            }
          } else {
            const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: currentUrlValue });
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
            setEditorState(RichUtils.toggleLink(newEditorState, currentSelection, entityKey));
            setLinkEntityKey(entityKey);
          }
        }
      }
      //setUrlValue(urlValue);
    },
    [setEditorState]
  );

  /*
  const _onLinkInputKeyDown = (e, editorState) => {
    if (e.which === 13) {
      _confirmLink();
    }
  };
  */

  /*
  const onURLChange = (val) => setUrlValue(val);
  */

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return true;
    }
    return false;
  };

  const handleUploadImage = async (event, editorState, onChangeEditor, blockKey, images) => {
    event.preventDefault();
    const files = Array.from(event.target.files);
    if (files && files.length && MAX_FILES - (images.length + files.length) >= 0) {
      try {
        setLoadingAddimg(true);
        const imageResponse = await Promise.all(files.map(uploadImage));
        confirmMedia(editorState, onChangeEditor, imageResponse, blockKey);
        setLoadingAddimg(false);
      } catch (error) {
        setLoadingAddimg(false);
      }
    }
  };

  const confirmMedia = (editorState, onChangeEditor, imageInfo, blockKey) => {
    const contentState = editorState.getCurrentContent();
    const imageBlock = contentState.getBlockForKey(blockKey);
    const imageEntity = imageBlock.getEntityAt(0);
    const imageEntityF = contentState.getEntity(imageEntity);
    const { images } = imageEntityF.getData();
    contentState.replaceEntityData(imageEntity, {
      images: [...images, ...imageInfo],
    });
    setBlockKey(null);
    setImagesGallery([]);
    const newEditorState = EditorState.createWithContent(contentState);
    onChangeEditor(newEditorState);
  };

  useEffect(() => {
    if (editorContainer && editorContainer.current) {
      if (topDistance > EDITOR_VISIBLE_DISTANCE) {
        setEditorOut(true);
      }

      if (topDistance < EDITOR_VISIBLE_DISTANCE) {
        setEditorOut(false);
      }
    }

    const selection = editorState.getSelection();
    const isCollapsed = selection.isCollapsed();
    if (isCollapsed) {
      setLinkButtonState(!urlValue ? 'disabled' : 'active');
    } else {
      setLinkButtonState('inactive');
    }

    //if (!isCollapsed && linkInputActive === 'disabled') {
    //setLinkButtonState('inactive');
    //}
    //if (newLinkEntityKey && linkInputActive === 'active') {
    //setLinkInputActive('inactive');
    //setUrlValue('');
    //setSelectionState(null);
    //setLinkEntityKey(null);
    //}

    Promise.resolve(
      typeof window.IntersectionObserver !== 'undefined' ? window.IntersectionObserver : import('intersection-observer')
    ).then(() => {
      const options = {
        root: document,
        rootMargin: '0px',
        threshold: 1,
        trackVisibility: true,
        delay: 100,
      };

      const observer = new window.IntersectionObserver(observerHandler, options);
      if (mediaToolbarRef && styledToolbarRef && mediaToolbarRef.current && styledToolbarRef.current) {
        observer.observe(mediaToolbarRef.current);
        observer.observe(styledToolbarRef.current);
      }

      //observer.observe(editorRef.current);
    });

    document.querySelector('.article-body-container').addEventListener('scroll', () => {
      const scrollElm = document.querySelector('.article-body-container');
      setTopDistance(scrollElm.scrollTop);
    });

    return () => {
      document.querySelector('.article-body-container').removeEventListener('scroll', () => {
        const scrollElm = document.querySelector('.article-body-container');
        setTopDistance(scrollElm.scrollTop);
      });
    };
  }, [urlValue, editorState, newLinkEntityKey, topDistance]);

  const observerHandler = (entries, observer) => {
    entries.forEach((entry) => {
      //const currentY = entry.boundingClientRect.y;
      //const currentRatio = entry.intersectionRatio;
      //const isIntersecting = entry.isIntersecting;

      if (entry.target.classList.contains('styled-toolbar-container')) {
        // if (currentY < previousYStyledToolbar) {
        //   if (currentRatio > previousRatioStyledToolbar && isIntersecting) {
        //     setStyledToolbarOut(true);
        //   } else {
        //   }
        // } else if (currentY > previousYStyledToolbar && isIntersecting) {
        //   if (currentRatio < previousRatioStyledToolbar) {
        //     setStyledToolbarOut(false);
        //   } else {
        //   }
        // }

        // previousYStyledToolbar = currentY;
        // previousRatioStyledToolbar = currentRatio;

        const isIntersected = entry.isIntersecting;
        setStyledToolbarOut(isIntersected);
      }
      if (entry.target.classList.contains('media-toolbar-container')) {
        const isIntersected = entry.isIntersecting;
        setMediaToolbarOut(isIntersected);
      }
    });
  };

  /*
  const onUrlInputBlur = () => {
    setLinkPopupOpened(false);
    //restore state
  };
  const onUrlInputClear = () => {
    setUrlValue('');
  };
  */
  const onApplyLink = (url) => {
    _confirmLink(url);
    setEditorStateBackup(null);
    setLinkPopupOpened(false);
    setTimeout(() => {
      makeFocus();
    }, 10);
  };
  const onCancelLink = () => {
    restoreEditorStateBackup();
    setEditorStateBackup(null);
    setLinkPopupOpened(false);
    setTimeout(() => {
      makeFocus();
    }, 10);
  };
  const urlInput = (
    <Popover isOpen={linkPopupOpened}>
      <div style={{ zIndex: 999 }}>
        <InsertLink
          inputRef={urlInputRef}
          //onKeyDown={_onLinkInputKeyDown}
          initialValue={urlValue}
          onClickBtn={_confirmLink}
          //editorState={editorState}
          //onChangeInput={onURLChange}
          //onClear={onUrlInputClear}
          //onBlur={(applied) => onUrlInputBlur(applied)}
          onApplyLink={onApplyLink}
          onCancel={onCancelLink}
          //setLinkInputActive={setLinkInputActive}
        />
      </div>
    </Popover>
  );

  useEffect(() => {
    if (step === 3) {
      makeFocus();
    }
  }, [step]);

  useEffect(() => {
    if (linkPopupOpened) {
      urlMakeFocus();
    } else {
      setUrlValue('');
    }
  }, [linkPopupOpened]);

  useEffect(() => {
    const url = getURLFromCursor(editorState);
    //if (url) {
    setUrlValue(url);
    //}
  }, [editorState]);

  useEffect(() => {
    const entity = getEntityFromCursor(editorState, 'LINK');
    if (entity) {
    }
  }, [editorState]);

  const openLinkInput = useCallback(() => {
    setEditorStateBackup(editorState); //Save current state before create fake selection
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'SELECTED'));
    setLinkPopupOpened(true);
  }, [editorState, setEditorState]);

  const handleUserKeyPress = useCallback(
    (event) => {
      const { keyCode, ctrlKey, metaKey } = event;

      if (keyCode === 75 && (ctrlKey === true || metaKey === true) && linkButtonState !== 'disabled') {
        event.preventDefault();
        //openLinkInput();
        //const url = getURLFromCursor(editorState);
        //if (url) {
        //setUrlValue(url);
        //setLinkPopupOpened(true);
        openLinkInput();
        setTimeout(() => {
          urlMakeFocus();
        }, 10);
        //}
      }
    },
    [linkButtonState, openLinkInput]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const onLinkButtonClick = (event) => {
    event.preventDefault();
    openLinkInput();
  };

  return (
    <EditorLayout ref={editorContainer} className="editor-parent-container" linkPopupOpened={linkPopupOpened ? 1 : 0}>
      <div
        style={{
          width: '180px',
          height: '44px',
          display: 'block',
        }}
        ref={styledToolbarRef}
        className="styled-toolbar-container"
      >
        {styledToolbarOut && (isFocusEditor || embedActive || linkPopupOpened) ? (
          <TextFormat
            editorState={editorState}
            setEditorState={setEditorState}
            //promptLink={_promptForLink}
            imageInputRef={imageInputRef}
            linkButtonState={linkButtonState}
            setLinkButtonState={setLinkButtonState}
            //setSelectionState={setSelectionState}
            onLinkButtonClick={onLinkButtonClick}
          />
        ) : null}
      </div>
      <TextToolbarFixed
        className="fixed-styled-toolbar-container"
        editorOut={editorOut}
        styledToolbarOut={styledToolbarOut}
        isFocusEditor={isFocusEditor}
        embedActive={embedActive}
        isMobile={isMobile}
      >
        <TextFormat
          editorState={editorState}
          setEditorState={setEditorState}
          //promptLink={_promptForLink}
          imageInputRef={imageInputRef}
          linkButtonState={linkButtonState}
          setLinkButtonState={setLinkButtonState}
          //setSelectionState={setSelectionState}
          onLinkButtonClick={onLinkButtonClick}
        />
      </TextToolbarFixed>
      <div>
        {urlInput}
        <div
          ref={editorRef}
          onClick={makeFocus}
          className="editor-container"
          onFocus={() => {
            setFocusEditor(true);
          }}
          onBlur={() => {
            setFocusEditor(false);
          }}
        >
          <Editor
            editorState={editorState}
            onChange={setEditorState}
            placeholder="Enter some text..."
            blockRendererFn={mediaBlockRenderer}
            handleKeyCommand={handleKeyCommand}
            readOnly={false}
            onFocus={() => setFocusEditor(true)}
            onBlur={() => setFocusEditor(false)}
            ref={editorDraftRef}
            blockStyleFn={() => 'blockStyle'}
            stripPastedStyles={true}
            customStyleMap={{
              SELECTED: {
                background: '#e2f2ff',
              },
            }}
          />
        </div>
      </div>
      {loading && (
        <FlexContainer justify="center">
          <Loader color="#6670F0" />
        </FlexContainer>
      )}
      <FlexContainer justify="center">
        <MediaToolbarFixed
          className="fixed-media-toolbar-container"
          editorOut={editorOut}
          mediaToolbarOut={mediaToolbarOut}
          isFocusEditor={isFocusEditor}
          embedActive={embedActive}
          isMobile={isMobile}
        >
          <MediaToolbar
            editorState={editorState}
            onChangeEditor={setEditorState}
            imageInputRef={imageInputRef}
            embedActive={embedActive}
            setEmbedActivation={setEmbedActivation}
            setLoading={setLoading}
          />
        </MediaToolbarFixed>
        <div
          ref={mediaToolbarRef}
          className="media-toolbar-container"
          style={{ opacity: isFocusEditor || embedActive ? 1 : 0 }}
        >
          <div style={{ opacity: mediaToolbarOut ? '1' : '0' }}>
            <MediaToolbar
              editorState={editorState}
              onChangeEditor={setEditorState}
              imageInputRef={imageInputRef}
              embedActive={embedActive}
              setEmbedActivation={setEmbedActivation}
              setLoading={setLoading}
            />
          </div>
        </div>
      </FlexContainer>
      <input
        className="d-none"
        type="file"
        accept="image/*"
        ref={imageInputRef}
        onChange={(e) => {
          e.preventDefault();
          handleUploadImage(e, editorState, setEditorState, currentBlockKey, imagesGallery);
        }}
        multiple
      />
    </EditorLayout>
  );
}

export const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} style={styles.link} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};

const Media = (props) => {
  const { setEditorState, imageInputRef, setBlockKey, setImagesGallery, editorState, loading } = props.blockProps;
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const blockKey = props.block.key;
  const type = entity.getType();

  if (type === 'ARTICLE') {
    const { articleId } = entity.getData();
    return <ArticleEmbed isPreview={true} articleId={articleId} />;
  }

  if (type === 'IMAGE') {
    const { images } = entity.getData();

    return (
      <ImageEditorComponent
        blockKey={blockKey}
        images={images}
        {...props}
        onChangeEditor={setEditorState}
        contentState={props.contentState}
        imageInputRef={imageInputRef}
        setBlockKey={setBlockKey}
        setImagesGallery={setImagesGallery}
        readOnly={false}
        editorState={editorState}
        loading={loading}
      />
    );
  }

  if (type === 'VIDEO') {
    const { videoId } = entity.getData();
    return (
      <EmbedPreview
        blockKey={blockKey}
        embedSource={videoId}
        contentState={props.contentState}
        onChangeEditor={setEditorState}
        editorState={editorState}
      />
    );
  }

  return null;
};

export default ContentEditor;
