import { getMeContributionsService, getUserContributionsService, getActivities } from '../../http/profileService';
const initialState = {
  meActivities: [],
  meContributions: [],
  meError: false,
  meLoading: false,
  meErrorMessage: '',
  meLastItem: undefined,
  meTotalItems: undefined,

  meLastActivitiesItem: undefined,
  meTotalActivitiesItems: undefined,

  contributions: [],
  error: false,
  loading: false,
  errorMessage: '',
  lastItem: undefined,
  totalItems: undefined,
};

//actions

const ADD_ME_ACTIVITIES = 'ADD_ME_ACTIVITIES';
const LOADING_ME_ACTIVITIES = 'LOADING_ME_ACTIVITIES';
const ERROR_ME_ACTIVITIES = 'ERROR_ME_ACTIVITIES';

const ADD_ME_CONTRIBUTIONS = 'ADD_ME_CONTRIBUTIONS';
const LOADING_ME_CONTRIBUTIONS = 'LOADING_ME_CONTRIBUTIONS';
const ERROR_ME_CONTRIBUTION = 'ERROR_ME_CONTRIBUTION';

const ADD_CONTRIBUTIONS = 'ADD_CONTRIBUTIONS';
const LOADING_CONTRIBUTIONS = 'LOADING_CONTRIBUTIONS';
const ERROR_CONTRIBUTION = 'ERROR_CONTRIBUTION';

export const getMeActivities =
  (forceFirst = false, limit = 20) =>
  async (dispatch, getState) => {
    const meLastActivitiesItem = forceFirst ? undefined : getState().profile.meLastActivitiesItem;
    dispatch(meLoadingMeActivities(true));
    try {
      const response = await getActivities(limit, meLastActivitiesItem);
      dispatch(
        addMeActivities({
          meActivities: response.data,
          meTotalActivitiesItems: response.headers['x-pagination-total-items'],
          meLastActivitiesItem: response.headers['x-pagination-last-item'],
          forceFirst,
        })
      );
    } catch (e) {
      /*
      if (e.response.status === 401) {
        dispatch(setAuthorization(false));
      }
      dispatch({ meError: true, meErrorMessage: e.response.data?.meError || e.response.statusText });
      */
    } finally {
      dispatch(meLoadingMeActivities(false));
    }
  };

// action creators
export const getMeContributions =
  (forceFirst = false) =>
  async (dispatch, getState) => {
    const meLastItem = forceFirst ? undefined : getState().profile.meLastItem;
    dispatch(meLoadingMeContributions(true));
    try {
      const response = await getMeContributionsService(meLastItem);
      dispatch(
        addMeContributions({
          meContributions: response.data,
          meTotalItems: response.headers['x-pagination-total-items'],
          meLastItem: response.headers['x-pagination-last-item'],
          forceFirst,
        })
      );
    } catch (e) {
      /*
      if (e.response.status === 401) {
        dispatch(setAuthorization(false));
      }
      dispatch({ meError: true, meErrorMessage: e.response.data?.meError || e.response.statusText });
      */
    } finally {
      dispatch(meLoadingMeContributions(false));
    }
  };

export const addMeContributions = ({ meContributions, meTotalItems, meLastItem, forceFirst }) => ({
  type: ADD_ME_CONTRIBUTIONS,
  payload: { meContributions, meTotalItems, meLastItem, forceFirst },
});

export const addMeActivities = ({ meActivities, meTotalActivitiesItems, meLastActivitiesItem, forceFirst }) => ({
  type: ADD_ME_ACTIVITIES,
  payload: { meActivities, meTotalActivitiesItems, meLastActivitiesItem, forceFirst },
});

export const meLoadingMeContributions = (isLoading) => ({ type: LOADING_ME_CONTRIBUTIONS, payload: isLoading });

export const meLoadingMeActivities = (isLoading) => ({ type: LOADING_ME_ACTIVITIES, payload: isLoading });

export const getContributions =
  (userId, forceFirst = false) =>
  async (dispatch, getState) => {
    const lastItem = forceFirst ? undefined : getState().profile.lastItem;
    dispatch(loadingContributions(true));
    try {
      const response = await getUserContributionsService(userId, lastItem);
      dispatch(
        addContributions({
          contributions: response.data,
          totalItems: response.headers['x-pagination-total-items'],
          lastItem: response.headers['x-pagination-last-item'],
          forceFirst,
        })
      );
    } catch (e) {
      /*
      if (e.response.status === 401) {
        dispatch(setAuthorization(false));
      }
      dispatch({ meError: true, meErrorMessage: e.response.data?.meError || e.response.statusText });
      */
    } finally {
      dispatch(loadingContributions(false));
    }
  };

export const addContributions = ({ contributions, totalItems, lastItem, forceFirst }) => ({
  type: ADD_CONTRIBUTIONS,
  payload: { contributions, totalItems, lastItem, forceFirst },
});

export const loadingContributions = (isLoading) => ({ type: LOADING_CONTRIBUTIONS, payload: isLoading });

//export const setErrorArticles = (meError) => ({ type: ERROR_ARTICLES, payload: meError });

//export const setFilterValue = (value) => ({ type: SET_FILTER_VALUE, payload: value });

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_ME_ACTIVITIES:
      return {
        ...state,
        meActivities: payload.forceFirst ? [...payload.meActivities] : [...state.meActivities, ...payload.meActivities],
        meTotalActivitiesItems: payload.meTotalActivitiesItems,
        meLastActivitiesItem: payload.meLastActivitiesItem !== 'null' ? payload.meLastActivitiesItem : null,
      };
    case LOADING_ME_ACTIVITIES:
      return {
        ...state,
        meLoading: payload,
      };
    case ERROR_ME_ACTIVITIES:
      return {
        ...state,
        meError: payload.meError,
        meErrorMessage: payload.meErrorMessage,
      };

    case ADD_ME_CONTRIBUTIONS:
      return {
        ...state,
        meContributions: payload.forceFirst
          ? [...payload.meContributions]
          : [...state.meContributions, ...payload.meContributions],
        meTotalItems: payload.meTotalItems,
        meLastItem: payload.meLastItem !== 'null' ? payload.meLastItem : null,
      };
    case LOADING_ME_CONTRIBUTIONS:
      return {
        ...state,
        meLoading: payload,
      };
    case ERROR_ME_CONTRIBUTION:
      return {
        ...state,
        meError: payload.meError,
        meErrorMessage: payload.meErrorMessage,
      };

    case ADD_CONTRIBUTIONS:
      return {
        ...state,
        contributions: payload.forceFirst
          ? [...payload.contributions]
          : [...state.contributions, ...payload.contributions],
        totalItems: payload.totalItems,
        lastItem: payload.lastItem !== 'null' ? payload.lastItem : null,
      };
    case LOADING_CONTRIBUTIONS:
      return {
        ...state,
        loading: payload,
      };
    case ERROR_CONTRIBUTION:
      return {
        ...state,
        error: payload.error,
        errorMessage: payload.errorMessage,
      };
    default:
      return state;
  }
};
