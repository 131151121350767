import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ContributionsPanel,
  MaxWidthContainer,
  ProfileContainer,
  ProfilePanel,
  ProfileUserInfo,
  UserActionsPanel,
  UserActionButton,
  UserName,
  Location,
  Genres,
  OtherUserInfo,
  StatusContainer,
  Level,
  KarmaContainer,
  KarmaValue,
  Description,
  BuskProfileButton,
  JoinedCommunityContainer,
  JoinedCommunityValue,
  BadgeContainer,
  BadgeTitle,
  BadgeImages,
} from './styled-components';
import { RiFlag2Line } from 'react-icons/ri';
import { IoMdHand } from 'react-icons/io';
import FlexContainer from '../../components/UI/FlexContainer';
import Contribution from './Contribution';
import Avatar from '../../components/UI/avatar/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { getContributions } from '../../redux/reducers/profileState';
import { getUser } from '../../http/profileService';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReportUser from '../../components/Profile/ReportUser/ReportUser';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import CustomTooltip from '../../components/UI/Tooltip';

import {
  AgeIcon,
  ModRankIcon,
  PopularIcon,
  ChatterIcon,
  HallOfFarmerIcon,
  StaffIcon,
  ModeratorIcon,
  PhotogenicIcon,
  NewYouIcon,
  BuskcoIcon,
  LoitererIcon,
  ConversationStarterIcon,
  SocialiteIcon,
  HelperIcon,
  SheepDogIcon,
  TownCrierIcon,
  BlahblahblahIcon,
  BoffinIcon,
  LawyerIcon,
  AdvocateIcon,
  RebelIcon,
  RuleMakerIcon,
  ConsumerIcon,
  LibrarianIcon,
  PlacemakerIcon,
  TeacherIcon,
  PollsterIcon,
  HistorianIcon,
  EagleEyeIcon,
  SuperFanIcon,
  CuratorIcon,
  ProducerIcon,
  PaparazzoIcon,
  AdministratorIcon,
  HawkerIcon,
  NewbieIcon,
  PasserbyIcon,
  AppreciatorIcon,
  EntertainerIcon,
  BardIcon,
  MistrelIcon,
  TroubadourIcon,
  BuskerIcon,
  LegendIcon,
} from '../../assets/icons/svg-icons';

export default function Profile() {
  const { id } = useParams();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const totalItems = useSelector((state) => state.profile.totalItems);
  const contributions = useSelector((state) => state.profile.contributions);
  const lastItem = useSelector((state) => state.profile.lastItem);
  const dispatch = useDispatch();

  const [showReportUser, setShowReportUser] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const loadMoreContributions = useCallback(
    (currentUserId, forceFirst = false) => {
      dispatch(getContributions(currentUserId, forceFirst));
    },
    [dispatch]
  );

  const getUserService = useCallback(async () => {
    try {
      const result = await getUser(id);
      setCurrentUser(result.data);
    } catch (err) {
      setCurrentUser(null);
    }
  }, [id]);

  useEffect(() => {
    if (id === user?._id) {
      history.replace('/profile');
    }
  }, [user, id, history]);

  useEffect(() => {
    if (id) {
      loadMoreContributions(id, true);
    }
  }, [id, loadMoreContributions]);

  useEffect(() => {
    if (id) {
      getUserService();
    }
  }, [id, getUserService]);

  const getFormatText = (type, value) => {
    switch (type) {
      case 'popular':
        switch (value) {
          case 1:
            return '25';
          case 2:
            return '100';
          case 3:
            return '500';
          case 4:
            return '1000';
          case 5:
            return '5000';
          case 6:
            return '10000';
          default:
            return '';
        }
      case 'chatterbox':
        switch (value) {
          case 1:
            return '1';
          case 2:
            return '5';
          case 3:
            return '25';
          case 4:
            return '100';
          case 5:
            return '250';
          default:
            return '';
        }
      case 'aged':
        switch (value) {
          case 1:
            return '1 year';
          case 2:
            return '2 years';
          case 3:
            return '3 years';
          case 4:
            return '5 years';
          case 5:
            return '10 years';
          default:
            return '';
        }
      case 'modRank':
        switch (value) {
          case 1:
            return '1 post';
          case 2:
            return '5 posts';
          case 3:
            return '10 posts';
          case 4:
            return '50 posts';
          case 5:
            return '100 posts';
          case 6:
            return '500 posts';
          default:
            return '';
        }
      default:
        return '';
    }
  };

  const Badges = ({ badges }) => {
    return (
      <>
        {currentUser?.rank === 'newbie' && (
          <CustomTooltip text={'Starting rank!'}>
            <NewbieIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {currentUser?.rank === 'passerby' && (
          <CustomTooltip text={'50+ points'}>
            <PasserbyIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {currentUser?.rank === 'appreciator' && (
          <CustomTooltip text={'100+ points'}>
            <AppreciatorIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {currentUser?.rank === 'entertainer' && (
          <CustomTooltip text={'250+ points'}>
            <EntertainerIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {currentUser?.rank === 'bard' && (
          <CustomTooltip text={'500+ points, busk.co badge, 10+ badges'}>
            <BardIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {currentUser?.rank === 'minstrel' && (
          <CustomTooltip text={'1,000+ points, busk.co badge, 20+ badges'}>
            <MistrelIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {currentUser?.rank === 'troubadour' && (
          <CustomTooltip text={'2,000+ points, busk.co badge, 35+ badges'}>
            <TroubadourIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {currentUser?.rank === 'busker' && (
          <CustomTooltip text={'5,000+ points, busk.co badge, 50+ badges'}>
            <BuskerIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {currentUser?.rank === 'legend' && (
          <CustomTooltip text={'10,000+ points, busk.co badge, 50+ badges'}>
            <LegendIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}

        {badges?.popular !== 0 && (
          <CustomTooltip text={`${getFormatText('popular', badges?.popular)}+ upvotes received`}>
            <PopularIcon rank={badges?.popular} style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.chatterbox !== 0 && (
          <CustomTooltip text={`${getFormatText('chatterbox', badges?.chatterbox)} comments made`}>
            <ChatterIcon rank={badges?.chatterbox} style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.aged !== 0 && (
          <CustomTooltip text={`${getFormatText('aged', badges?.aged)} on the site`}>
            <AgeIcon rank={badges?.aged} style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.modRank !== 0 && (
          <CustomTooltip text={`Moderated ${getFormatText('modRank', badges?.modRank)}`}>
            <ModRankIcon rank={badges?.modRank} style={{ margin: 5 }} />
          </CustomTooltip>
        )}

        {badges?.staff && (
          <CustomTooltip text={'Staff: I work here'}>
            <StaffIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.moderator && (
          <CustomTooltip text={'Moderator: I am a mod'}>
            <ModeratorIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.photogenic && (
          <CustomTooltip text={'Photogenic: Updated profile photo'}>
            <PhotogenicIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.newYou && (
          <CustomTooltip text={'New You: Updated bio'}>
            <NewYouIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.buskco && (
          <CustomTooltip text={'I have linked my profile on busk.co'}>
            <BuskcoIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.loiterer && (
          <CustomTooltip text={'Loiterer: Logged in 10 times'}>
            <LoitererIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.conversationStarter && (
          <CustomTooltip text={'Conversation starter: 10+ posts with 10+ comments each'}>
            <ConversationStarterIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.socialite && (
          <CustomTooltip text={'Socialite: 50 users have commented on my posts'}>
            <SocialiteIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.helper && (
          <CustomTooltip text={'Helper: mods deleted a post I reported'}>
            <HelperIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.sheepDog && (
          <CustomTooltip text={'Sheep dog: mods blocked a user I reported'}>
            <SheepDogIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.townCrier && (
          <CustomTooltip text={'Town Crier: posted in “Busking in the news”'}>
            <TownCrierIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.blahblahblah && (
          <CustomTooltip text={'Blah Blah Blah: posted in “Chit Chat”'}>
            <BlahblahblahIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.boffin && (
          <CustomTooltip text={'Boffin: posted in “Academic Paper”'}>
            <BoffinIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.lawyer && (
          <CustomTooltip text={'Lawyer: posted in “Law” or “Lawyer”'}>
            <LawyerIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.advocate && (
          <CustomTooltip text={'Advocate: posted in “Court Case”'}>
            <AdvocateIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.rebel && (
          <CustomTooltip text={'Rebel: posted in “Arrest”, “Harassment” or “Fine”'}>
            <RebelIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.ruleMaker && (
          <CustomTooltip text={'Rule maker: posted in “Code of Conduct” or “Permit”'}>
            <RuleMakerIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.consumer && (
          <CustomTooltip text={'Consumer: posted in “Equipment”'}>
            <ConsumerIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.librarian && (
          <CustomTooltip text={'Librarian: posted in “Books”'}>
            <LibrarianIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.placemaker && (
          <CustomTooltip text={'Placemaker: posted in “Placemaking”'}>
            <PlacemakerIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.teacher && (
          <CustomTooltip text={'Teacher: posted in “Guide”'}>
            <TeacherIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.pollster && (
          <CustomTooltip text={'Pollster: posted in “Poll/Survey”'}>
            <PollsterIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.historian && (
          <CustomTooltip text={'Historian: posted in “Historical Event”'}>
            <HistorianIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.hallOfFamer && (
          <CustomTooltip text={'I work at the Buskers Hall of Fame'}>
            <HallOfFarmerIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.eagleEye && (
          <CustomTooltip text={'Eagle Eye: posted in “TV” or “Movie”'}>
            <EagleEyeIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.superFan && (
          <CustomTooltip text={'Superfan: posted in “Celebrity”'}>
            <SuperFanIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.curator && (
          <CustomTooltip text={'Curator: posted in “Fine Art”'}>
            <CuratorIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.producer && (
          <CustomTooltip text={'Producer: posted in “Festival”'}>
            <ProducerIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.paparazzo && (
          <CustomTooltip text={'Paparazzo: posted in “Photographer” or “Filmmaker”'}>
            <PaparazzoIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.administrator && (
          <CustomTooltip text={'Administrator: posted in “Organisation”'}>
            <AdministratorIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.hawker && (
          <CustomTooltip text={'Hawker: posted in “Service”'}>
            <HawkerIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
      </>
    );
  };

  return (
    <MaxWidthContainer>
      <ReportUser userId={id} showReportUser={showReportUser} setShowReportUser={setShowReportUser} />
      <ProfileContainer>
        <ProfilePanel>
          <ProfileUserInfo>
            <UserActionsPanel>
              <UserActionButton onClick={() => setShowReportUser(true)}>
                <RiFlag2Line style={{ fontSize: 16, marginRight: '7px' }} /> Report User
              </UserActionButton>
            </UserActionsPanel>
            <Avatar src={currentUser?.photo?.content?.medium} size={96} />
            <UserName>{currentUser?.name || ''}</UserName>
            <Location>{currentUser?.location?.formatted_address || ''}</Location>
            <Genres>{currentUser?.genre || ''}</Genres>
          </ProfileUserInfo>
          <OtherUserInfo>
            <StatusContainer>
              <Level>
                <IoMdHand style={{ fontSize: 16, marginRight: '12px', verticalAlign: 'middle' }} />
                {currentUser?.rank?.capitalize() || '-'}
              </Level>
              <KarmaContainer>
                <KarmaValue>{currentUser?.points || 0}</KarmaValue> Karma
              </KarmaContainer>
            </StatusContainer>
            <Description>{currentUser?.bio || ''}</Description>
            <BuskProfileButton
              disabled={!currentUser?.buskProfile}
              onClick={() => {
                if (currentUser?.buskProfile) {
                  window.open(currentUser?.buskProfile, '_blank');
                }
              }}
            >
              Busk profile
            </BuskProfileButton>
            <JoinedCommunityContainer>
              Joined the community{' '}
              <JoinedCommunityValue>
                {currentUser?.created_at ? moment(currentUser?.created_at).fromNow() : ''}
              </JoinedCommunityValue>
            </JoinedCommunityContainer>
          </OtherUserInfo>
          <BadgeContainer>
            <BadgeTitle>Badges</BadgeTitle>
            <BadgeImages>
              <Badges badges={currentUser?.badges} />
            </BadgeImages>
          </BadgeContainer>
        </ProfilePanel>
        <FlexContainer
          style={{ padding: '48px 0px 48px 30px' }}
          justify="flex-start"
          align="flex-start"
          elmWidth="100%"
          column
        >
          <FlexContainer justify="flex-start" align="center" elmWidth="100%">
            <FlexContainer
              style={{
                cursor: 'pointer',
                padding: '0 20px',
              }}
            >
              {totalItems === undefined ? 'Loading...' : `${totalItems} CONTRIBUTIONS`}
            </FlexContainer>
          </FlexContainer>
          <div style={{ marginTop: 0, height: 1, width: '100%', borderBottom: '1px solid #BDBFDF' }} />
          <FlexContainer id="scrollableDiv" style={{ height: '100%', overflow: 'scroll', width: '100%' }}>
            <ContributionsPanel>
              <InfiniteScroll
                scrollableTarget="scrollableDiv"
                dataLength={contributions.length}
                next={() => loadMoreContributions(currentUser?._id, false)}
                hasMore={lastItem !== null}
              >
                {contributions.map((contribution) => (
                  <Contribution key={contribution._id} {...contribution} />
                ))}
              </InfiniteScroll>
            </ContributionsPanel>
          </FlexContainer>
        </FlexContainer>
      </ProfileContainer>
    </MaxWidthContainer>
  );
}
