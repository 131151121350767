import React, { useState, useEffect, useCallback } from 'react';
import { CloseIcon } from '../../../assets/icons/svg-icons';
import { reportUser } from '../../../http/profileService';
import FlexContainer from '../../UI/FlexContainer';
import Button from '../../UI/Button';
import RadioButton from '../../UI/RadioButton';
import TextArea from '../../UI/TextArea';
import Loader from '../../UI/Loader';
import Modal from '../../UI/modal/Modal';
import Notice from '../../UI/notice/Notice';
import styled from 'styled-components';

export default function ReportUser({ userId, showReportUser, setShowReportUser }) {
  const [draftSettings, setDraftSettings] = useState({ value: '', text: '' });
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        setShowReportUser(false);
      }
    },
    [setShowReportUser]
  );

  useEffect(() => {
    if (showReportUser) {
      setDraftSettings({ value: '', text: '' });
    }
  }, [showReportUser, setDraftSettings]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const onChange = (field, value) => {
    const newSettings = { ...draftSettings };
    if (typeof value === 'string') {
      newSettings[field] = value;
    } else {
      newSettings[field] = value.target.value;
    }
    setDraftSettings(newSettings);
  };

  const onChangeRadio = (value) => {
    const newSettings = { ...draftSettings };
    newSettings.value = value;
    setDraftSettings(newSettings);
  };

  const save = async () => {
    try {
      setLoading(true);
      const data = {
        userId: userId,
        flag: draftSettings.value,
        explanation: draftSettings.text,
      };
      await reportUser(data);
      setLoading(false);
      setSuccessMessage('The report has been sent');
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || 'Unable to send the report');
      setLoading(false);
    }
  };

  return (
    <>
      {successMessage && (
        <Notice
          duration={2000}
          type="success"
          text={successMessage}
          callBack={() => {
            setSuccessMessage(null);
          }}
        />
      )}
      <Modal
        setVisibility={() => setShowReportUser(showReportUser)}
        visible={showReportUser}
        elmHeight="auto"
        elmWidth="496px"
        footer={null}
      >
        <ReportUserModal>
          <FlexContainer
            style={{ padding: 16 }}
            justify="space-between"
            align="center"
            className="reactions-toolbar"
            elmWidth="100%"
          >
            <ReportUserModalTitle>REPORT THIS USER</ReportUserModalTitle>
            <CloseIcon
              width="10"
              height="10"
              onClick={() => {
                setShowReportUser(false);
              }}
            />
          </FlexContainer>
          <div
            style={{
              width: '100%',
              height: 1,
              backgroundColor: '#151531',
            }}
          />
          <FlexContainer
            style={{ padding: 16 }}
            justify="flex-start"
            align="center"
            className="reactions-toolbar"
            elmWidth="100%"
          >
            <RadioButton
              checked={draftSettings.value === 'POSTING_SENSITIVE_CONTENT'}
              value="POSTING_SENSITIVE_CONTENT"
              onChange={onChangeRadio}
            />
            <Label>Posting sensitive content</Label>
          </FlexContainer>
          <div
            style={{
              width: '95%',
              margin: 'auto',
              height: 1,
              backgroundColor: '#151531',
            }}
          />
          <FlexContainer
            style={{ padding: 16 }}
            justify="flex-start"
            align="center"
            className="reactions-toolbar"
            elmWidth="100%"
          >
            <RadioButton
              checked={draftSettings.value === 'NOT_NICE_OR_HARMFUL'}
              value="NOT_NICE_OR_HARMFUL"
              onChange={onChangeRadio}
            />
            <Label>Not nice or harmful</Label>
          </FlexContainer>
          <div
            style={{
              width: '95%',
              margin: 'auto',
              height: 1,
              backgroundColor: '#151531',
            }}
          />
          <FlexContainer
            style={{ padding: 16 }}
            justify="flex-start"
            align="center"
            className="reactions-toolbar"
            elmWidth="100%"
          >
            <RadioButton checked={draftSettings.value === 'SPAM_BOT'} value="SPAM_BOT" onChange={onChangeRadio} />
            <Label>Spam / bot</Label>
          </FlexContainer>
          <div
            style={{
              width: '95%',
              margin: 'auto',
              height: 1,
              backgroundColor: '#151531',
            }}
          />
          <FlexContainer
            style={{ padding: 16 }}
            justify="flex-start"
            align="center"
            className="reactions-toolbar"
            elmWidth="100%"
          >
            <RadioButton
              checked={draftSettings.value === 'SUPLANTING_SOMEONE_ELSE'}
              value="SUPLANTING_SOMEONE_ELSE"
              onChange={onChangeRadio}
            />
            <Label>Suplanting someone else</Label>
          </FlexContainer>
          <div
            style={{
              width: '95%',
              margin: 'auto',
              height: 1,
              backgroundColor: '#151531',
            }}
          />
          <FlexContainer
            style={{ padding: 16 }}
            justify="flex-start"
            align="center"
            className="reactions-toolbar"
            elmWidth="100%"
          >
            <RadioButton checked={draftSettings.value === 'OTHER'} value="OTHER" onChange={onChangeRadio} />
            <Label>Other</Label>
          </FlexContainer>
          <div
            style={{
              width: '95%',
              margin: 'auto',
              height: 1,
              backgroundColor: '#151531',
            }}
          />
          <FlexContainer
            style={{ padding: 16 }}
            justify="flex-start"
            align="center"
            className="reactions-toolbar"
            elmWidth="100%"
          >
            <TextArea
              dark
              style={{ width: '100%', margin: '7px 0' }}
              placeholder="Place explain the context of your report..."
              value={draftSettings?.text || ''}
              onChange={(value) => {
                onChange('text', value);
              }}
              rows={4}
            />
          </FlexContainer>

          <FlexContainer
            style={{ padding: 20 }}
            justify="center"
            align="center"
            className="reactions-toolbar"
            elmWidth="100%"
          >
            <Button
              modalSecondary
              rounded
              onClick={() => {
                setShowReportUser(false);
              }}
            >
              CANCEL
            </Button>
            <Button disabled={!(draftSettings?.value !== '')} primary rounded style={{ marginLeft: 10 }} onClick={save}>
              REPORT
            </Button>
          </FlexContainer>
          {loading && (
            <FlexContainer justify="center" align="center">
              <Loader />
            </FlexContainer>
          )}
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </ReportUserModal>
      </Modal>
    </>
  );
}

const ReportUserModal = styled.div`
  background: #1f1f3d;
  border-radius: 16px;
  max-width: 496px;
`;

const ReportUserModalTitle = styled.div`
  /* Caption - Lato 12 Bold */

  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  /* mid gray */

  color: #bdbfdf;
`;

const Label = styled.div`
  /* Body - OpenSans 14 reg */

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: center;

  /* clear BG */

  color: #f6f8ff;
`;
