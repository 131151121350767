import React, { useEffect, useState, useRef, useCallback } from 'react';
import { ThemeProvider } from 'styled-components';
import { StyledView, MaxWidthContainer, ArticleDetailContainer, FeatureImage } from './styled-components';
import FlexContainer from '../../components/UI/FlexContainer';
import DownloadPdf from '../../components/UI/download-pdf/DownloadPdf';
import AvatarPlaceholder from '../../assets/images/avatar-placeholder.png';
import { Ellipse, CalendarIcon, OpenlinkIcon, LockIcon } from '../../assets/icons/svg-icons';
import ToolbarReactions from '../../components/UI/reaction-toolbar/ToolbarReactions';
import Tag from '../../components/UI/Tag';
import AuthorMeta from '../../components/UI/author-post/AuthorMeta';
import { Prompt, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearArticleDetails, getArticleDetailBySlug } from '../../redux/reducers/articleDetail';
import Loader from '../../components/UI/Loader';
import { getDateFormatted } from '../../utils/utils';
import ArticleContentBody from './ArticleContentBody';
import Button from '../../components/UI/Button';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { SITE_TITLE } from '../../utils/constants';
import CommentBox from '../../components/ArticleDetails/Comments/CommentBox';
import { onGetComments, onPostComment } from '../../redux/actions/commentActions';
import useInterval from '../../hooks/useInterval';
import Comment from '../../components/ArticleDetails/Comment/Comment';
import ImageEditorComponent from '../../components/ArticleDetails/ImageEditor/ImageEditorComponent';

import Modal from '../../components/UI/modal/Modal';
import { useModal } from '../../components/UI/modal/useModal';

export default function ArticleDetail() {
  const history = useHistory();
  const modal = useModal();
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);

  const refCommentsArea = useRef(null);
  const { categoryId, slug } = useParams();
  const dispatch = useDispatch();
  const { article, error, errorMessage, loading } = useSelector((store) => store.articleDetail);
  const { comments, loadingComments } = useSelector((store) => store.comments);
  const { isMobile } = useSelector((store) => store.app);
  const [canLeave, setCanLeave] = useState(true);
  const [showPrompt, setShowPrompt] = useState(false);
  const [goComments, setGoComments] = useState(false);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const goComments = query.get('goComments');
    if (goComments === 'true') {
      setGoComments(true);
    }
  }, []);

  useEffect(() => {
    if (goComments && !loadingComments) {
      if (refCommentsArea?.current) {
        const y = refCommentsArea?.current.getBoundingClientRect().top + window.pageYOffset - 130;
        window.scrollTo({ top: y, behavior: 'smooth' });
        setGoComments(false);
      }
    }
  }, [loadingComments, goComments]);

  const alertUser = useCallback(
    (e) => {
      if (!canLeave) {
        e.preventDefault();
        setShowPrompt(true);
        e.returnValue = true;
        return '';
      }
    },
    [canLeave]
  );

  useEffect(() => {
    setShowPrompt(!canLeave);
    window.addEventListener('beforeunload', alertUser);
    // window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
      window.removeEventListener('popstate', alertUser);
    };
  }, [canLeave, alertUser]);

  useEffect(() => {
    return () => {
      dispatch(clearArticleDetails());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getArticleDetailBySlug(slug));
  }, [categoryId, slug, dispatch]);

  useEffect(() => {
    if (article) {
      dispatch(onGetComments(article?._id));
    }
  }, [article, dispatch]);

  useInterval(() => {
    if (article) {
      dispatch(onGetComments(article?._id));
    }
  }, 30000);

  useEffect(() => {
    if (article && article.categoryId.toLowerCase() !== categoryId) {
      // history.replace(`/${article.categoryId.toLowerCase()}/${slug}`);
    }
  }, [article, categoryId, history, slug]);

  const handleTagClick = (e, tag) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(`/?keywords=${tag}&sort=created_at`);
    } else {
      history.push(`/?keywords=${tag}&sort=created_at`);
    }
  };

  const handlePostComment = async (comment) => {
    await dispatch(onPostComment({ articleId: article?._id, ...comment }));
    dispatch(getArticleDetailBySlug(slug, true));
  };

  const onExitClick = () => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && showPrompt) {
      setLastLocation(nextLocation);
      modal.handleClick();
      return false;
    }

    return true;
  };

  return (
    <ArticleDetailContainer>
      <Prompt when={showPrompt} message={handleBlockedNavigation} />
      <ThemeProvider theme={{ isDark: true }}>
        <Modal
          title="Exit Page"
          setVisibility={modal.handleClick}
          visible={modal.visible}
          elmHeight="auto"
          elmWidth="496px"
          textOk="Leave"
          okClick={onExitClick}
          header={undefined}
        >
          <p style={{ textAlign: 'center', marginTop: '16px' }}>
            You have unfinished changes,
            <br /> If you leave now you may lose them.
          </p>
        </Modal>
      </ThemeProvider>
      <Helmet>
        <title>{'Article Detail'}</title>
      </Helmet>
      {error && (
        <StyledView>
          <MaxWidthContainer>
            <p>{errorMessage}</p>
          </MaxWidthContainer>
        </StyledView>
      )}
      {!error && !loading && (
        <>
          <ThemeProvider theme={{ isDark: true }}>
            <Helmet>
              <title>
                {article?.title || 'Article Detail'} - {SITE_TITLE}
              </title>
            </Helmet>
            <StyledView className="header-content">
              <MaxWidthContainer>
                <div className="breadcrumb">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (e.ctrlKey || e.metaKey) {
                        window.open(`/?category=${article?.categoryId}&sort=created_at`);
                      } else {
                        history.push(`/?category=${article?.categoryId}&sort=created_at`);
                      }
                    }}
                  >
                    {article?.categoryId}
                  </div>
                  <Ellipse />
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (e.ctrlKey || e.metaKey) {
                        window.open(`/?search=${article?.contentTypeId}&sort=created_at`);
                      } else {
                        history.push(`/?search=${article?.contentTypeId}&sort=created_at`);
                      }
                    }}
                  >
                    {article?.contentTypeId}
                  </div>
                  <Ellipse />
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (!article?.location?.place_id) {
                        return;
                      }
                      if (e.ctrlKey || e.metaKey) {
                        window.open(
                          `/?locationID=${article?.location?.place_id}&locationName=${article?.location?.locality}&sort=created_at`
                        );
                      } else {
                        history.push(
                          `/?locationID=${article?.location?.place_id}&locationName=${article?.location?.locality}&sort=created_at`
                        );
                      }
                    }}
                  >
                    {article?.location?.locality || 'Worldwide'}
                  </div>
                </div>
                <FlexContainer className="metadata-container" align="stretch">
                  <FlexContainer elmWidth="80%" column align="stretch">
                    <h2>{article?.title}</h2>

                    {article?.date && (
                      <div className="metadata-date">
                        <CalendarIcon className="head-article-content-icon" />
                        <span>Passed: {getDateFormatted(article.date)}</span>
                        {article?.other && <span className="discontinued-date-label">Discontinued</span>}
                      </div>
                    )}
                    {isMobile && (
                      <FlexContainer align="center" className="featured-img-container">
                        {(article?.image?.content?.featured_m || article?.image?.content?.large) && (
                          <figure className="featured-img">
                            <img
                              src={article?.image.content.featured_m || article?.image.content.large}
                              alt={article?.title || ''}
                            />
                          </figure>
                        )}
                      </FlexContainer>
                    )}
                    <div className="metadata-url">
                      {article &&
                        article.URL &&
                        article.contentTypeId !== 'BOOK' &&
                        article.contentTypeId !== 'EQUIPMENT' && (
                          <a
                            style={{
                              display: '-webkit-box',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              marginRight: 20,
                            }}
                            href={article.URL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <OpenlinkIcon className="head-article-content-icon-link" />
                            {article.URL}
                          </a>
                        )}
                      {article &&
                        article.other &&
                        (article.contentTypeId === 'BOOK' || article.contentTypeId === 'EQUIPMENT') && (
                          <Button
                            primary
                            className="button-buy"
                            onClick={() => {
                              window.open(article.other, '_blank');
                            }}
                          >
                            Buy here
                          </Button>
                        )}
                    </div>
                  </FlexContainer>
                  {!isMobile && (
                    <FlexContainer elmWidth="20%" align="center" className="featured-img-container">
                      {(article?.image?.content?.featured_m || article?.image?.content?.large) && (
                        <FeatureImage>
                          <ImageEditorComponent
                            images={[{ url: article?.image?.content?.featured_m || article?.image?.content?.large }]}
                          />
                        </FeatureImage>
                      )}
                    </FlexContainer>
                  )}
                </FlexContainer>
              </MaxWidthContainer>
            </StyledView>
            <StyledView className="after-header-content">
              <MaxWidthContainer>
                <FlexContainer
                  style={{ cursor: 'pointer' }}
                  align="center"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (article?.user?._id && article?.user?.name !== '[DELETED]') {
                      if (e.ctrlKey || e.metaKey) {
                        window.open(`/profile/${article?.user?._id}`);
                      } else {
                        history.push(`/profile/${article?.user?._id}`);
                      }
                    }
                  }}
                >
                  <figure className="post-author-avatar">
                    <img
                      src={
                        article?.user?.photo?.content?.small ? article?.user?.photo?.content?.small : AvatarPlaceholder
                      }
                      alt={article?.user?.name || ''}
                    />
                  </figure>
                  <div className="author-metadata">
                    <div>
                      <strong>{article?.user?.name || '[DELETED]'}</strong>
                    </div>
                    <div className="post-metadata-date">
                      <span>{article?.created_at && getDateFormatted(article.created_at)}</span>
                    </div>
                  </div>
                </FlexContainer>
                <FlexContainer align="center">
                  <ToolbarReactions
                    articleId={article?._id}
                    liked={article?.liked}
                    likes={article?.likes}
                    comments={article?.comments}
                    userId={article?.userId}
                    onComments={() => {
                      if (refCommentsArea) {
                        const y = refCommentsArea?.current.getBoundingClientRect().top + window.pageYOffset - 130;
                        window.scrollTo({ top: y, behavior: 'smooth' });
                      }
                    }}
                  />
                </FlexContainer>
              </MaxWidthContainer>
            </StyledView>
          </ThemeProvider>
          <ThemeProvider theme={{ isDark: false }}>
            <StyledView>
              <MaxWidthContainer className="content-container">
                {article?.content ? <ArticleContentBody articleContent={article.content} /> : null}
              </MaxWidthContainer>
            </StyledView>
            <StyledView>
              <MaxWidthContainer>
                {article?.pdf && article?.pdf.url && (
                  <DownloadPdf
                    fileName={article.pdf.filename}
                    pdfUrl={article.pdf.url}
                    originalFilename={article.pdf.originalFilename}
                  />
                )}
                {article && article.URL && (article.contentTypeId === 'BOOK' || article.contentTypeId === 'EQUIPMENT') && (
                  <Button primary className="button-buy">
                    <a href={article.URL} target="_blank" rel="noopener noreferrer">
                      Buy here
                    </a>
                  </Button>
                )}
              </MaxWidthContainer>
            </StyledView>
            <StyledView>
              <MaxWidthContainer className="footer-author-meta">
                {article &&
                  article.keywords &&
                  article.keywords.length > 0 &&
                  article.keywords.map((k, index) => (
                    <Tag key={index} md tagType="primary" onClick={(e) => handleTagClick(e, k)}>
                      {k}
                    </Tag>
                  ))}
              </MaxWidthContainer>
              <MaxWidthContainer className="footer-author-meta">
                {article?.user && article?.created_at ? (
                  <AuthorMeta
                    userId={article?.user?._id}
                    authorName={article?.user?.name}
                    postDate={article?.created_at && getDateFormatted(article.created_at)}
                    avatarUrl={article?.user?.photo?.content?.small}
                  />
                ) : (
                  <AuthorMeta
                    authorName={'[DELETED]'}
                    postDate={
                      (article?.created_at && getDateFormatted(article?.created_at)) ||
                      (article?.create_at && getDateFormatted(article?.create_at))
                    }
                  />
                )}
              </MaxWidthContainer>
              <MaxWidthContainer className="reactions-column">
                <FlexContainer align="stretch" elmWidth="100%" justify="space-between">
                  {article?.communityEditsAllowed ? (
                    <FlexContainer align="center" elmWidth="40%" className="contributions-bar">
                      {/* <FlexContainer elmWidth="100%">
                        <FlexContainer align="center" elmWidth="100%" className="contributions-counter">
                          <span className="contributions-number">0</span>
                          <span>CONTRIBUTIONS</span>
                        </FlexContainer>
                      </FlexContainer> */}
                      {/* <FlexContainer elmWidth="100%">
                        <FlexContainer align="center" elmWidth="100%">
                          <EditIcon className="contributions-number" />
                          <span>SUGGEST EDIT</span>
                        </FlexContainer>
                      </FlexContainer> */}
                    </FlexContainer>
                  ) : (
                    <FlexContainer align="center" elmWidth="60%">
                      <span className="edit-lock">
                        <LockIcon />
                        EDIT LOCKED
                      </span>
                    </FlexContainer>
                  )}

                  <FlexContainer align="center" elmWidth="30%" justify="flex-end">
                    <ToolbarReactions
                      postDetail
                      articleId={article?._id}
                      liked={article?.liked}
                      likes={article?.likes}
                      comments={article?.comments}
                      userId={article?.userId}
                    />
                  </FlexContainer>
                </FlexContainer>
              </MaxWidthContainer>
            </StyledView>
            <StyledView className="comments-section">
              <MaxWidthContainer>
                <div ref={refCommentsArea}>
                  <CommentBox onPostComment={handlePostComment} setCanLeave={setCanLeave} />
                </div>
                <br />
                {loadingComments && (
                  <MaxWidthContainer>
                    <FlexContainer justify="center">
                      <Loader color="#6670F0" />
                    </FlexContainer>
                  </MaxWidthContainer>
                )}
                {comments.map((comment) => (
                  <Comment key={comment?._id} articleId={article?._id} {...comment} />
                ))}
              </MaxWidthContainer>
            </StyledView>
          </ThemeProvider>
        </>
      )}
      {!error && loading && (
        <StyledView>
          <MaxWidthContainer>
            <FlexContainer justify="center">
              <Loader color="#6670F0" />
            </FlexContainer>
          </MaxWidthContainer>
        </StyledView>
      )}
    </ArticleDetailContainer>
  );
}
