import styled from 'styled-components';
import { screen } from '../../utils/constants';

export const customStyle = {
  indicatorsContainer: (provided, state) => ({ color: state.isFocused ? 'white' : '#BDBFDF' }),
  indicatorSeparator: () => ({ display: 'none' }),
  control: (provided, state) => ({
    all: 'unset',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    lineHeight: '22px',
    background: '#151531',
    display: 'flex',
    margin: 'auto',
    paddingTop: '2px',
    paddingBottom: '2px',
    border: '1px solid transparent',
    borderColor: state.isFocused ? '#6670f0' : 'transparent',
    boxShadow: state.isFocused ? '0px 0px 12px rgba(97, 124, 255, 0.1)' : 'none',
    borderRadius: '8px',
    color: '#f6f8ff',
    width: '193px',
  }),
  input: (provided, state) => ({
    all: 'unset',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    lineHeight: '22px',
    background: '#151531',
    display: 'flex',
    margin: 'auto',
    border: '1px solid transparent',
    borderColor: state.isFocused ? '#6670f0' : 'transparent',
    boxShadow: state.isFocused ? '0px 0px 12px rgba(97, 124, 255, 0.1)' : 'none',
    borderRadius: '8px',
    color: '#f6f8ff',
    width: '100%',
  }),
  menu: (provided, state) => ({
    background: '#151531',
    color: '#f6f8ff',
    zIndex: 99,
    position: 'absolute',
    borderRadius: '0 0 8px 8px',
    boxShadow: '0px 0px 7px #151531',
  }),
  menuList: (provided, state) => ({ ...provided, color: '#BDBFDF', fontSize: '14px', background: state.isHover }),
};

const View = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => (props.theme.isDark ? props.theme.baseColors.dark : props.theme.baseColors.white)};
  color: ${(props) => (props.theme.isDark ? props.theme.baseColors.white : props.theme.baseColors.dark)};
`;

export const MaxWidthContainer = styled.div`
  max-width: 1224px;
  width: 100%;
  min-height: 800px;
  height: calc(100% - 150px);
  margin: auto;
  background-color: ${(props) => (props.theme.isDark ? props.theme.baseColors.dark : props.theme.baseColors.white)};
  color: ${(props) => (props.theme.isDark ? props.theme.baseColors.white : props.theme.baseColors.dark)};
`;

export const StyledView = styled(View)`
  justify-content: flex-start;
  overflow: auto;
  height: auto;
  position: relative;

  & .create-article-divider {
    margin-bottom: 0;
    margin-top: 0;
  }

  & > * {
    flex: 0;
  }

  ${MaxWidthContainer} {
    flex-grow: 1;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const ProfilePanel = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: #1f1f3d;
  color: white;
  flex: 0 0 0.3;
  min-width: 426px;
  max-width: 426px;
  min-height: 800px;
  height: 100%;
`;

export const ProfileUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 35px;
`;

export const ReportUserButton = styled.button`
  text-align: right;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #6670f0;
  background: none;
  outline: 0;
  border: none;
  cursor: pointer;
  @media (max-width: ${screen.SM}) {
    padding: 0;
  }
`;

export const ProfileImageContainer = styled.div`
  text-align: center;
  margin-bottom: 16px;
  border-radius: 50%;
  overflow: hidden;
`;

export const UserActionsPanel = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: stretch;
`;

export const UserActionButton = styled.button`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #9695b7;
  background: none;
  outline: 0;
  border: none;
  cursor: pointer;
`;

export const UserName = styled.h4`
  color: #6670f0;
  margin: 4px 0;
`;

export const Location = styled.div`
  margin: 4px 0;
`;

export const Genres = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  /* or 160% */

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  /* mid gray */

  color: #bdbfdf;
  margin: 4px 0;
`;

export const OtherUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  background: #151531;
  /* DS Dark Layering Y4 12-16% */

  box-shadow: 0px 4px 16px rgba(1, 1, 69, 0.24);
  padding: 0px 16px 16px 16px;
  align-items: center;
`;
export const StatusContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding: 9px 0;
  border-bottom: 1px solid #6670f0;
`;
export const Level = styled.div`
  color: #ffffff;
`;
export const KarmaContainer = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  text-align: center;

  /* clear BG */
  color: #f6f8ff;
`;
export const KarmaValue = styled.span`
  font-family: Pragati Narrow;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  /* Pink */

  color: #e3bbea;
`;
export const Description = styled.div`
  text-align: center;
  margin-top: 16px;
`;
export const BuskProfileButton = styled.button`
  font-family: Lato;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #ffffff;
  outline: 0;
  border: none;
  cursor: pointer;
  background: #6670f0;
  /* DS Focus 0-12 (10%) */

  box-shadow: 0px 0px 12px rgba(97, 124, 255, 0.1);
  border-radius: 18px;
  width: 100%;
  height: 36px;
  max-width: 193px;
  margin: 24px 0;
`;
export const JoinedCommunityContainer = styled.div`
  display: flex;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  color: #9695b7;
`;

export const ProfileAction = styled.div`
  cursor: pointer;
  display: flex;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;

  color: #6670f0;
`;

export const LogoutAction = styled.div`
  cursor: pointer;
  display: flex;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;

  color: #e3bbea;
`;

export const JoinedCommunityValue = styled.div`
  /* Caption - Lato 12 Bold */

  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  /* Pink */

  color: #e3bbea;
  margin-left: 13px;
`;

//Contributions Panel
export const ContributionsPanel = styled.div`
  flex: 1;
  padding: 40px 24px;
`;

export const ContributionContainer = styled.div`
  padding: 16px 0 11px 0;
  border-bottom: 1px solid #bdbfdf;
  cursor: pointer;
  & .breadcrumb {
    color: #9695b7;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    /* identical to box height, or 160% */

    display: flex;
    align-items: center;
    text-transform: uppercase;

    & svg {
      margin: 0 5px;
    }
  }
`;

export const ContributionsTitle = styled.h6`
  border-bottom: 1px solid #bdbfdf;
`;

export const AlertsPanel = styled.div`
  flex: 1;
  padding: 40px 24px;

  & .select-category.css-2b097c-container {
    @media (max-width: ${screen.SM}) {
      width: 99%;
      margin: 0;
    }
  }

  & .category__control.css-14xdnlq-Control,
  .category__control--is-focused {
    @media (max-width: ${screen.SM}) {
      width: 100%;
      margin: 0;
    }
  }

  & .category__option {
    background: #151531;
    &:hover {
      background: #1f1f3d;
      color: #6670f0;
    }
  }
  & .category__option--is-selected {
    color: #6670f0;
  }
  & .category__single-value {
    color: #bdbfdf;
  }
  & .css-tlfecz-indicatorContainer svg path,
  .css-tlfecz-indicatorContainer svg rect {
    fill: ${(props) => (props.theme.isDark ? props.theme.baseColors.middleLight : props.theme.baseColors.middle)};
    &:focus {
      fill: white;
    }
    &:hover {
      fill: white;
    }
  }

  & .category__control--is-focused .category__dropdown-indicator svg path {
    fill: ${(props) => (props.theme.isDark ? props.theme.baseColors.middleLight : props.theme.baseColors.middle)};
  }

  & .category__control {
    @media (max-width: ${screen.SM}) {
      border-radius: 0 0 8px 8px !important;
      width: 100%;
    }
  }
`;

export const ActiveTab = styled.h6`
  border-bottom: 1px solid #bdbfdf;
`;

export const ContributionDate = styled.div`
  /* Caption - Lato 12 Reg */

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  /* dark gray */

  color: #9695b7;
  margin-bottom: 7px;
`;

export const ContributionArticleLocation = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  /* identical to box height, or 160% */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #9695b7;
  margin: 12px 0 9px 0;

  & svg {
    margin: 0 5px;
  }
`;

export const ContributionArticleTitle = styled.h4`
  color: ${(props) => (props.theme.isDark ? props.theme.baseColors.middleLight : '#1f1f3d')};
  margin: 10px 0 8px 0;
`;

export const ContributionText = styled.h6`
  color: ${(props) => (props.theme.isDark ? props.theme.baseColors.middleLight : '#1f1f3d')};
  margin: 8px 0;
  text-transform: none;
`;

export const AlertTitle = styled.h3`
  /* H3 CAPS - Lato 16 reg */

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;

  /* dark */

  color: #1f1f3d;
`;

export const AlertToolbar = styled.div`
  background: #f6f8ff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin: 13px 0;
  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    & span {
      /* CAPT CAPS - Lato 10 reg */

      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 16px;
      /* identical to box height, or 160% */

      display: flex;
      align-items: center;
      text-transform: uppercase;

      /* dark gray */

      color: #9695b7;
      margin: 0 8px;
    }
    .divider {
      width: 0.5px;
      height: 10px;
      background-color: #bdbfdf;
    }

    .noselect {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */
    }
  }
  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    & span {
      /* CAPT CAPS - Lato 10 reg */
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 16px;
      /* identical to box height, or 160% */

      display: flex;
      align-items: center;
      text-transform: uppercase;

      /* Blue */

      color: #6670f0;

      margin: 0 10px;
    }
  }
`;

//Contributions Panel
export const ActivityPanel = styled.div`
  flex: 1;
  padding: 40px 24px;
`;

export const BadgeContainer = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BadgeTitle = styled.div`
  width: 100%;
  text-align: center;
  font-family: Lato;
  font-weight: semi-bold;
  font-size: 17px;
  margin-bottom: 10px;
`;

export const BadgeImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 360px;
`;
