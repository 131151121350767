import React, { useState, useEffect, useCallback } from 'react';
import { CloseIcon } from '../../../assets/icons/svg-icons';
import FlexContainer from '../../UI/FlexContainer';
import Button from '../../UI/Button';
import Input from '../../UI/forms/Input';
import Modal from '../../UI/modal/Modal';
import { updateCurrentUser, deactivateCurrentUser } from '../../../http/profileService';
import Loader from '../../UI/Loader';
import Notice from '../../UI/notice/Notice';
import styled from 'styled-components';
import { setAuthorization } from '../../../redux/reducers/authState';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default function UserSettings({ showUserSettings, setShowUserSettings }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);

  const [draftSettings, setDraftSettings] = useState({ currentPassword: '', changePassword: '', confirmPassword: '' });
  const [showDeactivateAccount, setShowDeactivateAccount] = useState(false);
  const [deactivate, setDeactivate] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (showUserSettings) {
      setDraftSettings({ currentPassword: '', changePassword: '', confirmPassword: '' });
    }
  }, [showUserSettings]);

  useEffect(() => {
    if (!user?.hasPassword) {
      setShowDeactivateAccount(true);
    }
  }, [user]);

  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        setShowUserSettings(false);
      }
    },
    [setShowUserSettings]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const onChange = (field, value) => {
    const newSettings = { ...draftSettings };
    if (typeof value === 'string') {
      newSettings[field] = value;
    } else {
      newSettings[field] = value.target.value;
    }
    setDraftSettings(newSettings);
  };

  const save = async () => {
    try {
      if (draftSettings.changePassword.length < 8) {
        setErrorMessage('Password need at least 8 characters');
        return;
      }
      if (!/[A-Z]+/.test(draftSettings.changePassword)) {
        setErrorMessage('Password need at least one uppercase');
        return;
      }

      setLoading(true);
      const data = {
        currentPassword: draftSettings.currentPassword,
        newPassword: draftSettings.changePassword,
      };
      await updateCurrentUser(data);
      setErrorMessage(null);
      setLoading(false);
      setShowUserSettings(false);
      setSuccessMessage('Your password has been updated');
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || 'Unable to change the password');

      setLoading(false);
    }
  };

  const deactivateAccount = async () => {
    try {
      setLoading(true);
      await deactivateCurrentUser();
      dispatch(setAuthorization(false));
      history.replace('/');
      setLoading(false);
      setShowUserSettings(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      {successMessage && (
        <Notice
          duration={2000}
          type="success"
          text={successMessage}
          callBack={() => {
            setSuccessMessage(null);
          }}
        />
      )}
      <Modal
        setVisibility={() => setShowUserSettings(showUserSettings)}
        visible={showUserSettings}
        elmHeight="auto"
        elmWidth="496px"
        footer={null}
      >
        <UserSettingsModal>
          <FlexContainer
            style={{ padding: 16 }}
            justify="space-between"
            align="center"
            className="reactions-toolbar"
            elmWidth="100%"
          >
            <UserSettingsModalTitle>ACCOUNT SETTINGS</UserSettingsModalTitle>
            <CloseIcon
              width="10"
              height="10"
              onClick={() => {
                setShowUserSettings(false);
              }}
            />
          </FlexContainer>
          <div
            style={{
              width: '100%',
              height: 1,
              backgroundColor: '#151531',
            }}
          />
          {!showDeactivateAccount && user?.hasPassword ? (
            <>
              <FlexContainer
                style={{ padding: '16px 16px 0 16px' }}
                justify="flex-start"
                align="center"
                className="reactions-toolbar"
                elmWidth="100%"
              >
                <Title>CHANGE PASSWORD</Title>
              </FlexContainer>
              <FlexContainer
                style={{ padding: 16 }}
                justify="flex-start"
                align="center"
                className="reactions-toolbar"
                elmWidth="100%"
              >
                <Input
                  type="password"
                  placeholder="Current password"
                  value={draftSettings?.currentPassword || ''}
                  handleChange={(value) => onChange('currentPassword', value)}
                />
              </FlexContainer>
              <div
                style={{
                  width: '95%',
                  margin: 'auto',
                  height: 1,
                  backgroundColor: '#151531',
                }}
              />
              <FlexContainer
                style={{ padding: '8px 16px' }}
                justify="space-between"
                align="center"
                className="reactions-toolbar"
                elmWidth="100%"
              >
                <Input
                  type="password"
                  placeholder="Change password"
                  value={draftSettings?.changePassword || ''}
                  handleChange={(value) => onChange('changePassword', value)}
                />
              </FlexContainer>
              <div
                style={{
                  width: '95%',
                  margin: 'auto',
                  height: 1,
                  backgroundColor: '#151531',
                }}
              />
              <FlexContainer
                style={{ padding: 16 }}
                justify="flex-start"
                align="center"
                className="reactions-toolbar"
                elmWidth="100%"
              >
                <Input
                  type="password"
                  placeholder="Confirm password"
                  value={draftSettings?.confirmPassword || ''}
                  handleChange={(value) => onChange('confirmPassword', value)}
                />
              </FlexContainer>
              <div
                style={{
                  width: '95%',
                  margin: 'auto',
                  height: 1,
                  backgroundColor: '#151531',
                }}
              />
              <FlexContainer
                style={{ padding: 16 }}
                justify="flex-start"
                align="center"
                className="reactions-toolbar"
                elmWidth="100%"
              >
                <DesactivateAccountAction
                  onClick={() => {
                    setShowDeactivateAccount(!showDeactivateAccount);
                  }}
                >
                  DEACTIVATE ACCOUNT
                </DesactivateAccountAction>
              </FlexContainer>
            </>
          ) : (
            <FlexContainer
              style={{ padding: 16 }}
              justify="flex-start"
              align="flex-start"
              className="reactions-toolbar"
              elmWidth="100%"
              column
            >
              <Title style={{ marginBottom: 10 }}>CONFIRM</Title>
              <Title style={{ marginBottom: 10 }}>
                If you deactivate your account, your data will be (securely) stored in case you decide to re-activate it
                again in the future. Please type the following word to confirm this action
              </Title>
              <Title>Please type the following word to confirm this action</Title>
              <FlexContainer
                style={{ padding: 16 }}
                justify="flex-start"
                align="center"
                className="reactions-toolbar"
                elmWidth="100%"
              >
                <Input
                  placeholder="Type “DEACTIVATE”"
                  value={deactivate || ''}
                  handleChange={(value) => setDeactivate(value.target.value)}
                />
              </FlexContainer>
            </FlexContainer>
          )}
          <FlexContainer
            style={{ padding: 20 }}
            justify="center"
            align="center"
            className="reactions-toolbar"
            elmWidth="100%"
          >
            <Button
              modalSecondary
              rounded
              onClick={() => {
                if (user.hasPassword) {
                  if (!showDeactivateAccount) {
                    setShowUserSettings(false);
                  } else {
                    setShowDeactivateAccount(false);
                  }
                } else {
                  setShowUserSettings(false);
                }
              }}
            >
              CANCEL
            </Button>
            {!showDeactivateAccount ? (
              <Button
                primary
                rounded
                style={{ marginLeft: 10 }}
                onClick={save}
                disabled={
                  loading ||
                  draftSettings?.changePassword === '' ||
                  draftSettings?.changePassword !== draftSettings?.confirmPassword
                }
              >
                SAVE
              </Button>
            ) : (
              <Button
                disabled={loading || deactivate !== 'DEACTIVATE'}
                primary
                rounded
                style={{ marginLeft: 10 }}
                onClick={deactivateAccount}
              >
                DEACTIVATE
              </Button>
            )}
          </FlexContainer>
          {loading && (
            <FlexContainer justify="center" align="center">
              <Loader />
            </FlexContainer>
          )}
          {errorMessage && (
            <FlexContainer justify="center" align="center">
              <div className="error-message">{errorMessage}</div>
            </FlexContainer>
          )}
        </UserSettingsModal>
      </Modal>
    </>
  );
}

const UserSettingsModal = styled.div`
  background: #1f1f3d;
  border-radius: 16px;
  max-width: 496px;
`;

const UserSettingsModalTitle = styled.div`
  /* Caption - Lato 12 Bold */

  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  /* mid gray */

  color: #bdbfdf;
`;

const DesactivateAccountAction = styled.div`
  margin-left: 10px;
  cursor: pointer;
  /* Caption - Lato 12 Bold */

  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  /* Blue */

  color: #6670f0;
`;

const Title = styled.div`
  margin-left: 10px;
  /* Caption - Lato 12 Bold */

  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  /* dark gray */

  color: #9695b7;
`;
