
export const customStyle = {
    indicatorsContainer: (provided, state) => ({ color: state.isFocused ? 'white' : '#BDBFDF' }),
    indicatorSeparator: () => ({ display: 'none' }),
    control: (provided, state) => ({
      all: 'unset',
      fontFamily: 'Open Sans',
      fontSize: '14px',
      lineHeight: '22px',
      background: '#151531',
      display: 'flex',
      margin: 'auto',
      paddingTop: '2px',
      paddingBottom: '2px',
      border: '1px solid transparent',
      borderColor: state.isFocused ? '#6670f0' : 'transparent',
      boxShadow: state.isFocused ? '0px 0px 12px rgba(97, 124, 255, 0.1)' : 'none',
      borderRadius: '8px 8px 8px 8px',
      color: '#f6f8ff',
      width: '193px',
    }),
    input: (provided, state) => ({
      all: 'unset',
      fontFamily: 'Open Sans',
      fontSize: '14px',
      lineHeight: '22px',
      background: '#151531',
      display: 'flex',
      margin: 'auto',
      border: '1px solid transparent',
      borderColor: state.isFocused ? '#6670f0' : 'transparent',
      boxShadow: state.isFocused ? '0px 0px 12px rgba(97, 124, 255, 0.1)' : 'none',
      borderRadius: '2px 2px 2px 2px',
      color: '#f6f8ff',
      width: '100%',
    }),
    menu: (provided, state) => ({
      background: '#151531',
      color: '#f6f8ff',
      zIndex: 99,
      position: 'absolute',
      borderRadius: '4px 4px 4px 4px',
      boxShadow: '0px 0px 7px #151531',
    }),
    menuList: (provided, state) => ({ ...provided, color: '#BDBFDF', fontSize: '14px', background: state.isHover }),
  };