import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { CloseIcon } from '../../../assets/icons/svg-icons';
import FlexContainer from '../../UI/FlexContainer';
import Button from '../../UI/Button';
import Checkbox from '../../login/components/Checkbox';
import Modal from '../../UI/modal/Modal';
import Loader from '../../UI/Loader';
import { updateCurrentUser } from '../../../http/profileService';
import Notice from '../../UI/notice/Notice';
import styled from 'styled-components';
import { onGetCurrentUser } from '../../../redux/actions/authActions';

export default function EmailNotifications({ me, showEmailNotifications, setShowEmailNotifications }) {
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = useState(null);

  const [loading, setLoading] = useState(false);

  const [draftSettings, setDraftSettings] = useState({
    commentsInMyPosts: me?.emailNotifications?.commentsInMyPosts,
    newPostsFromAlerts: me?.emailNotifications?.newPostsFromAlerts,
    communityUpdates: me?.emailNotifications?.communityUpdates,
    karma: me?.emailNotifications?.karma,
  });

  const loadData = useCallback(() => {
    const newSettings = {};
    if (me?.emailNotifications) {
      newSettings.commentsInMyPosts = me?.emailNotifications?.commentsInMyPosts;
      newSettings.newPostsFromAlerts = me?.emailNotifications?.newPostsFromAlerts;
      newSettings.communityUpdates = me?.emailNotifications?.communityUpdates;
      newSettings.karma = me?.emailNotifications?.karma;
    } else {
      newSettings.commentsInMyPosts = true;
      newSettings.newPostsFromAlerts = true;
      newSettings.communityUpdates = true;
      newSettings.karma = true;
    }

    setDraftSettings(newSettings);
  }, [me]);

  useEffect(() => {
    if (showEmailNotifications) {
      loadData();
    }
  }, [showEmailNotifications, loadData]);

  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        setShowEmailNotifications(false);
      }
    },
    [setShowEmailNotifications]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (me) {
      loadData();
    }
  }, [me, loadData]);

  const onChange = (field) => {
    const newSettings = { ...draftSettings };
    newSettings[field] = !newSettings[field];
    setDraftSettings(newSettings);
  };

  const save = async () => {
    try {
      setLoading(true);
      const data = {
        emailNotifications: draftSettings,
      };
      await updateCurrentUser(data);
      setLoading(false);
      setSuccessMessage('Your settings has been updated');
      dispatch(onGetCurrentUser());
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      {successMessage && (
        <Notice
          duration={2000}
          type="success"
          text={successMessage}
          callBack={() => {
            setSuccessMessage(null);
          }}
        />
      )}
      <Modal
        setVisibility={() => setShowEmailNotifications(showEmailNotifications)}
        visible={showEmailNotifications}
        elmHeight="auto"
        elmWidth="496px"
        footer={null}
      >
        <EmailNotificationsModal>
          <FlexContainer
            style={{ padding: 16 }}
            justify="space-between"
            align="center"
            className="reactions-toolbar"
            elmWidth="100%"
          >
            <EmailNotificationsModalTitle>EMAIL NOTIFICATIONS</EmailNotificationsModalTitle>
            <CloseIcon
              width="10"
              height="10"
              onClick={() => {
                setShowEmailNotifications(false);
              }}
            />
          </FlexContainer>
          <div
            style={{
              width: '100%',
              height: 1,
              backgroundColor: '#151531',
            }}
          />
          <FlexContainer
            style={{ padding: 16 }}
            justify="flex-start"
            align="center"
            className="reactions-toolbar"
            elmWidth="100%"
          >
            <Checkbox
              checked={draftSettings.commentsInMyPosts}
              disabled={false}
              readonly={false}
              onChange={() => onChange('commentsInMyPosts')}
            />
            <Label>Comments in my posts</Label>
          </FlexContainer>
          <div
            style={{
              width: '95%',
              margin: 'auto',
              height: 1,
              backgroundColor: '#151531',
            }}
          />
          <FlexContainer
            style={{ padding: 16 }}
            justify="flex-start"
            align="center"
            className="reactions-toolbar"
            elmWidth="100%"
          >
            <Checkbox checked={draftSettings.newPostsFromAlerts} onChange={() => onChange('newPostsFromAlerts')} />
            <Label>New post from alerts</Label>
          </FlexContainer>
          <div
            style={{
              width: '95%',
              margin: 'auto',
              height: 1,
              backgroundColor: '#151531',
            }}
          />
          <FlexContainer
            style={{ padding: 16 }}
            justify="flex-start"
            align="center"
            className="reactions-toolbar"
            elmWidth="100%"
          >
            <Checkbox checked={draftSettings.communityUpdates} onChange={() => onChange('communityUpdates')} />
            <Label>Community updates</Label>
          </FlexContainer>
          <div
            style={{
              width: '95%',
              margin: 'auto',
              height: 1,
              backgroundColor: '#151531',
            }}
          />
          <FlexContainer
            style={{ padding: 16 }}
            justify="flex-start"
            align="center"
            className="reactions-toolbar"
            elmWidth="100%"
          >
            <Checkbox checked={draftSettings.karma} onChange={() => onChange('karma')} />
            <Label>Karma</Label>
          </FlexContainer>
          <div
            style={{
              width: '95%',
              margin: 'auto',
              height: 1,
              backgroundColor: '#151531',
            }}
          />
          <FlexContainer
            style={{ padding: 20 }}
            justify="center"
            align="center"
            className="reactions-toolbar"
            elmWidth="100%"
          >
            <Button
              modalSecondary
              rounded
              onClick={() => {
                setShowEmailNotifications(false);
              }}
            >
              CANCEL
            </Button>

            <Button primary rounded style={{ marginLeft: 10 }} onClick={save} disabled={loading}>
              SAVE
            </Button>
          </FlexContainer>
          {loading && (
            <FlexContainer justify="center" align="center">
              <Loader />
            </FlexContainer>
          )}
        </EmailNotificationsModal>
      </Modal>
    </>
  );
}

const EmailNotificationsModal = styled.div`
  background: #1f1f3d;
  border-radius: 16px;
  max-width: 496px;
`;

const EmailNotificationsModalTitle = styled.div`
  /* Caption - Lato 12 Bold */

  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  /* mid gray */

  color: #bdbfdf;
`;

const Label = styled.div`
  /* Body - OpenSans 14 reg */

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: center;

  /* clear BG */

  color: #f6f8ff;
`;
