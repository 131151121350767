import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Loader from '../../components/UI/Loader';
import Notice from '../../components/UI/notice/Notice';
import { activate, reactivate } from '../../http/authService';

const Activation = ({ type }) => {
  const history = useHistory();

  const [toastMessage, setToastMessage] = useState(null);

  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const email = params.get('email') || '';
  const activationCode = params.get('activationCode') || '';

  const activateRequest = useCallback(async () => {
    try {
      await activate(email, activationCode);
      setToastMessage({
        type: 'success',
        message: 'Your account is activated now you can login',
        action: () => {
          history.replace('/');
        },
      });
    } catch (error) {
      setToastMessage({ type: 'error', message: error?.response?.data?.message || 'Unable to activate the account' });
    }
  }, [email, activationCode]);

  const reactivateRequest = useCallback(async () => {
    try {
      await reactivate(email, activationCode);
      setToastMessage({
        type: 'success',
        message: 'Your account is activated now you can login',
        action: () => {
          history.replace('/');
        },
      });
    } catch (error) {
      setToastMessage({ type: 'error', message: error?.response?.data?.message || 'Unable to reactivate the account' });
    }
  }, [email, activationCode]);

  useEffect(() => {
    if (activationCode && email) {
      if (type === 'activate') {
        activateRequest();
      }
      if (type === 'reactivate') {
        reactivateRequest();
      }
    }
  }, [email, activationCode, type]);

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {toastMessage && (
        <Notice
          duration={5000}
          type={toastMessage.type}
          text={toastMessage.message}
          callBack={() => {
            if (toastMessage.action) {
              toastMessage.action();
            }
            setToastMessage(null);
          }}
        />
      )}
      <Loader color="#111" />
    </div>
  );
};

export default Activation;
