import axios from 'axios';

const api = process.env.REACT_APP_API_URL;
const LIKES_ROUTE = api + '/likes';

//To set a like in a specific post triggered by specific user.
export const setLikeService = async (data) => {
  const token = await localStorage.getItem('_token');
  return axios.post(
    `${LIKES_ROUTE}`,
    data,
    {
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
    }
  );
};

//To unset a like in a specific post triggered by specific user.
export const unsetLikeService = async (data) => {
  const token = await localStorage.getItem('_token');
  let params = '';
  if (data.commentId) {
    params += `?commentId=${data.commentId}`;
  }
  if (data.articleId) {
    params += `?articleId=${data.articleId}`;
  }
  return axios.delete(`${LIKES_ROUTE}${params}`, {
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
  });
};
