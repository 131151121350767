import React from 'react';
import AvatarPlaceholder from '../../../assets/images/avatar-placeholder.png';
import FlexContainer from '../FlexContainer';
import { AuthorMetaLayout } from './styled-components';
import PropTypes from 'prop-types';
import Link from '../Link';

export default function AuthorMeta({ userId, authorName, postDate, avatarUrl }) {
  if (userId && authorName !== '[DELETED]') {
    return (
      <Link id="hidden_link" to={userId && authorName !== '[DELETED]' ? `/profile/${userId}` : ''}>
        <AuthorMetaLayout style={{ cursor: 'pointer' }}>
          <FlexContainer align="center">
            <figure className="post-author-avatar">
              <img src={avatarUrl ? avatarUrl : AvatarPlaceholder} alt={authorName} />
            </figure>
            <div className="author-metadata">
              <div>
                <strong>{authorName}</strong>
              </div>
              <div className="date-metadata">
                <span>{postDate}</span>
              </div>
            </div>
          </FlexContainer>
        </AuthorMetaLayout>
      </Link>
    );
  } else {
    return (
      <AuthorMetaLayout style={{ cursor: 'pointer' }}>
        <FlexContainer align="center">
          <figure className="post-author-avatar">
            <img src={avatarUrl ? avatarUrl : AvatarPlaceholder} alt={authorName} />
          </figure>
          <div className="author-metadata">
            <div>
              <strong>{authorName}</strong>
            </div>
            <div className="date-metadata">
              <span>{postDate}</span>
            </div>
          </div>
        </FlexContainer>
      </AuthorMetaLayout>
    );
  }
}

AuthorMeta.propTypes = {
  authorName: PropTypes.string.isRequired,
  postDate: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
};
