import React, { useState, useEffect } from 'react';
import { PunchIcon, SubMenuIcon, PunchIconFilled, CommentsIconUnfilled } from '../../../assets/icons/svg-icons';
import FlexContainer from '../FlexContainer';
import { ReactionLayout } from './styled-components';
import styled from 'styled-components';
import { setLikeService, unsetLikeService } from '../../../http/likeService';
import { deleteArticle } from '../../../http/articleService';
import { useSelector, useDispatch } from 'react-redux';
import Config from '../../../lib/Config';
import { ThemeProvider } from 'styled-components';
import Modal from '../modal/Modal';
import { useHistory } from 'react-router-dom';
import Report from '../../ArticleDetails/Report/Report';
import Notice from '../../UI/notice/Notice';
import { setVisibleLogin, setArticlesHome } from '../../../redux/reducers/appState';
import Menu from '../Menu';
import { useParams } from 'react-router-dom';
import { getArticleDetailBySlug } from '../../../redux/reducers/articleDetail';

export default function ToolbarReactions({
  articleId,
  postDetail,
  liked,
  likes,
  comments,
  onComments,
  userId,
  hideEllipses,
  modalOpen,
}) {
  const { slug } = useParams();

  const { articles } = useSelector((store) => store.app);

  const dispatch = useDispatch();

  const [showDelete, setShowDelete] = useState(false);
  const [reactions, setReactions] = useState({ like: liked, numLikes: likes });
  const [showReportModal, setShowReportModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { isMobile } = useSelector((store) => store.app);
  const { user, authorization } = useSelector((state) => state.auth);

  const history = useHistory();

  useEffect(() => {
    setReactions({ like: liked, numLikes: likes });
  }, [liked, likes]);

  useEffect(() => {
    if (authorization) {
      dispatch(setVisibleLogin(false));
    }
  }, [authorization, dispatch]);

  useEffect(() => {
    if (modalOpen) {
      modalOpen(showReportModal);
    }
  }, [showReportModal, modalOpen]);

  useEffect(() => {
    if (modalOpen) {
      modalOpen(showDelete);
    }
  }, [showDelete, modalOpen]);

  const setLike = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (authorization) {
      try {
        const response = await setLikeService({ articleId });
        setReactions({ ...reactions, like: true, numLikes: response.data.likes });
        dispatch(getArticleDetailBySlug(slug, true));
      } catch {}
    } else {
      dispatch(setVisibleLogin(true));
    }
  };

  const unsetLike = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (authorization) {
      try {
        const response = await unsetLikeService({ articleId });
        setReactions({ ...reactions, like: false, numLikes: response.data.likes });
        dispatch(getArticleDetailBySlug(slug, true));
      } catch {}
    } else {
      dispatch(setVisibleLogin(true));
    }
  };

  const deleteArticleRequest = async (e) => {
    try {
      setShowDelete(false);
      await deleteArticle(articleId);
      const filtered = articles.filter((item) => item._id !== articleId);
      dispatch(setArticlesHome(filtered));
      history.replace('/');
    } catch {}
  };

  const getOptions = () => {
    let options = [{ description: 'Report' }, { description: 'Share' }, { description: 'Suggest Edit' }];
    if (userId && user && userId === user?._id) {
      options.push({ description: 'Delete' });
    }
    return options;
  };

  return (
    <ReactionLayout postDetail={postDetail ? 1 : 0}>
      {successMessage && (
        <Notice
          duration={2000}
          type="success"
          text={successMessage}
          callBack={() => {
            setSuccessMessage(null);
          }}
        />
      )}
      {errorMessage && (
        <Notice
          duration={2000}
          type="error"
          text={errorMessage}
          callBack={() => {
            setErrorMessage(null);
          }}
        />
      )}

      <ThemeProvider theme={{ isDark: true }}>
        <Modal
          title="DELETE CONFIRMATION"
          setVisibility={() => {
            setShowDelete(!showDelete);
          }}
          visible={showDelete}
          elmHeight="auto"
          elmWidth="496px"
          textOk="Delete"
          okClick={deleteArticleRequest}
          header={undefined}
        >
          <p style={{ textAlign: 'center', marginTop: '16px' }}>
            Are you sure you want to delete this post?
            <br />
            This action cannot be undone
          </p>
        </Modal>
      </ThemeProvider>

      <Report
        articleId={articleId}
        setShowReportModal={setShowReportModal}
        showReportModal={showReportModal}
        onSuccess={() => {
          setSuccessMessage('Your report has been submitted successfully');
        }}
        onError={(message) => {
          setErrorMessage(message);
        }}
      />

      {!Config.HIDE_WHILE_LAUNCH ? (
        <FlexContainer justify="flex-end" align="center" className="reactions-toolbar" elmWidth="100%">
          <FlexContainer elmWidth="100%">
            <FlexContainer align="center" elmWidth="100%">
              {reactions.like ? <PunchIconFilled onClick={unsetLike} /> : <PunchIcon onClick={setLike} />}
              <span style={{ marginRight: 5 }}>{reactions.numLikes || 0}</span>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer elmWidth="100%">
            <FlexContainer align="center" elmWidth="100%">
              <CommentsIconUnfilled className="comment-icon" onClick={onComments} />
              <span style={{ marginRight: 5 }}>{comments || 0}</span>
            </FlexContainer>
          </FlexContainer>
          {postDetail && !isMobile && (
            <>
              {/* <FlexContainer elmWidth="100%">
                <FlexContainer className="share-btn" align="center" elmWidth="100%">
                  <ShareIcon />
                  <span>ssSHARE</span>
                </FlexContainer>
              </FlexContainer> */}
              {/* <FlexContainer elmWidth="100%">
                <FlexContainer
                  className="report-btn"
                  align="center"
                  elmWidth="100%"
                  onClick={() => {
                    setShowReportModal(true);
                  }}
                >
                  <FlagIcon fill={showReportModal} />
                  <span>REPORT</span>
                </FlexContainer>
              </FlexContainer> */}
            </>
          )}
          {(!hideEllipses && !postDetail) || (postDetail && isMobile) ? (
            <FlexContainer elmWidth="100%" style={{ position: 'relative' }}>
              <ThemeProvider theme={{ isDark: true }}>
                <Menu
                  onSelect={(option) => {
                    if (option.description === 'Delete') {
                      setShowDelete(true);
                    }
                    if (option.description === 'Report') {
                      setShowReportModal(true);
                    }
                  }}
                  options={getOptions()}
                  icon={<SubMenuIcon style={{ marginLeft: -20 }} />}
                />
              </ThemeProvider>
            </FlexContainer>
          ) : null}
        </FlexContainer>
      ) : null}
    </ReactionLayout>
  );
}

export const OptionsLayout = styled.div`
  background-color: #151531;
  color: ${(props) => (props.isActive ? 'white' : '#bdbfdf')};
  padding: 12px;
  border: 0.5px solid #1f1f3d;
  border-radius: 0 0 8px 8px;
  & span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */

    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &:hover {
    background-color: #1f1f3d;
    color: #6670f0;
  }
`;
