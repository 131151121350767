import React, { useCallback, useEffect, useState } from 'react';
import {
  ActivityPanel,
  ContributionsPanel,
  MaxWidthContainer,
  ProfileContainer,
  ProfilePanel,
  ProfileUserInfo,
  ProfileAction,
  AlertsPanel,
  customStyle,
  AlertTitle,
  AlertToolbar,
  LogoutAction,
  OtherUserInfo,
  KarmaContainer,
  KarmaValue,
  StatusContainer,
  Level,
  BadgeContainer,
  BadgeTitle,
  BadgeImages,
} from './styled-components';
import { IoMdHand } from 'react-icons/io';
import { ThemeProvider } from 'styled-components';
import { AlertIcon, Ellipse, EditIcon, CloseIcon } from '../../assets/icons/svg-icons';
import styled from 'styled-components';
import UserSettings from '../../components/Profile/UserSettings/UserSettings';
import EmailNotifications from '../../components/Profile/EmailNotifications/EmailNotifications';
import Input from '../../components/UI/forms/Input';
import TextArea from '../../components/UI/TextArea';
import LocationAutocomplete from '../../components/UI/LocationAutocomplete/LocationAutocomplete';
import Button from '../../components/UI/Button';
import FlexContainer from '../../components/UI/FlexContainer';
import { useHistory, useLocation } from 'react-router-dom';
import {
  PlusIcon,
  AgeIcon,
  ModRankIcon,
  PopularIcon,
  ChatterIcon,
  HallOfFarmerIcon,
  StaffIcon,
  ModeratorIcon,
  PhotogenicIcon,
  NewYouIcon,
  BuskcoIcon,
  LoitererIcon,
  ConversationStarterIcon,
  SocialiteIcon,
  HelperIcon,
  SheepDogIcon,
  TownCrierIcon,
  BlahblahblahIcon,
  BoffinIcon,
  LawyerIcon,
  AdvocateIcon,
  RebelIcon,
  RuleMakerIcon,
  ConsumerIcon,
  LibrarianIcon,
  PlacemakerIcon,
  TeacherIcon,
  PollsterIcon,
  HistorianIcon,
  EagleEyeIcon,
  SuperFanIcon,
  CuratorIcon,
  ProducerIcon,
  PaparazzoIcon,
  AdministratorIcon,
  HawkerIcon,
  NewbieIcon,
  PasserbyIcon,
  AppreciatorIcon,
  EntertainerIcon,
  BardIcon,
  MistrelIcon,
  TroubadourIcon,
  BuskerIcon,
  LegendIcon,
} from '../../assets/icons/svg-icons';
import Contribution from './Contribution';
import Alert from './Alert';
import Activity from './Activity';
import { useDispatch, useSelector } from 'react-redux';
import { getMeContributions, getMeActivities } from '../../redux/reducers/profileState';
import { setVisibleSettings } from '../../redux/reducers/appState';

import {
  updateCurrentUser,
  uploadImageRequest,
  validateBuskIntegration,
  createAlert,
  deleteAlert,
  getAlerts,
  editAlert,
} from '../../http/profileService';
import { setAuthorization } from '../../redux/reducers/authState';
import { onGetCurrentUser } from '../../redux/actions/authActions';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../../components/UI/Loader';
import Notice from '../../components/UI/notice/Notice';
import Avatar from '../../components/UI/avatar/Avatar';
import Select from 'react-select';
import { startCase } from 'lodash';

import { Prompt } from 'react-router-dom';
import Modal from '../../components/UI/modal/Modal';
import CustomTooltip from '../../components/UI/Tooltip';

import { useModal } from '../../components/UI/modal/useModal';

export default function OwnProfile() {
  const modal = useModal();
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);
  const [canLeave, setCanLeave] = useState(true);

  const { user } = useSelector((state) => state.auth);
  const { showSettings } = useSelector((state) => state.app);

  let { categoriesList } = useSelector((store) => store.topbarSearch);
  categoriesList = categoriesList.map((category) => ({
    ...category,
    label: startCase(category.label.toLowerCase()),
  }));
  const history = useHistory();
  const location = useLocation();
  const totalItems = useSelector((state) => state.profile.meTotalItems);
  const lastItem = useSelector((state) => state.profile.meLastItem);
  const contributions = useSelector((state) => state.profile.meContributions);

  const lastActivitiesItem = useSelector((state) => state.profile.meLastActivitiesItem);
  const activities = useSelector((state) => state.profile.meActivities);

  const dispatch = useDispatch();

  const [draftAlert, setDraftAlert] = useState({ term: '', location: null, category: null });
  const [showAlert, setShowAlert] = useState({});
  const [draftProfile, setDraftProfile] = useState(null);
  const [showUserSettings, setShowUserSettings] = useState(false);
  const [showEmailNotifications, setShowEmailNotifications] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [toastErrorMessage, setToastErrorMessage] = useState(null);
  const [loadingAlerts, setLoadingAlerts] = useState(false);
  const [alerts, setAlerts] = useState(null);
  const [loadingActivities] = useState(false);

  const [activeTab, setActiveTab] = useState('contributions');
  const [buskprofilechange, setBuskprofilechange] = useState(null);
  const genres = [
    'DANCER',
    'CIRCUS',
    'MUSICIAN',
    'JUGGLER',
    'CLOWN',
    'STATUE',
    'THEATRE',
    'MAGICIAN',
    'PUPPETEER',
    'ARTIST',
    'FREAKSHOW',
    'STORYTELLER',
    'OTHER',
  ];

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  useEffect(() => {
    console.log(location.hash);
    if (location.hash === '#activity') {
      setActiveTab('activity');
    } else if (location.hash === '#alerts') {
      setActiveTab('alerts');
      getAlertsRequest();
    }
  }, [location.hash]);

  useEffect(() => {
    if (showSettings) {
      setShowUserSettings(true);
      dispatch(setVisibleSettings(false));
    }
  }, [showSettings, dispatch]);

  const alertUser = useCallback(
    (e) => {
      if (!canLeave) {
        e.preventDefault();
        setShowPrompt(true);
        e.returnValue = true;
        return '';
      }
    },
    [canLeave]
  );

  useEffect(() => {
    setShowPrompt(!canLeave);
    window.addEventListener('beforeunload', alertUser);
    // window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
      window.removeEventListener('popstate', alertUser);
    };
  }, [canLeave, alertUser]);

  const validateCode = useCallback(
    async (buskValidationCode) => {
      try {
        await validateBuskIntegration({ code: buskValidationCode });
        setSuccessMessage('The Busk account has been activated');
        history.replace('/profile');
      } catch (err) {
        setToastErrorMessage('An error ocurred activating the Busk account');
      }
    },
    [history]
  );

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const buskValidationCode = query.get('buskValidationCode');
    if (buskValidationCode) {
      validateCode(buskValidationCode);
    }
  }, [validateCode]);

  const loadMoreActivities = useCallback(
    (forceFirst = false) => {
      dispatch(getMeActivities(forceFirst));
    },
    [dispatch]
  );

  const loadMoreContributions = useCallback(
    (forceFirst = false) => {
      dispatch(getMeContributions(forceFirst));
    },
    [dispatch]
  );

  useEffect(() => {
    if (activeTab === 'contributions') {
      loadMoreContributions(true);
    }
  }, [loadMoreContributions, activeTab]);

  useEffect(() => {
    if (activeTab === 'activity') {
      loadMoreActivities(true);
    }
  }, [loadMoreActivities, activeTab]);

  useEffect(() => {
    dispatch(onGetCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setDraftProfile({ ...user });
      if (user?.buskprofilechange) {
        setBuskprofilechange({ ...user?.buskprofilechange });
      }
    }
  }, [user]);

  const onChange = (field, value) => {
    setCanLeave(false);
    const newProfile = { ...draftProfile };
    if (typeof value === 'string') {
      newProfile[field] = value;
    } else {
      newProfile[field] = value.target.value;
    }
    setDraftProfile(newProfile);
  };

  const createAlertRequest = async () => {
    const data = {};
    let valid = false;
    if (draftAlert.term !== '') {
      data.phrase = draftAlert.term;
      valid = true;
    }
    if (draftAlert.location) {
      data.placeId = draftAlert.location;
      valid = true;
    }
    if (draftAlert.category) {
      data.categoryId = draftAlert.category;
      valid = true;
    }
    if (!valid) {
      return;
    }
    setLoadingAlerts(true);

    try {
      if (draftAlert?.id) {
        await editAlert(draftAlert?.id, data);
        setSuccessMessage('The alert has been edited');
      } else {
        await createAlert(data);
        setSuccessMessage('The alert has been created');
      }
      setDraftAlert({ term: '', location: null, category: null, locationInput: null });
      getAlertsRequest();
    } catch (err) {
      setToastErrorMessage(err?.response?.data?.message || 'Unable to create the alert');
    }

    setLoadingAlerts(false);
  };

  const getAlertsRequest = async () => {
    setLoadingAlerts(true);
    try {
      const result = await getAlerts();
      setAlerts(result.data);
    } catch (err) {
      setToastErrorMessage('Unable to get the alerts');
    }
    setLoadingAlerts(false);
  };

  const deleteAlertRequest = async (id) => {
    setLoadingAlerts(true);
    try {
      await deleteAlert(id);
      setSuccessMessage('The alert has been deleted');
      getAlertsRequest();
    } catch (err) {
      setToastErrorMessage('Unable to delete the alert');
    }
    setLoadingAlerts(false);
  };

  const save = async () => {
    try {
      setLoading(true);
      const data = {
        name: draftProfile.name,
        genre: draftProfile.genre,
        bio: draftProfile.bio,
        placeId: draftProfile.placeId,
        buskProfile: draftProfile.buskProfile,
      };
      const result = await updateCurrentUser(data);
      if (result?.data?.buskprofilechange) {
        setBuskprofilechange({ ...result?.data?.buskprofilechange });
      }
      dispatch(onGetCurrentUser());
      setLoading(false);
      setSuccessMessage('Your profile has been updated');
      setErrorMessage(null);
      setCanLeave(true);
    } catch (err) {
      setLoading(false);
      setErrorMessage('Unable to update the user profile');
    }
  };

  const saveImage = async (image) => {
    try {
      setLoadingImage(true);
      const result = await uploadImageRequest(image);
      await updateCurrentUser({ imageId: result?.data._id });
      dispatch(onGetCurrentUser());
      setLoadingImage(false);
    } catch {
      setLoadingImage(false);
    }
  };

  const onExitClick = () => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && showPrompt) {
      setLastLocation(nextLocation);
      modal.handleClick();
      return false;
    }

    return true;
  };

  const getFormatText = (type, value) => {
    switch (type) {
      case 'popular':
        switch (value) {
          case 1:
            return '25';
          case 2:
            return '100';
          case 3:
            return '500';
          case 4:
            return '1000';
          case 5:
            return '5000';
          case 6:
            return '10000';
          default:
            return '';
        }
      case 'chatterbox':
        switch (value) {
          case 1:
            return '1';
          case 2:
            return '5';
          case 3:
            return '25';
          case 4:
            return '100';
          case 5:
            return '250';
          default:
            return '';
        }
      case 'aged':
        switch (value) {
          case 1:
            return '1 year';
          case 2:
            return '2 years';
          case 3:
            return '3 years';
          case 4:
            return '5 years';
          case 5:
            return '10 years';
          default:
            return '';
        }
      case 'modRank':
        switch (value) {
          case 1:
            return '1 post';
          case 2:
            return '5 posts';
          case 3:
            return '10 posts';
          case 4:
            return '50 posts';
          case 5:
            return '100 posts';
          case 6:
            return '500 posts';
          default:
            return '';
        }
      default:
        return '';
    }
  };

  const Badges = ({ badges }) => {
    return (
      <>
        {user?.rank === 'newbie' && (
          <CustomTooltip text={'Starting rank!'}>
            <NewbieIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {user?.rank === 'passerby' && (
          <CustomTooltip text={'50+ points'}>
            <PasserbyIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {user?.rank === 'appreciator' && (
          <CustomTooltip text={'100+ points'}>
            <AppreciatorIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {user?.rank === 'entertainer' && (
          <CustomTooltip text={'250+ points'}>
            <EntertainerIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {user?.rank === 'bard' && (
          <CustomTooltip text={'500+ points, busk.co badge, 10+ badges'}>
            <BardIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {user?.rank === 'minstrel' && (
          <CustomTooltip text={'1,000+ points, busk.co badge, 20+ badges'}>
            <MistrelIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {user?.rank === 'troubadour' && (
          <CustomTooltip text={'2,000+ points, busk.co badge, 35+ badges'}>
            <TroubadourIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {user?.rank === 'busker' && (
          <CustomTooltip text={'5,000+ points, busk.co badge, 50+ badges'}>
            <BuskerIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {user?.rank === 'legend' && (
          <CustomTooltip text={'10,000+ points, busk.co badge, 50+ badges'}>
            <LegendIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}

        {badges?.popular !== 0 && (
          <CustomTooltip text={`${getFormatText('popular', badges?.popular)}+ upvotes received`}>
            <PopularIcon rank={badges?.popular} style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.chatterbox !== 0 && (
          <CustomTooltip text={`${getFormatText('chatterbox', badges?.chatterbox)} comments made`}>
            <ChatterIcon rank={badges?.chatterbox} style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.aged !== 0 && (
          <CustomTooltip text={`${getFormatText('aged', badges?.aged)} on the site`}>
            <AgeIcon rank={badges?.aged} style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.modRank !== 0 && (
          <CustomTooltip text={`Moderated ${getFormatText('modRank', badges?.modRank)}`}>
            <ModRankIcon rank={badges?.modRank} style={{ margin: 5 }} />
          </CustomTooltip>
        )}

        {badges?.staff && (
          <CustomTooltip text={'Staff: I work here'}>
            <StaffIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.moderator && (
          <CustomTooltip text={'Moderator: I am a mod'}>
            <ModeratorIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.photogenic && (
          <CustomTooltip text={'Photogenic: Updated profile photo'}>
            <PhotogenicIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.newYou && (
          <CustomTooltip text={'New You: Updated bio'}>
            <NewYouIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.buskco && (
          <CustomTooltip text={'I have linked my profile on busk.co'}>
            <BuskcoIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.loiterer && (
          <CustomTooltip text={'Loiterer: Logged in 10 times'}>
            <LoitererIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.conversationStarter && (
          <CustomTooltip text={'Conversation starter: 10+ posts with 10+ comments each'}>
            <ConversationStarterIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.socialite && (
          <CustomTooltip text={'Socialite: 50 users have commented on my posts'}>
            <SocialiteIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.helper && (
          <CustomTooltip text={'Helper: mods deleted a post I reported'}>
            <HelperIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.sheepDog && (
          <CustomTooltip text={'Sheep dog: mods blocked a user I reported'}>
            <SheepDogIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.townCrier && (
          <CustomTooltip text={'Town Crier: posted in “Busking in the news”'}>
            <TownCrierIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.blahblahblah && (
          <CustomTooltip text={'Blah Blah Blah: posted in “Chit Chat”'}>
            <BlahblahblahIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.boffin && (
          <CustomTooltip text={'Boffin: posted in “Academic Paper”'}>
            <BoffinIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.lawyer && (
          <CustomTooltip text={'Lawyer: posted in “Law” or “Lawyer”'}>
            <LawyerIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.advocate && (
          <CustomTooltip text={'Advocate: posted in “Court Case”'}>
            <AdvocateIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.rebel && (
          <CustomTooltip text={'Rebel: posted in “Arrest”, “Harassment” or “Fine”'}>
            <RebelIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.ruleMaker && (
          <CustomTooltip text={'Rule maker: posted in “Code of Conduct” or “Permit”'}>
            <RuleMakerIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.consumer && (
          <CustomTooltip text={'Consumer: posted in “Equipment”'}>
            <ConsumerIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.librarian && (
          <CustomTooltip text={'Librarian: posted in “Books”'}>
            <LibrarianIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.placemaker && (
          <CustomTooltip text={'Placemaker: posted in “Placemaking”'}>
            <PlacemakerIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.teacher && (
          <CustomTooltip text={'Teacher: posted in “Guide”'}>
            <TeacherIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.pollster && (
          <CustomTooltip text={'Pollster: posted in “Poll/Survey”'}>
            <PollsterIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.historian && (
          <CustomTooltip text={'Historian: posted in “Historical Event”'}>
            <HistorianIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.hallOfFamer && (
          <CustomTooltip text={'I work at the Buskers Hall of Fame'}>
            <HallOfFarmerIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.eagleEye && (
          <CustomTooltip text={'Eagle Eye: posted in “TV” or “Movie”'}>
            <EagleEyeIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.superFan && (
          <CustomTooltip text={'Superfan: posted in “Celebrity”'}>
            <SuperFanIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.curator && (
          <CustomTooltip text={'Curator: posted in “Fine Art”'}>
            <CuratorIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.producer && (
          <CustomTooltip text={'Producer: posted in “Festival”'}>
            <ProducerIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.paparazzo && (
          <CustomTooltip text={'Paparazzo: posted in “Photographer” or “Filmmaker”'}>
            <PaparazzoIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.administrator && (
          <CustomTooltip text={'Administrator: posted in “Organisation”'}>
            <AdministratorIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
        {badges?.hawker && (
          <CustomTooltip text={'Hawker: posted in “Service”'}>
            <HawkerIcon style={{ margin: 5 }} />
          </CustomTooltip>
        )}
      </>
    );
  };

  return (
    <MaxWidthContainer>
      <Prompt when={showPrompt} message={handleBlockedNavigation} />
      <ThemeProvider theme={{ isDark: true }}>
        <Modal
          title="Exit Page"
          setVisibility={modal.handleClick}
          visible={modal.visible}
          elmHeight="auto"
          elmWidth="496px"
          textOk="Leave"
          okClick={onExitClick}
          header={undefined}
        >
          <p style={{ textAlign: 'center', marginTop: '16px' }}>
            You have unfinished changes,
            <br /> If you leave now you may lose them.
          </p>
        </Modal>
      </ThemeProvider>

      <UserSettings showUserSettings={showUserSettings} setShowUserSettings={setShowUserSettings} />
      <EmailNotifications
        me={user}
        showEmailNotifications={showEmailNotifications}
        setShowEmailNotifications={setShowEmailNotifications}
      />
      {successMessage && (
        <Notice
          duration={2000}
          type="success"
          text={successMessage}
          callBack={() => {
            setSuccessMessage(null);
          }}
        />
      )}
      {toastErrorMessage && (
        <Notice
          duration={2000}
          type="error"
          text={toastErrorMessage}
          callBack={() => {
            setToastErrorMessage(null);
          }}
        />
      )}
      <ProfileContainer>
        <ProfilePanel>
          <ProfileUserInfo>
            <Avatar
              src={user?.photo?.content?.medium}
              size={96}
              editable
              loading={loadingImage}
              onChange={(image) => {
                saveImage(image);
              }}
            />
            <FlexContainer style={{ padding: '10px 23px 10px 23px' }} justify="center" align="center" elmWidth="100%">
              <Input
                type="text"
                readonly={false}
                placeholder="Name"
                value={draftProfile?.name || ''}
                block
                handleChange={(value) => onChange('name', value)}
              />
            </FlexContainer>
            <FlexContainer style={{ padding: '10px 23px 10px 23px' }} justify="center" align="center" elmWidth="100%">
              <LocationAutocomplete
                value={draftProfile?.location?.place_id}
                addressValue={draftProfile?.location?.formatted_address}
                placeholder="City"
                onChangeData={(placeId) => onChange('placeId', placeId)}
              />
            </FlexContainer>
            <FlexContainer
              style={{ padding: '10px 23px 10px 23px', flexWrap: 'wrap' }}
              justify="center"
              align="center"
              elmWidth="100%"
            >
              {genres.map((genre) => {
                if (genre === draftProfile?.genre) {
                  return <GenreSelectedContainer>{genre}</GenreSelectedContainer>;
                } else {
                  return <GenreContainer onClick={() => onChange('genre', genre)}>{genre}</GenreContainer>;
                }
              })}
            </FlexContainer>
            <FlexContainer style={{ padding: '10px 23px 10px 23px' }} justify="center" align="center" elmWidth="100%">
              <TextArea
                dark
                limit={2000}
                limitWarning
                style={{ width: '100%', margin: '7px 0' }}
                placeholder="Description"
                value={draftProfile?.bio || ''}
                onChange={(value) => {
                  if (value.length > 2000) {
                    setToastErrorMessage(`The description has exceeded the maximum length of 2000 characters`);
                  }

                  onChange('bio', value);
                }}
                rows={4}
              />
            </FlexContainer>
            <FlexContainer style={{ padding: '10px 23px 10px 23px' }} justify="center" align="center" elmWidth="100%">
              <Input
                type="text"
                placeholder="Your busk.co profile URL"
                value={draftProfile?.buskProfile || buskprofilechange?.profileUrl || ''}
                handleChange={(value) => onChange('buskProfile', value)}
              />
            </FlexContainer>
            {buskprofilechange && 'You have a pending change to approve'}
            <FlexContainer style={{ padding: 20 }} justify="center" align="center" elmWidth="100%">
              <Button
                style={{ marginRight: 10 }}
                modalSecondary
                onClick={() => {
                  history.replace('/');
                }}
              >
                EXIT
              </Button>
              <Button style={{ marginLeft: 10 }} primary onClick={save} disabled={loading}>
                SAVE
              </Button>
            </FlexContainer>
            <OtherUserInfo style={{ width: '100%' }}>
              <StatusContainer style={{ display: 'flex', alignItems: 'center' }}>
                <Level>
                  <IoMdHand style={{ fontSize: 16, marginRight: '12px', verticalAlign: 'middle' }} />
                  {user?.rank?.capitalize() || '-'}
                </Level>
                <KarmaContainer>
                  <KarmaValue>{user?.points || 0}</KarmaValue> Karma
                </KarmaContainer>
              </StatusContainer>
            </OtherUserInfo>
            <BadgeContainer>
              <BadgeTitle>Badges</BadgeTitle>
              <BadgeImages>
                <Badges badges={user?.badges} />
              </BadgeImages>
            </BadgeContainer>
            {loading && (
              <FlexContainer justify="center" align="center">
                <Loader />
              </FlexContainer>
            )}
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <FlexContainer style={{ padding: '20px 0px' }} justify="center" align="center" elmWidth="100%" column>
              <FlexContainer
                style={{ borderTop: '1px solid #151531', padding: 10 }}
                justify="center"
                align="center"
                elmWidth="100%"
                onClick={() => {
                  setShowUserSettings(!showUserSettings);
                }}
              >
                <ProfileAction>Account Settings</ProfileAction>
              </FlexContainer>
              <FlexContainer
                style={{ borderTop: '1px solid #151531', padding: 10 }}
                justify="center"
                align="center"
                elmWidth="100%"
                onClick={() => {
                  setShowEmailNotifications(!showEmailNotifications);
                }}
              >
                <ProfileAction>Email Notifications</ProfileAction>
              </FlexContainer>

              <FlexContainer
                style={{ borderTop: '1px solid #151531', padding: 10 }}
                justify="center"
                align="center"
                elmWidth="100%"
                onClick={() => {
                  history.push(`/profile/${user._id}`);
                  dispatch(setAuthorization(false));
                }}
              >
                <LogoutAction>Logout</LogoutAction>
              </FlexContainer>
            </FlexContainer>
          </ProfileUserInfo>
        </ProfilePanel>
        <FlexContainer
          style={{ padding: '48px 0px 48px 30px' }}
          justify="flex-start"
          align="flex-start"
          elmWidth="100%"
          column
        >
          <FlexContainer justify="flex-start" align="center" elmWidth="100%">
            <FlexContainer
              style={{
                cursor: 'pointer',
                padding: '0 20px',
                borderBottom: activeTab === 'contributions' ? '2px solid #6670F0' : 'none',
              }}
              onClick={() => setActiveTab('contributions')}
            >
              {totalItems === undefined ? 'Loading...' : `${totalItems} CONTRIBUTIONS`}
            </FlexContainer>
            <FlexContainer
              style={{
                cursor: 'pointer',
                padding: '0 20px',
                borderBottom: activeTab === 'activity' ? '2px solid #6670F0' : 'none',
              }}
              onClick={() => {
                setActiveTab('activity');
              }}
            >
              ACTIVITY
            </FlexContainer>
            <FlexContainer
              style={{
                cursor: 'pointer',
                padding: '0 20px',
                borderBottom: activeTab === 'alerts' ? '2px solid #6670F0' : 'none',
              }}
              onClick={() => {
                getAlertsRequest();
                setActiveTab('alerts');
              }}
            >
              ALERTS
            </FlexContainer>
          </FlexContainer>
          <div style={{ marginTop: 0, height: 1, width: '100%', borderBottom: '1px solid #BDBFDF' }} />

          <FlexContainer id="scrollableDiv" style={{ height: '100%', overflow: 'scroll', width: '100%' }}>
            {activeTab === 'contributions' && (
              <ContributionsPanel>
                <InfiniteScroll
                  scrollableTarget="scrollableDiv"
                  dataLength={contributions.length}
                  next={loadMoreContributions}
                  hasMore={lastItem !== null}
                >
                  {contributions.map((contribution) => {
                    if (contribution?.article) {
                      return <Contribution key={contribution._id} {...contribution} />;
                    }
                    return null;
                  })}
                </InfiniteScroll>
              </ContributionsPanel>
            )}

            {activeTab === 'activity' && (
              <ActivityPanel>
                {loadingActivities && (
                  <FlexContainer justify="center" align="center">
                    <Loader color="#1F1F3D" />
                  </FlexContainer>
                )}
                <InfiniteScroll
                  scrollableTarget="scrollableDiv"
                  dataLength={contributions.length}
                  next={loadMoreActivities}
                  hasMore={lastActivitiesItem !== null}
                >
                  {activities?.map((activity) => (
                    <Activity
                      type={activity?.type || ''}
                      count={activity?.count || 0}
                      article={activity?.article}
                      created_at={activity?.created_at}
                    />
                  ))}
                </InfiniteScroll>
              </ActivityPanel>
            )}

            {activeTab === 'alerts' && (
              <AlertsPanel>
                <div
                  style={{
                    padding: 16,
                    backgroundColor: '#1f1f3d',
                    borderRadius: 16,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Title>CREATE AN ALERT OF THE TOPICS YOU WANT TO FOLLOW</Title>
                  <div
                    style={{
                      width: '100%',
                      height: 1,
                      backgroundColor: '#151531',
                    }}
                  />

                  <FlexContainer
                    style={{ padding: 10 }}
                    justify="flex-start"
                    align="flex-end"
                    className="reactions-toolbar"
                    elmWidth="100%"
                  >
                    <FlexContainer column>
                      <Label>Exact phrase to follow</Label>
                      <FlexContainer style={{ padding: '10px 0' }} justify="center" align="center" elmWidth="100%">
                        <Input
                          type="text"
                          readonly={false}
                          placeholder="Eg, Amps, Arrest, etc."
                          value={draftAlert?.term || ''}
                          block
                          handleChange={(value) => setDraftAlert({ ...draftAlert, term: value.target.value })}
                        />
                        <Conector>{'&'}</Conector>
                      </FlexContainer>
                    </FlexContainer>
                    <FlexContainer column>
                      <Label>Location (optional)</Label>
                      <FlexContainer style={{ padding: '10px 0' }} justify="center" align="center" elmWidth="100%">
                        <LocationAutocomplete
                          value={draftAlert?.location?.place_id || ''}
                          addressValue={draftAlert?.location?.formatted_address || ''}
                          placeholder="City"
                          inputValue={draftAlert?.locationInput}
                          onChangeInput={(value) => setDraftAlert({ ...draftAlert, locationInput: value })}
                          onChangeData={(place) => setDraftAlert({ ...draftAlert, location: place })}
                        />
                        <Conector>{'&'}</Conector>
                      </FlexContainer>
                    </FlexContainer>
                    <FlexContainer column>
                      <Label>Category</Label>
                      <FlexContainer style={{ padding: '10px 0' }} justify="center" align="center" elmWidth="100%">
                        <Select
                          options={[
                            {
                              label: categoriesList && categoriesList.length ? 'All categories' : 'Loading...',
                              value: '',
                            },
                            ...categoriesList,
                          ]}
                          styles={customStyle}
                          placeholder="All categories"
                          className="select-category"
                          classNamePrefix="category"
                          isSearchable={false}
                          onChange={(val) => {
                            setDraftAlert({ ...draftAlert, category: val.value });
                          }}
                          defaultValue={categoriesList.filter((c) => c.value === draftAlert.category)}
                          value={categoriesList.filter((c) => c.value === draftAlert.category)}
                        />
                      </FlexContainer>
                    </FlexContainer>
                    <FlexContainer style={{ marginLeft: 30, marginBottom: 12 }} align="flex-end" elmHeight="100%">
                      <PlusButton onClick={createAlertRequest}>
                        {draftAlert?.id ? <EditIcon /> : <PlusIcon marginRight={0} />}
                      </PlusButton>
                    </FlexContainer>
                  </FlexContainer>
                  <Footer>
                    Put in the exact word or phrase you want to get notifications about. We’ll send you a notification
                    when there are new posts matching it.
                  </Footer>
                </div>

                <AlertTitle style={{ marginTop: 26 }}>YOUR ALERTS</AlertTitle>
                {loadingAlerts && (
                  <FlexContainer justify="center" align="center">
                    <Loader color="#1F1F3D" />
                  </FlexContainer>
                )}
                {alerts?.map((item) => (
                  <>
                    <AlertToolbar>
                      <div className="left">
                        <AlertIcon />
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            if (e.ctrlKey || e.metaKey) {
                              window.open(`/?search=${item.phrase}&sort=created_at`);
                            } else {
                              history.push(`/?search=${item.phrase}&sort=created_at`);
                            }
                          }}
                        >
                          {item.phrase || 'PHRASE'}
                        </span>
                        <Ellipse fill="#6670F0" />
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (!item?.location?.place_id) {
                              return;
                            }
                            if (e.ctrlKey || e.metaKey) {
                              window.open(
                                `/?locationID=${item.placeId}&locationName=${item?.locality}&sort=created_at`
                              );
                            } else {
                              history.push(
                                `/?locationID=${item.placeId}&locationName=${item?.locality}&sort=created_at`
                              );
                            }
                          }}
                        >
                          {item.place?.locality || 'LOCATION'}
                        </span>
                        <Ellipse fill="#6670F0" />
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            if (e.ctrlKey || e.metaKey) {
                              window.open(`/?category=${item.categoryId}&sort=created_at`);
                            } else {
                              history.push(`/?category=${item.categoryId}&sort=created_at`);
                            }
                          }}
                        >
                          {item.categoryId || 'CATEGORY'}
                        </span>
                      </div>
                      <div className="right">
                        <div
                          style={{ display: 'flex', cursor: 'pointer' }}
                          onClick={() => {
                            if (showAlert?.[item._id] === true) {
                              setShowAlert({ ...showAlert, [item._id]: false });
                            } else {
                              setShowAlert({ ...showAlert, [item._id]: true });
                            }
                          }}
                        >
                          <span className="noselect" style={{ color: '#9695b7' }}>
                            {showAlert?.[item._id] === true ? 'HIDE' : 'SHOW'}
                          </span>
                        </div>
                        <div className="divider" />
                        <CloseIcon
                          style={{ marginLeft: 10, cursor: 'pointer' }}
                          height={12}
                          width={12}
                          onClick={() => {
                            deleteAlertRequest(item._id);
                          }}
                        />
                      </div>
                    </AlertToolbar>
                    {showAlert?.[item._id] === true && (
                      <>
                        {item?.results?.map((alert) => (
                          <Alert key={alert._id} {...alert} />
                        ))}
                      </>
                    )}
                  </>
                ))}
              </AlertsPanel>
            )}
          </FlexContainer>
        </FlexContainer>
      </ProfileContainer>
    </MaxWidthContainer>
  );
}

const GenreContainer = styled.div`
  cursor: pointer;
  background: #3b3b64;
  border-radius: 4px;
  padding: 3px 10px;
  margin: 5px;
  /* CAPT CAPS - Lato 10 reg */

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  /* or 160% */

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  /* mid gray */

  color: #bdbfdf;
`;

const GenreSelectedContainer = styled.div`
  cursor: pointer;
  background: #6670f0;
  border-radius: 4px;
  padding: 3px 10px;
  margin: 5px;
  /* CAPT CAPS - Lato 10 reg */

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  /* or 160% */

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  /* darker */

  color: #151531;
`;

const Title = styled.div`
  /* Caption - Lato 12 Bold */

  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;

  /* mid gray */

  color: #bdbfdf;
`;

const Footer = styled.div`
  /* Caption - Lato 12 Bold */

  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  width: 100%;

  /* dark gray */

  color: #9695b7;
  padding: 0 40px;
`;

const Label = styled.div`
  /* Caption - Lato 12 Bold */

  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;

  /* mid gray */

  color: #bdbfdf;
`;

const Conector = styled.div`
  /* H4 - Latto 16 Bold */

  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;

  /* clear BG */

  color: #f6f8ff;
  margin-left: 10px;
  margin-right: 10px;
`;

const PlusButton = styled.div`
  cursor: pointer;
  width: 36px;
  height: 36px;
  background: #6670f0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
