import { isFunction } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import Login from '../../login/Login';
import Button from '../../UI/Button';
import Modal from '../../UI/modal/Modal';
import TextArea from '../../UI/TextArea';
import CommenterInfo from './CommenterInfo';
import { CommentBoxActions, CommentBoxContainer, CommentBoxHeader } from './styled-components';
import { connect, useDispatch } from 'react-redux';
import Recaptcha from 'react-google-invisible-recaptcha';
import { checkLogin, onGetCurrentUser } from '../../../redux/actions/authActions';
import Loading from '../../UI/Loading';
import Notice from '../../UI/notice/Notice';

const CommentBox = ({ onPostComment, setAuth, auth, user, loadingComment, setCanLeave, isChildren = false }) => {
  const refCaptcha = React.useRef(null);

  const [commentText, setCommentText] = useState(``);
  const [modal, setModal] = useState(false);

  const [externalFocus, setExternalFocus] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (commentText.length) {
      setCanLeave(false);
    } else {
      setCanLeave(true);
    }
  }, [commentText.length, setCanLeave]);

  useEffect(() => {
    dispatch(onGetCurrentUser());
  }, [dispatch, auth]);

  const toggleModal = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  const onCheckAuth = useCallback(async () => {
    await setAuth();
    if (!auth) {
      toggleModal();
    }
  }, [auth, setAuth, toggleModal]);

  useEffect(() => {
    if (externalFocus) {
      onCheckAuth();
    }
  }, [externalFocus, onCheckAuth]);

  useEffect(() => {
    if (refCaptcha?.current?.callbacks) {
      refCaptcha.current.callbacks.execute();
    }
  }, [refCaptcha]);

  const onClearClick = useCallback(() => {
    setCommentText('');
  }, [setCommentText]);

  const onPostCommentClick = () => {
    const token = refCaptcha.current.callbacks.getResponse();
    isFunction(onPostComment) && onPostComment({ comment: commentText, recaptchaToken: token });
    setCommentText('');
    if (refCaptcha?.current?.callbacks) {
      refCaptcha.current.callbacks.execute();
    }
  };

  const onTextChange = (value) => {
    if (value.length > 2000) {
      setErrorMessage(`The reply has exceeded the maximum length of 2000 characters`);
    }
    setCommentText(value);
  };

  return (
    <>
      <div style={{ position: 'fixed', bottom: 15, right: 0, height: 50, width: 100 }}>
        <Recaptcha
          onResolved={() => {
            /* Human detected. */
          }}
          ref={refCaptcha}
          sitekey="6Ld4C5gcAAAAAIbVyz_lYVsTV0e4ZVsvIK9U26qT"
        />
      </div>

      <CommentBoxContainer>
        {errorMessage && (
          <Notice
            duration={2000}
            type="error"
            text={errorMessage}
            callBack={() => {
              setErrorMessage(null);
            }}
          />
        )}
        {user && (
          <CommentBoxHeader>
            <CommenterInfo
              profileImage={
                user?.photo?.content?.small ? user?.photo?.content?.small : 'https://via.placeholder.com/24'
              }
              name={user?.name}
            />
          </CommentBoxHeader>
        )}
        <>
          <TextArea
            setExternalFocus={setExternalFocus}
            style={{ width: '100%', margin: '7px 0' }}
            placeholder={isChildren ? 'Reply here...' : 'Comment here...'}
            value={commentText}
            onChange={onTextChange}
            rows={4}
            onClick={onCheckAuth}
            limit={2000}
            limitWarning
          ></TextArea>
        </>
        <CommentBoxActions>
          <Button modalSecondary rounded onClick={onClearClick} size={90}>
            Clear
          </Button>
          <Button
            primary
            rounded
            onClick={onPostCommentClick}
            disabled={!auth || loadingComment || commentText.length === 0 || commentText.length > 2000}
          >
            {loadingComment ? <Loading /> : isChildren ? 'Post Reply' : 'Post Comment'}
          </Button>
        </CommentBoxActions>
      </CommentBoxContainer>
      <ThemeProvider theme={{ isDark: true }}>
        <Modal setVisibility={toggleModal} visible={modal} elmHeight="auto" elmWidth="496px" footer={null}>
          <Login handleCancel={toggleModal} callback={toggleModal} />
        </Modal>
      </ThemeProvider>
    </>
  );
};
const mapStateToProps = (store) => ({
  auth: store.auth.authorization,
  user: store.auth.user,
  loadingComment: store.comments.loadingPostComment,
});
const mapDispatchToProps = (dispatch) => ({ setAuth: checkLogin });

export default connect(mapStateToProps, mapDispatchToProps)(CommentBox);
