import React from 'react';
import { Switch, Route, Router, BrowserRouter } from 'react-router-dom';
import Home from '../views/Home/Home';
import CreateArticle from '../views/CreateArticle/CreateArticle';
import Layout from '../components/Layout/Layout';
import ArticleDetail from '../views/ArticleDetail/ArticleDetail';
import { useSelector, useDispatch } from 'react-redux';
import NotFound from '../views/NotFound/NotFound';
import ContentEditor from '../components/CreateArticle/DanteEditor/ContentEditor';
import history from './history';
import { Helmet } from 'react-helmet';
import { SITE_TITLE } from '../utils/constants';
import Profile from '../views/Profile/Profile';
import OwnProfile from '../views/Profile/OwnProfile';
import Activation from '../views/Activation/Activation';
import ComponentsTestPage from '../views/ComponentsTestPage';
import { ThemeProvider } from 'styled-components';
import Modal from '../components/UI/modal/Modal';
import Login from '../components/login/Login';
import { setVisibleLogin } from '../redux/reducers/appState';

const RouterApp = () => {
  const dispatch = useDispatch();

  const { authorization } = useSelector((store) => store.auth);
  const { showLogin } = useSelector((store) => store.app);

  return (
    <>
      <BrowserRouter
      // getUserConfirmation={(message, callback) => {
      //   const allowTransition = window.confirm('xxYou have unfinished changes, if you leave now you may lose them.');

      //   callback('message', allowTransition);
      // }}
      >
        <ThemeProvider theme={{ isDark: true }}>
          <Modal
            setVisibility={() => {
              dispatch(setVisibleLogin(false));
            }}
            visible={showLogin}
            elmHeight="auto"
            elmWidth="496px"
            footer={null}
          >
            <Login
              handleCancel={() => {
                dispatch(setVisibleLogin(false));
              }}
            />
          </Modal>
        </ThemeProvider>

        <Router history={history}>
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/" exact>
              <Layout home="home">
                <Helmet>
                  <title>{SITE_TITLE}</title>
                </Helmet>
                <Home />
              </Layout>
            </Route>
            <Route path="/search" exact>
              <Layout home="search">
                <Helmet>
                  <title>Search - {SITE_TITLE}</title>
                </Helmet>
                <Home />
              </Layout>
            </Route>
            {authorization && (
              <Route path="/create" exact>
                <Helmet>
                  <title>Create new article - {SITE_TITLE}</title>
                </Helmet>
                <CreateArticle />
              </Route>
            )}
            {authorization && (
              <Route path="/profile" exact>
                <Layout home="search" noKeywords={true} view="detail">
                  <OwnProfile />
                </Layout>
              </Route>
            )}
            <Route path="/profile/:id" exact>
              <Layout home="search" noKeywords={true} view="detail">
                <Profile />
              </Layout>
            </Route>
            <Route path="/testeditor" exact>
              <div style={{ padding: '50px' }}>
                <ContentEditor />
              </div>
            </Route>
            <Route path="/:categoryId/:slug" exact>
              <Layout home="search" noKeywords={true} view="detail">
                <ArticleDetail />
              </Layout>
            </Route>
            <Route path="/test-components" exact>
              <ComponentsTestPage />
            </Route>
            <Route path="/activate" exact>
              <Layout home="search" noKeywords={true} view="detail">
                <Activation type="activate" />
              </Layout>
            </Route>
            <Route path="/reactivate" exact>
              <Layout home="search" noKeywords={true} view="detail">
                <Activation type="reactivate" />
              </Layout>
            </Route>
            <Route path="**">
              <Layout noKeywords={true}>
                <NotFound />
              </Layout>
            </Route>
          </Switch>
        </Router>
      </BrowserRouter>
    </>
  );
};

export default RouterApp;
