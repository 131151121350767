import React from 'react';
import { Whisper, Tooltip } from 'rsuite';

const CustomTooltip = ({ children, text }) => (
  <Whisper
    trigger="hover"
    placement="auto"
    delay={200}
    controlId={`control-id-auto`}
    speaker={<Tooltip>{text}</Tooltip>}
  >
    {children}
  </Whisper>
);

export default CustomTooltip;
