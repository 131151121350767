import React from 'react';
import { useHistory } from 'react-router-dom';
import { Ellipse } from '../../assets/icons/svg-icons';
import {
  ContributionArticleLocation,
  ContributionArticleTitle,
  ContributionText,
  ContributionContainer,
} from './styled-components';
import FlexContainer from '../../components/UI/FlexContainer';
import ToolbarReactions from '../../components/UI/reaction-toolbar/ToolbarReactions';
import AuthorMeta from '../../components/UI/author-post/AuthorMeta';
import { getDateFormatted } from '../../utils/utils';

const Alert = ({
  title,
  textContent,
  categoryId,
  contentTypeId,
  location,
  slug,
  comments,
  liked,
  likes,
  _id,
  user,
  created_at,
  create_at,
}) => {
  const history = useHistory();
  return (
    <ContributionContainer
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.ctrlKey || e.metaKey) {
          window.open(`/${categoryId.toLowerCase()}/${slug}`);
        } else {
          history.push(`/${categoryId.toLowerCase()}/${slug}`);
        }
      }}
    >
      <ContributionArticleLocation>
        <div className="breadcrumb">
          <div
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (e.ctrlKey || e.metaKey) {
                window.open(`/?category=${categoryId}&sort=created_at`);
              } else {
                history.push(`/?category=${categoryId}&sort=created_at`);
              }
            }}
          >
            {categoryId}
          </div>
          <Ellipse />
          <div
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (e.ctrlKey || e.metaKey) {
                window.open(`/?search=${contentTypeId}&sort=created_at`);
              } else {
                history.push(`/?search=${contentTypeId}&sort=created_at`);
              }
            }}
          >
            {contentTypeId}
          </div>
          <Ellipse />
          <div
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (!location?.place_id) {
                return;
              }
              if (e.ctrlKey || e.metaKey) {
                window.open(`/?locationID=${location?.place_id}&locationName=${location?.locality}&sort=created_at`);
              } else {
                history.push(`/?locationID=${location?.place_id}&locationName=${location?.locality}&sort=created_at`);
              }
            }}
          >
            {location?.locality || 'Worldwide'}
          </div>
        </div>
      </ContributionArticleLocation>
      <ContributionArticleTitle>{title}</ContributionArticleTitle>
      <ContributionText>{textContent}</ContributionText>
      <FlexContainer justify="space-between" align="stretch">
        <FlexContainer justify="flex-start" align="center" className="reactions-toolbar" elmWidth="90%">
          {user ? (
            <AuthorMeta
              userId={user?._id}
              authorName={user?.name}
              postDate={(created_at && getDateFormatted(created_at)) || (create_at && getDateFormatted(create_at))}
              avatarUrl={user?.photo?.content?.small}
            />
          ) : (
            <AuthorMeta
              authorName={'[DELETED]'}
              postDate={(created_at && getDateFormatted(created_at)) || (create_at && getDateFormatted(create_at))}
            />
          )}
        </FlexContainer>
        <FlexContainer justify="space-evenly" align="center" className="reactions-toolbar">
          <ToolbarReactions articleId={_id} liked={liked} likes={likes} comments={comments} hideEllipses />
        </FlexContainer>
      </FlexContainer>
    </ContributionContainer>
  );
};

export default Alert;
