import { COMMENTS_LOADING, COMMENT_LOADING, GET_COMMENTS } from '../actions/commentActions';

const initialState = {
  comments: [],
  loadingComments: false,
  loadingPostComment: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case COMMENT_LOADING:
      return { ...state, loadingPostComment: payload };
    case COMMENTS_LOADING:
      return { ...state, loadingComments: payload };
    case GET_COMMENTS:
      return { ...state, comments: payload };
    default:
      return state;
  }
};
