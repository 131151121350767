import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link as BaseLink } from 'react-router-dom';
import { Ellipse } from '../../assets/icons/svg-icons';

import moment from 'moment';
import styled from 'styled-components';

const Activity = ({ article, type, count, created_at }) => {
  const history = useHistory();

  const getCopy = () => {
    switch (type) {
      case 'CREATE_ARTICLE':
        return 'Post published: ';
      case 'COMMENT_ARTICLE':
        if (!count) {
          return `You wrote a comment on: `;
        }
        return count === 1 ? 'You commented on: ' : `You wrote ${count} comments on: `;
      case 'GET_COMMENT_ON_ARTICLE':
        if (!count) {
          return `You got a comment on: `;
        }
        return count === 1 ? 'You got 1 comment on: ' : `You got ${count} comments on: `;
      case 'REPLY_ARTICLE':
        if (!count) {
          return `You wrote a reply to comments on: `;
        }
        return count === 1 ? 'You replied to a comment on: ' : `You wrote ${count} replies to comments on: `;
      case 'GET_REPLY_ON_ARTICLE':
        if (!count) {
          return `You got a reply to comments on:`;
        }
        return count === 1 ? 'You got 1 reply to a comment on: ' : `You got ${count} replies to a comment on: `;
      case 'TYPE_GET_REPLY_TO_YOUR_COMMENTS':
        if (!count) {
          return `You got a reply to comments on:`;
        }
        return count === 1 ? 'You got 1 reply to a comment on: ' : `You got ${count} replies to a comment on: `;
      case 'TYPE_LIKE_ARTICLE':
        if (!count) {
          return `A person upvoted:`;
        }
        return count === 1 ? '1 person upvoted: ' : `${count} people upvoted: `;
      case 'TYPE_LIKE_COMMENT':
        if (!count) {
          return `A person upvoted to a comment on :`;
        }
        return count === 1 ? '1 person upvoted to a comment on: ' : `${count} people upvoted to a comment on: `;
      case 'TYPE_LIKE_REPLY':
        if (!count) {
          return `A person upvoted to a reply on :`;
        }
        return count === 1 ? '1 person upvoted to a reply on: ' : `${count} people upvoted to a reply on: `;
      default:
        return '';
    }
  };
  return (
    <ActivityContainer>
      {created_at ? <ActivityDate>{moment(created_at).fromNow()}</ActivityDate> : null}

      <div className="breadcrumb">
        <div
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (e.ctrlKey || e.metaKey) {
              window.open(`/?category=${article?.categoryId}&sort=created_at`);
            } else {
              history.push(`/?category=${article?.categoryId}&sort=created_at`);
            }
          }}
        >
          {article?.categoryId}
        </div>
        <Ellipse />
        <div
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (e.ctrlKey || e.metaKey) {
              window.open(`/?search=${article?.contentTypeId}&sort=created_at`);
            } else {
              history.push(`/?search=${article?.contentTypeId}&sort=created_at`);
            }
          }}
        >
          {article?.contentTypeId}
        </div>
        <Ellipse />
        <div
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (!article?.location?.place_id) {
              return;
            }
            if (e.ctrlKey || e.metaKey) {
              window.open(
                `/?locationID=${article?.location?.place_id}&locationName=${article?.location?.locality}&sort=created_at`
              );
            } else {
              history.push(
                `/?locationID=${article?.location?.place_id}&locationName=${article?.location?.locality}&sort=created_at`
              );
            }
          }}
        >
          {article?.location?.locality || 'Worldwide'}
        </div>
      </div>
      <ActivityText>{getCopy()}</ActivityText>
      <ActivityArticle to={`/${article?.categoryId?.toLowerCase()}/${article?.slug}`}>
        {article?.title || ''}
      </ActivityArticle>
    </ActivityContainer>
  );
};

export default Activity;

const ActivityContainer = styled.div`
  width: 100%;
  padding: 12px 0;
  border-bottom: 1px solid #bdbfdf;
  margin-bottom: 10px;
  & .breadcrumb {
    color: #9695b7;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    margin-bottom: 10px;
    /* identical to box height, or 160% */

    display: flex;
    align-items: center;
    text-transform: uppercase;

    & svg {
      margin: 0 5px;
    }
  }
`;

export const ActivityDate = styled.div`
  /* Caption - Lato 12 Reg */

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  /* dark gray */

  color: #9695b7;
  margin-bottom: 7px;
`;

const ActivityText = styled.div`
  /* Body - OpenSans 14 reg */

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  /* dark */

  color: ${(props) => (props.theme.isDark ? props.theme.baseColors.middleLight : '#1f1f3d')};
  display: inline;
`;

const ActivityArticle = styled(BaseLink)`
  cursor: pointer;
  /* Body - OpenSans 14 reg */

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  /* Blue */

  color: #6670f0;
  display: inline;

  &:hover {
    color: #6670f0;
    text-decoration: none;
  }
`;
