import axios from 'axios';
import { busk } from './articleService';

const api = process.env.REACT_APP_API_URL;
const ME_CONTRIBUTIONS_ROUTE = api + '/me/contributions';
const UPLOAD_IMAGE = api + '/images';
const VALIDATE_BUSK_INTEGRATION = api + '/busk-integration';
const ALERT = api + '/alerts';
const ALERT_RESULT = api + '/alerts/results';
const ACTIVITY = api + '/me/activity';

//To set a like in a specific post triggered by specific user.
export const getMeContributionsService = async (lastItem) => {
  const token = localStorage.getItem('_token');
  return busk(token).get(`${ME_CONTRIBUTIONS_ROUTE}`, {
    params: {
      before: lastItem || undefined,
      limit: 20,
    },
  });
};

export const getUser = (id) => {
  const token = localStorage.getItem('_token');
  return busk(token).get(`${api}/users/${id}`);
};

export const getCurrentuser = () => {
  const token = localStorage.getItem('_token');
  return busk(token).get('/me');
};

export const updateCurrentUser = (data) => {
  const token = localStorage.getItem('_token');
  return busk(token).put('/me', data);
};

export const deactivateCurrentUser = () => {
  const token = localStorage.getItem('_token');
  return busk(token).delete('/me');
};

export const getUserContributionsService = async (userId, lastItem) => {
  const token = localStorage.getItem('_token');
  return busk(token).get(`${api}/users/${userId}/contributions`, {
    params: {
      before: lastItem || undefined,
      limit: 20,
    },
  });
};

export const reportUser = (data) => {
  const token = localStorage.getItem('_token');
  return busk(token).post('/reports', data);
};

export const uploadImageRequest = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('profile', true);
  const token = await localStorage.getItem('_token');

  return axios.post(UPLOAD_IMAGE, formData, {
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
  });
};

export const validateBuskIntegration = (data) => {
  const token = localStorage.getItem('_token');
  return busk(token).post(VALIDATE_BUSK_INTEGRATION, data);
};

export const createAlert = (data) => {
  const token = localStorage.getItem('_token');
  return busk(token).post(ALERT, data);
};

export const editAlert = (id, data) => {
  const token = localStorage.getItem('_token');
  return busk(token).put(`${ALERT}/${id}`, data);
};

export const getAlerts = (limit, sort) => {
  const token = localStorage.getItem('_token');
  return busk(token).get(ALERT, {
    params: {
      limit: limit || undefined,
      sort: sort || undefined,
    },
  });
};

export const getAlertResults = () => {
  const token = localStorage.getItem('_token');
  return busk(token).get(ALERT_RESULT);
};

export const deleteAlert = (id) => {
  const token = localStorage.getItem('_token');
  return busk(token).delete(`${ALERT}/${id}`);
};

export const getActivities = (limit, lastItem) => {
  const token = localStorage.getItem('_token');
  return busk(token).get(`${ACTIVITY}`, {
    params: {
      before: lastItem || undefined,
      limit: limit,
    },
  });
};
